import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '26px',
	},
}));

// TODO: update the welcome section to prompt guest users to sign up
// isGuest state (for the sign up prompt) causing re-renders, need to use context or set state at top level component and pass down props
export default function WelcomeSection() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const userName = useSelector((state) => state.auth.customer?.first_name);

	return (
		<Box className={classes.container}>
			<Typography variant='h4'>
				{userName
					? `${t('messages.main.hello')} ${userName}`
					: t('messages.main.welcomeExclamation')}
			</Typography>
		</Box>
	);
}

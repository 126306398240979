import React, { useRef, useState } from 'react';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';

// mui
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// components
import PortalTextField from 'components/UIKit/PortalTextField';

const useStyles = makeStyles((theme) => ({}));

export default function GoogleAddressAutoComplete({ setAddress }) {
	const classes = useStyles();
	const inputRef = useRef(null);

	const { ref: textFieldRef } = usePlacesWidget({
		apiKey: 'AIzaSyCjEyTmH-hWQ4sS8nchvNP2ha_Y8YmLsfU',
		onPlaceSelected: (place) => setAddress(place),
		inputAutocompleteValue: 'country',
		options: {
			types: ['address'],
			componentRestrictions: { country: 'us' },
		},
	});

	return <PortalTextField fullWidth placeholder={'Address'} inputRef={textFieldRef} />;
}

// lib
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// components
import { goToMLRuntimeServer } from 'redux/actions/admin';
import CustomButton from 'components/UIKit/CustomButton';
import PageHeader from 'components/Admin/PageHeader';
import Section from 'components/Admin/Section';

const useStyles = makeStyles((theme) => ({
	button: {
		marginTop: '20px',
	},
}));

export default function MachineStats() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.auth.language);

	const goToCountOnlyMode = () => {
		dispatch(goToMLRuntimeServer());
	};

	return (
		<>
			<PageHeader title={t('admin.operatingMode')} />
			<Section title={t('admin.countingOnly')}>
				<Typography variant='body1'>{t('admin.clickToUse')}</Typography>
				<Box className={classes.button}>
					<CustomButton variant='secondary' size='small' onClick={goToCountOnlyMode}>
						{t('admin.countOnlyMode')}
					</CustomButton>
				</Box>
			</Section>
		</>
	);
}

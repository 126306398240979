import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStylesInput = makeStyles((theme) => ({
	root: {
		border: '1px solid #e2e2e1',
		maxHeight: '55px',
		padding: theme.spacing(1),
		overflow: 'hidden',
		borderRadius: 10,
		margin: '0px auto',
		width: '70%',
		marginTop: '12px',
		boxShadow: '0px 0px 4px 3px rgba(7,7,7,0.07)',
		backgroundColor: '#fff',
	},
}));

export default function PortalTextField(props) {
	const classes = useStylesInput();
	const [backspaceCount, setBackspaceCount] = useState(0);

	// unfocus text field to 'drop' virtual keyboard when enter is pressed
	const handleEnterPress = (e) => {
		if (e.keyCode === 13) {
			e.target.blur();
		}
	};

	const handleBackspacePress = (e) => {
		// if delete or backspace is pressed down, clear input completely
		if (e.repeat && e.keyCode === (8 || 46)) {
			setBackspaceCount((prevState) => prevState + 1);

			if (backspaceCount > 4) {
				try {
					props.clearInput();
					setBackspaceCount(0);
				} catch {
					return;
				}
			}
		}
		// reset backspace counter if it is let go before clear input is triggered
		if (!e.repeat && e.keyCode === (8 || 46)) {
			setBackspaceCount(0);
		}
	};

	const handleKeyPress = (e) => {
		handleEnterPress(e);
		handleBackspacePress(e);
	};

	return (
		<TextField
			type={props.type}
			autoComplete='off'
			inputProps={{ autoCapitalize: 'off' }}
			InputProps={{
				classes,
				disableUnderline: true,
				endAdornment: props.passwordInput && (
					<IconButton
						aria-label='toggle password visibility'
						onClick={props.handlePasswordVisibility}
					>
						{props.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				),
			}}
			autoFocus={props.autoFocus}
			onKeyDown={(e) => handleKeyPress(e)}
			{...props}
		/>
	);
}


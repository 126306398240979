import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import { v4 as uuid } from 'uuid';
import PortalTextField from 'components/UIKit/PortalTextField'; // TODO: rename this component

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0px',
		marginTop: '40px',
	},
	input: {
		width: '500px',
	},
	select: {
		marginTop: '20px',
		height: '45px',
		width: '80px',
		'& .Mui-focused': {
			color: theme.palette.text.primary,
		},
	},
	label: {
		color: theme.palette.text.primary,
		'&.Mui-focused': {
			color: theme.palette.text.primary,
		},
	},
	menuPaper: {
		maxHeight: '400px',
	},
}));

export default function LicenseForm({ type, setUserInfo }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const uniqueId = uuid().slice(0, 8);
	const [id, setId] = useState('');
	const [identificationType, setIdentificationType] = useState(null);

	const idTypes = {
		1: 'drivers_license_number',
		2: 'vehicle_license_number',
		3: 'id_number', // California id
		4: 'na',
	};

	useEffect(() => {
		setIdentificationType(idTypes[type]);
	}, [type]);

	useEffect(() => {
		setUserInfo(id, identificationType);
	}, [id, identificationType]);

	const handleChange = (event) => {
		setId(event.target.value);
	};

	const text = {
		1: {
			instructions: 'overlay.CAOverOneHundredForm.enterDriversLicense',
			placeholder: 'overlay.CAOverOneHundredForm.driversLicenseNumber',
			selectState: true,
		},
		2: {
			instructions: 'overlay.CAOverOneHundredForm.enterVehicleLicense',
			placeholder: 'overlay.CAOverOneHundredForm.vehicleLicenseNumber',
			selectState: true,
		},
		3: {
			instructions: 'overlay.CAOverOneHundredForm.enterCaliforniaId',
			placeholder: 'overlay.CAOverOneHundredForm.californiaIdNumber',
			selectState: false,
		},
		4: {
			instructions: 'overlay.CAOverOneHundredForm.cannotProvideId',
			placeholder: 'overlay.CAOverOneHundredForm.explanation',
			selectState: false,
		},
	};

	return (
		<>
			<Typography>{t(text[type].instructions)}</Typography>
			<Box className={classes.form}>
				<FormControl>
					<PortalTextField
						id={uniqueId}
						className={classes.input}
						placeholder={t(text[type].placeholder)}
						value={id}
						onChange={handleChange}
						multiline={type === 4}
						rows={type === 4 && 3}
					/>
				</FormControl>
			</Box>
		</>
	);
}

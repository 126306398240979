import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: '#FFFFFF',
		margin: '20px 10px',
		height: '50px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'& .MuiTypography-root': {
			fontWeight: '300',
		},
	},
	unauthorized: {
		opacity: '0.5',
	},
	unprotected: {
		opacity: '1',
	},
	active: {
		opacity: '1',
		animation: '$fadeIn 400ms',
		backgroundColor: '#008EAA',
		'& .MuiTypography-root': {
			color: '#FFFFFF',
		},
	},
	['@keyframes fadeIn']: {
		'0%': { backgroundColor: '#FFFFFF' },
		'100%': { backgroundColor: '#008EAA' },
	},
}));

export default function NavButton({ label, onClick, unprotected, active, disabled }) {
	const classes = useStyles();
	const isAuthorized = useSelector((state) => state.admin.isAuthenticated);

	const classNames = clsx({
		[classes.container]: true,
		[classes.unauthorized]: !isAuthorized || disabled,
		[classes.unprotected]: unprotected,
		[classes.active]: isAuthorized && active,
	});

	return (
		<Box className={classNames} onClick={onClick}>
			<Typography>{label}</Typography>
		</Box>
	);
}

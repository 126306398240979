// formatting currency util based on general amount and locale

const getValidLocale = (locale) => {
	let validLocale;

	switch (locale) {
		case 'en':
			validLocale = 'en-US';
			break;
		case 'es':
			validLocale = 'es';
			break;
		case 'fr':
			validLocale = 'fr';
			break;
		case 'ru':
			validLocale = 'ru';
			break;
		case 'zh-CN':
			validLocale = 'zh';
			break;
		case 'zn-TW':
			validLocale = 'zh';
		default:
			validLocale = 'en-US';
	}

	return validLocale;
};

// amount is the general number eg. 2342115
// type is the general number format eg. microdollars
// if no type is specified, micro is the default
export const formatIntlUSD = (amount, locale, type) => {
	let price = 0;

	let USDollar = new Intl.NumberFormat(getValidLocale(locale), {
		style: 'currency',
		currency: 'USD',
		currencyDisplay: 'narrowSymbol',
	});

	if (!type) {
		price = amount / 1000000;
	} else if (type === 'cents') {
		price = amount / 100;
	}

	return USDollar.format(price);
};

export default { formatIntlUSD };

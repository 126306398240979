// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//  mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

// components
import CustomNotification from 'components/UIKit/CustomNotification';
import oversizedIcon from 'assets/images/recycletek-oversized-icon.png';
import aluminumIcon from 'assets/images/recycletek-aluminum-icon.png';
import CustomButton from 'components/UIKit/CustomButton';
import plasticIcon from 'assets/images/recycletek-plastic-icon.png';

// redux
import { stopProcess } from 'redux/actions/process';
import {
	NEXT_STEP,
	SET_MATERIAL_TO_LOAD,
	MODAL_OTHER_MATERIALS_SELECTED,
} from '../../../redux/actions/types';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		width: '70%',
	},
	options: {
		textAlign: 'center',
		marginTop: '30px',
		height: '100%',
		'& .MuiButton-root': {
			margin: '23px',
		},
	},
	iconContainer: {
		borderRadius: '99px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '60px',
		width: '60px',
	},
	label: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& h6': {
			lineHeight: '1.4',
		},
	},
	selected: {
		backgroundColor: theme.palette.primary.dark,
	},
	buttonContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-evenly',
		position: 'absolute',
		bottom: '20px',
	},
}));

export default function SelectContainerType() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useSelector((state) => state.admin.state);
	const availableMaterials = useSelector((state) => state.admin.materialType);
	const step = useSelector((state) => state.steps.activeStep);
	const modal = useSelector((state) => state.modal);
	const overweightMaterials = useSelector((state) => state.process.dailyWeightLimits.overweight);
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	const options = {
		plastic: {
			label: t('beginProcess.plasticBottles'),
			type: 'plastic',
			icon: plasticIcon,
			iconHeight: '80px',
			disabled: !availableMaterials.includes('plastic'),
			overweight: location === 'CA' && overweightMaterials.includes('Plastic'),
			overweightLabel: t('beginProcess.plasticExceeded'),
		},
		aluminum: {
			label: t('beginProcess.aluminumCans'),
			type: 'aluminum',
			icon: aluminumIcon,
			iconHeight: '50px',
			disabled: !availableMaterials.includes('aluminum'),
			overweight: location === 'CA' && overweightMaterials.includes('Aluminum'),
			overweightLabel: t('beginProcess.aluminumExceeded'),
		},
		other: {
			label: t('beginProcess.otherMaterials'),
			type: 'other',
			icon: oversizedIcon,
			iconHeight: '76px',
			disabled: !availableMaterials.includes('other'),
			overweight: false,
		},
	};

	const handleChange = (type) => {
		const other = type === 'other';

		if (other) {
			taskId && dispatch(stopProcess());
			dispatch({
				type: MODAL_OTHER_MATERIALS_SELECTED,
				payload: {
					message: t('beginProcess.anyPlasticOrAluminum'),
					heading: t('messages.modal.pleaseConfirm'),
				},
			});
		} else if (!other) {
			dispatch({
				type: SET_MATERIAL_TO_LOAD,
				payload: type,
			});
		}
	};

	const finishedProcessingItems = () => {
		dispatch(stopProcess());
		dispatch({ type: NEXT_STEP, payload: step + 1 });
		history.push('/checkout');
	};

	const back = () => {
		history.push('/payment-select');
	};

	return (
		<>
			<CustomNotification
				open={open}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
			/>
			<Box className={classes.container}>
				<Box className={classes.options}>
					{Object.values(options).map((option) => (
						<>
							{!option.disabled && (
								<CustomButton
									name={option.type}
									variant='secondary'
									size='extra-large'
									onClick={() => handleChange(option.type)}
								>
									<Box className={classes.label}>
										<Typography variant='h6'>{option.label}</Typography>
										<Box
											className={`${classes.iconContainer} ${
												option.selected && classes.selected
											}`}
										>
											<img
												src={option.icon}
												style={{ height: option.iconHeight }}
												alt='material-type-icon'
											/>
										</Box>
									</Box>
								</CustomButton>
							)}
						</>
					))}
				</Box>
			</Box>
			<Box className={classes.buttonContainer}>
				{!taskId && !prevTaskId && (
					<CustomButton disabled={taskId} variant='alternate' size='small' onClick={back}>
						{t('common.buttons.back')}
					</CustomButton>
				)}
				{(taskId || prevTaskId) && (
					<CustomButton size='expanded' onClick={finishedProcessingItems}>
						{t('beginProcess.noMoreContainers')}
					</CustomButton>
				)}
			</Box>
		</>
	);
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/UIKit/CustomButton';
import LicenseForm from 'components/CACompliance/LicenseForm';
import { SET_COMPLIANCE_INFO, SET_REDEMPTION_AMOUNT, SET_COMPLIANCE } from 'redux/actions/types';
import { resumeTask } from 'redux/actions/process';
import Signature from 'components/Signature/Signature';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: '100px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '470px',
	},
	formContainer: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	question: {
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: '34px',
		},
		'& .MuiInputBase-root': {
			minWidth: '480px',
			marginTop: '40px',
			'&.Mui-focused fieldset': {
				borderColor: '#000',
			},
		},
	},
	buttonGroup: {
		position: 'absolute',
		bottom: '100px',
		width: '460px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	signature: {
		minHeight: '450px',
	},
	cancel: {
		position: 'absolute',
		bottom: '10px',
	},
}));

export default function UserInfoQuestionnaireCA(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const [question, setQuestion] = useState(1);
	const [selected, setSelected] = useState(false);
	const [userInfo, setUserInfo] = useState({ id: '', state: '', type: '' });
	const [signature, setSignature] = useState(false);

	const questions = {
		1: 'overlay.CAOverOneHundredQuestions.driversLicense',
		3: 'overlay.CAOverOneHundredQuestions.californiaId',
		2: 'overlay.CAOverOneHundredQuestions.vehicleLicense',
		4: 'overlay.CAOverOneHundredQuestions.unableToProvideId',
	};

	const nextQuestion = () => {
		if (question !== 4) {
			setQuestion((prevState) => prevState + 1);
		} else if (question === 4) {
			setQuestion(1);
		}
	};

	const close = () => {
		dispatch({
			type: SET_COMPLIANCE,
			payload: false,
		});
	};

	const handleUserInfo = (id, idType) => {
		setUserInfo((prevState) => ({
			...prevState,
			id,
			state: 'CA',
			type: idType,
		}));
	};

	const handleSelect = () => {
		setSelected(true);
	};

	const back = () => {
		setSelected(false);
	};

	const submit = () => {
		dispatch({
			type: SET_COMPLIANCE_INFO,
			payload: {
				type: userInfo.type,
				number: userInfo.id,
				state: userInfo.state,
			},
		});

		if (!prevTaskId) {
			dispatch(resumeTask()).then(() => {
				close();
			});
		} else {
			close();
		}
	};

	const handleSignature = () => {
		setSignature(true);
	};

	const cancel = () => {
		dispatch({
			type: SET_COMPLIANCE,
			payload: false,
		});
		if (props.resetRedemptionValue) {
			dispatch({
				type: SET_REDEMPTION_AMOUNT,
				payload: 0,
			});
		}
	};

	return (
		<Box className={classes.container}>
			{!selected && (
				<>
					<Box className={classes.question}>
						<Typography>{t(questions[question])}</Typography>
					</Box>
					<Box className={classes.buttonGroup}>
						<CustomButton variant='alternate' onClick={nextQuestion}>
							{question !== 4 ? t('overlay.no') : t('overlay.backToOptions')}
						</CustomButton>
						<CustomButton variant='secondary' onClick={handleSelect}>
							{t('overlay.yes')}
						</CustomButton>
					</Box>
					{props.cancel && (
						<Box className={classes.cancel} onClick={cancel}>
							<CustomButton variant='alternate' size='small'>
								Cancel
							</CustomButton>
						</Box>
					)}
				</>
			)}
			{selected && !signature && (
				<>
					<Box className={classes.formContainer}>
						<LicenseForm type={question} setUserInfo={handleUserInfo} />
					</Box>
					<Box className={classes.buttonGroup}>
						<CustomButton variant='alternate' onClick={back}>
							{t('common.buttons.back')}
						</CustomButton>
						<CustomButton variant='secondary' onClick={handleSignature}>
							{t('overlay.submit')}
						</CustomButton>
					</Box>
				</>
			)}
			{signature && (
				<Box className={classes.signature}>
					<Signature saveCallback={submit} />
				</Box>
			)}
		</Box>
	);
}

import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';

// components
import PortalTextField from 'components/UIKit/PortalTextField';
import CustomButton from 'components/UIKit/CustomButton';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '400px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiTypography-root': {
			marginTop: '8px',
		},
		justifyContent: 'space-between',
		'& .MuiInputBase-input': {
			maxWidth: '120px',
		},
		'& .MuiInputBase-root': {
			marginTop: '6px',
		},
	},
	input: {
		maxWidth: '200px',
		height: '66px',
		maxHeight: '66px',
		align: 'left',
		'& .MuiInputBase-input': {
			color: theme.palette.text.primary,
		},
	},
	counterInputs: {
		display: 'flex',
		alignItems: 'center',
		maxHeight: '60px',
		'& .MuiButtonBase-root': {
			marginTop: '4px',
		},
	},
}));

export default function AdminCounterInput({
	name,
	value,
	label,
	disabled,
	handleChange,
	keyDown,
	increaseCount,
	decreaseCount,
}) {
	const classes = useStyles();
	const uniqueId = uuid().slice(0, 8);
	const intervalRef = useRef(null);
	const [showPasswordText, setShowPasswordText] = useState(false);
	const [incrementCounter, setIncrementCounter] = useState(0);
	const [decrementCounter, setDecrementCounter] = useState(0);

	useEffect(() => {
		return () => stopCounter(); // stop/clear counter on unmount
	}, []);

	useEffect(() => {
		incrementCounter > 1 && handleAdjustment(10, 'add');
	}, [incrementCounter]);

	useEffect(() => {
		decrementCounter > 1 && value >= 10 && handleAdjustment(10, 'sub');
	}, [decrementCounter]);

	const container = clsx({
		[classes.container]: true,
	});

	const handlePasswordVisibility = () => {
		setShowPasswordText(!showPasswordText);
	};

	const handleAdjustment = (count, operation) => {
		operation === 'add' && increaseCount(count);
		operation === 'sub' && decreaseCount(count);
	};

	// increment or decrement adjusted counts by 1 and start a timer to determine if button is being held down
	const adjustAndStartCounter = (operation) => {
		handleAdjustment(1, operation);
		if (intervalRef.current) return;
		intervalRef.current = setInterval(() => {
			operation === 'add' && setIncrementCounter((prevCounter) => prevCounter + 1);
			operation === 'sub' && setDecrementCounter((prevCounter) => prevCounter + 1);
		}, 400);
	};

	// stop timer when button stops being held down
	const stopCounter = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	return (
		<Box className={container}>
			<Typography variant='body2'>{label}:</Typography>
			<Box className={classes.counterInputs}>
				<CustomButton
					size='extra-small'
					disabled={value === 0}
					name='cy-count-input-decrement'
					onPointerDown={() => adjustAndStartCounter('sub')}
					onPointerUp={stopCounter}
					onPointerLeave={stopCounter}
				>
					<RemoveIcon fontSize='large' />
				</CustomButton>
				<PortalTextField
					className={classes.input}
					id={uniqueId}
					value={value}
					fullWidth
					name={name}
					disabled={disabled}
					handlePasswordVisibility={handlePasswordVisibility}
					onChange={handleChange}
					onKeyDown={keyDown}
				/>
				<CustomButton
					size='extra-small'
					name='cy-count-input-increment'
					onPointerDown={() => adjustAndStartCounter('add')}
					onPointerUp={stopCounter}
					onPointerLeave={stopCounter}
				>
					<AddIcon fontSize='large' />
				</CustomButton>
			</Box>
		</Box>
	);
}

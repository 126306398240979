import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';


// components
import CustomButton from 'components/UIKit/CustomButton';
import MaterialTypeNumber from 'components/UIKit/MaterialTypeNumber';
import { DELETE_SCRAP_ITEM } from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '10px 0px',
		maxHeight: 300,
	},
	table: {
		minWidth: 650,
	},
	confirmDelete: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
	},
	buttonGroup: {
		margin: '40px',
		display: 'flex',
		width: '440px',
		justifyContent: 'space-between',
	},
	material: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function ScrapEntriesTable({ editScrap }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const scrap = useSelector((state) => state.adjustments.scrap);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [materialToDelete, setMaterialToDelete] = useState('');
	const [entryToDelete, setEntryToDelete] = useState({});

	const resetConfirmDelete = () => {
		setShowConfirmDelete(false);
		setMaterialToDelete('');
		setEntryToDelete({});
	};

	/**
	 *
	 * @param {string} material - Eg. 'HDPE', 'GLS, or 'PETE'
	 * @param  {Object} entry - Eg. { category: 'line_breakage', weight_lbs: 13.2 }
	 *
	 */
	const deleteEntry = (material, entry) => {
		resetConfirmDelete();
		dispatch({
			type: DELETE_SCRAP_ITEM,
			payload: {
				material: material,
				entry: entry,
			},
		});
	};

	/**
	 *
	 * @param {string} material - Eg. 'HDPE', 'GLS, or 'PETE'
	 * @param  {Object} entry - Eg. { category: 'line_breakage', weight_lbs: 13.2 }
	 *
	 */
	const editEntry = (material, entry) => {
		deleteEntry(material, entry);
		editScrap(material, entry);
	};

	/**
	 *
	 * @param {string} material - Eg. 'HDPE', 'GLS, or 'PETE'
	 * @param  {Object} entry - Eg. { category: 'line_breakage', weight_lbs: 13.2 }
	 *
	 */
	const handleDeleteEntry = (material, entry) => {
		setShowConfirmDelete(true);
		setMaterialToDelete(material);
		setEntryToDelete(entry);
	};

	return (
		<TableContainer className={classes.container} component={Paper}>
			{!showConfirmDelete && (
				<Table className={classes.table} stickyHeader aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>{t('overlay.review.material')}</TableCell>
							<TableCell align='right'>{t('overlay.review.category')}</TableCell>
							<TableCell align='right'>{t('overlay.review.size')}</TableCell>
							<TableCell align='right'>{t('overlay.review.count')}</TableCell>
							<TableCell align='right'>{t('overlay.review.weight')}</TableCell>
							<TableCell align='right'>{t('overlay.review.deleteEntry')}</TableCell>
							<TableCell align='right'>{t('overlay.review.editEntry')}</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{Object.keys(scrap).map(
							(material) =>
								scrap[material].length > 0 &&
								scrap[material].map((entry, index) => (
									<TableRow key={index}>
										<TableCell component='th' scope='row'>
											<Box className={classes.material}>
												{material} <MaterialTypeNumber type={material} />
											</Box>
										</TableCell>
										<TableCell align='right'>
											{entry.category?.replaceAll('_', ' ')}
										</TableCell>
										<TableCell align='right'>{entry.size_oz ?? 'N/A'}</TableCell>
										<TableCell align='right'>{entry.count ?? 'N/A'}</TableCell>
										<TableCell align='right'>
											{entry.weight_lbs
												? Number(entry.weight_lbs).toFixed(1)
												: 'N/A'}
										</TableCell>
										<TableCell align='right'>
											<CustomButton
												size='extra-small'
												variant='danger'
												onClick={() => handleDeleteEntry(material, entry)}
											>
												<ClearIcon />
											</CustomButton>
										</TableCell>
										<TableCell align='right'>
											<CustomButton
												size='extra-small'
												variant='secondary'
												name={`cy-edit-scrap-${material}-${index}`}
												onClick={() => editEntry(material, entry)}
											>
												<EditIcon />
											</CustomButton>
										</TableCell>
									</TableRow>
								)),
						)}
					</TableBody>
				</Table>
			)}
			{showConfirmDelete && (
				<Box className={classes.confirmDelete}>
					<Typography>{t('overlay.review.areYouSure')}</Typography>
					<Box className={classes.buttonGroup}>
						<CustomButton variant='alternate' onClick={resetConfirmDelete}>
							{t('overlay.no')}
						</CustomButton>
						<CustomButton
							variant='danger'
							onClick={() => deleteEntry(materialToDelete, entryToDelete)}
						>
							{t('overlay.yes')}
						</CustomButton>
					</Box>
				</Box>
			)}
		</TableContainer>
	);
}

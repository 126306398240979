// lib
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import CustomNotification from 'components/UIKit/CustomNotification';
import { setPaymentType } from 'redux/actions/user';
import { confirmCharity } from 'redux/actions/modal';
import { getCharities } from 'redux/actions/user';
import { SERVER_URL } from 'redux/actions/config.js';
import CustomButton from 'components/UIKit/CustomButton';
import cautionIcon from 'assets/images/causion.png';
import Background from 'components/Background/Background';
import CustomBox from 'components/UIKit/CustomBox';
import ScrollContainer from 'components/UIKit/ScrollContainer';

const useStyles = makeStyles((theme) => ({
	logos: {
		height: '15%',
	},
	container: {
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	charityContainer: {
		position: 'relative',
		minHeight: '240px',
		maxHeight: '240px',
		minWidth: '380px',
		maxWidth: '380px',
		margin: '18px',
		boxShadow: '1px 1px 5px grey',
		borderRadius: '8px',
		overflow: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	charityHeading: {
		display: 'flex',
		flexDirection: 'row',
		margin: '4px',
		textAlign: 'center',
	},
	charityImage: {
		height: '90px',
		margin: '4px 12px 4px 4px',
		borderRadius: '8px',
	},
	charityButtons: {
		position: 'absolute',
		bottom: '10px',
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'center',
		'& .MuiButton-root': {
			marginRight: '14px',
			marginLeft: '14px',
		},
	},
	closeButtonContainer: {
		backgroundColor: 'rgba(255, 255, 255, 0.7)',
		width: '100%',
		position: 'sticky',
		top: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'flex-start',
		'& .MuiButton-root': {
			margin: '8px',
			marginRight: '16px',
		},
	},
	charityAbout: {
		margin: '10px',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: '10px',
	},
	charitiesContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		marginTop: '10px',
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '90%',
		height: '410px',
		'& img': {
			marginBottom: '8px',
			height: '60px',
			width: 'auto',
		},
	},
}));

export default function DonateToCharity() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const modal = useSelector((state) => state.modal);
	const language = useSelector((state) => state.auth.language);
	const machineLocation = useSelector((state) => state.admin.state);
	const payment = useSelector((state) => state.auth.paymentData);
	const [charityInFocus, setCharityInFocus] = useState(null);
	const [allCharitiesList, setAllCharitiesList] = useState('');
	const [charitiesList, setCharitiesList] = useState('');
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	useEffect(() => {
		!charitiesList &&
			dispatch(getCharities()).then((res) => {
				const filteredCharities = res.filter((charity) => charity.state === machineLocation);
				setAllCharitiesList(res);
				setCharitiesList(filteredCharities);
			});
	}, []);

	// closes the custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	const openAbout = (index) => {
		setCharityInFocus(index);
	};

	const closeAbout = () => {
		setCharityInFocus(null);
	};

	const selectCharity = (charity) => {
		dispatch(setPaymentType(payment.donate.name, charity.id, payment.donate.id));
		dispatch(
			confirmCharity(
				t('messages.modal.donateEarningsTo', { name: charity.business_name }),
				t('messages.modal.pleaseConfirm'),
			),
		);
	};

	const back = () => {
		history.push('/payment-select');
	};

	const viewAllCharities = () => {
		setCharitiesList(allCharitiesList);
	};

	return (
		<Background>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
				isDonation={true}
			/>
			<Box className={classes.container}>
				<CustomBox title={t('donate.title')} position='relative'>
					<Box className={classes.charitiesContainer}>
						<ScrollContainer>
							{charitiesList.length ? (
								charitiesList.map((charity, index) => (
									<Box className={classes.charityContainer}>
										{charityInFocus !== index ? (
											<Box>
												<Box className={classes.charityHeading}>
													<img
														className={classes.charityImage}
														src={`${SERVER_URL}${charity.imageurl}`}
														alt='charity logo'
													/>
													<Typography variant='h6'>
														{charity.business_name}
													</Typography>
												</Box>
												<Box className={classes.charityButtons}>
													<CustomButton
														variant='secondary'
														size='small'
														onClick={() => openAbout(index)}
													>
														{t('common.buttons.about')}
													</CustomButton>
													<CustomButton
														name='donate-btn-select'
														variant='secondary'
														size='small'
														onClick={() =>
															selectCharity(charity, charity.business_name)
														}
													>
														{t('common.buttons.select')}
													</CustomButton>
												</Box>
											</Box>
										) : (
											<>
												<Box className={classes.closeButtonContainer}>
													<CustomButton
														variant='secondary'
														size='small'
														onClick={closeAbout}
													>
														{t('common.buttons.close')}
													</CustomButton>
												</Box>
												<Box className={classes.charityAbout}>
													<Typography variant='body1'>
														{t(charity.description[language])}
													</Typography>
												</Box>
											</>
										)}
									</Box>
								))
							) : (
								<Box className={classes.noCharitiesContainer}>
									<img src={cautionIcon} />
									<Typography>
										{t('donate.noCharitiesAvailable')}{' '}
										<CustomButton
											variant='secondary'
											size='small'
											onClick={viewAllCharities}
										>
											{t('donate.here')}
										</CustomButton>{' '}
										{t('donate.toViewAll')}
									</Typography>
								</Box>
							)}
						</ScrollContainer>
					</Box>
					<Box className={classes.buttonContainer}>
						<CustomButton variant='alternate' size='small' onClick={back}>
							{t('common.buttons.back')}
						</CustomButton>
					</Box>
				</CustomBox>
			</Box>
		</Background>
	);
}

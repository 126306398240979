// lib
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

// components
import CustomNotification from 'components/UIKit/CustomNotification';
import MachinePaused from 'pages/Process/MachinePaused';
import PaymentSelect from 'pages/Process/PaymentSelect';
import PrintReceipt from 'pages/Process/PrintReceipt';
import StartProcess from 'pages/Process/StartProcess';
import BeginProcess from 'pages/Process/LoadHopper/BeginProcess';
import Background from 'components/Background/Background.js';
import ItemDetect from 'pages/Process/ItemDetect';
import EcoVision from 'pages/Process/EcoVision';

export default function ItemProcess({ key }) {
	const modal = useSelector((state) => state.modal);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (modal.heading) {
			setOpen(true);
		}
	}, [modal]);

	// closes custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	return (
		<Background key={key}>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
			/>
			<Route path='/begin-process' component={BeginProcess}></Route>
			<Route path='/item-detect' component={ItemDetect} />
			<Route path='/eco-vision' component={EcoVision} />
			<Route path='/machine-paused' component={MachinePaused} />
			<Route path='/start-process'>
				<StartProcess />
			</Route>
			<Route path='/payment-select' component={PaymentSelect} />
			<Route path='/print-receipt' component={PrintReceipt} />
		</Background>
	);
}

import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import CustomButton from 'components/UIKit/CustomButton';
import { SET_SIGNATURE } from 'redux/actions/types';

import './sigCanvas.css';

export default function Signature({ saveCallback }) {
	const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const sigCanvas = useRef({});

	// clear method given by react-signature-canvas */
	const clear = () => {
		sigCanvas.current.clear();
	};

	// getTrimmedCanvas given by react-signature-canvas
	const save = () => {
		const data = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

		setImageURL(data);
		dispatch({
			type: SET_SIGNATURE,
			payload: data,
		});
		saveCallback();
	};

	return (
		<>
			<Typography variant='h6'>{t('common.misc.signature')}</Typography>
			<>
				<SignaturePad
					ref={sigCanvas}
					canvasProps={{
						className: 'signatureCanvas',
					}}
				/>
				<div className='buttons'>
					<CustomButton variant='alternate' onClick={clear}>
						{t('common.buttons.clear')}
					</CustomButton>
					<CustomButton variant='primary' onClick={save}>
						{t('common.buttons.save')}
					</CustomButton>
				</div>
			</>
			<br />
			<br />
			{/* if our we have a non-null image url we should 
      show an image and pass our imageURL state to it*/}
			{imageURL ? (
				<img
					src={imageURL}
					alt='my signature'
					style={{
						display: 'block',
						margin: '0 auto',
						border: '1px solid black',
						width: '150px',
					}}
				/>
			) : null}
		</>
	);
}

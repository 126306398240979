// lib
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// redux
import {
	getTaskStats,
	pauseTask,
	manuallyInsertCounts,
	manuallyChangeCounts,
	calculateWeights,
} from 'redux/actions/process';

// components
import PageHeader from 'components/Admin/PageHeader';
import CustomErrorBadge from 'components/UIKit/CustomErrorBadge';
import CustomNotification from 'components/UIKit/CustomNotification';
import { MODAL_GENERAL_ERROR, PREV_ADJUSTMENT } from 'redux/actions/types';
import ReviewAndFinalize from 'pages/Express/ReviewAndFinalize/ReviewAndFinalize';
import ContainerInputTypes from 'components/ContainerInputTypes';
import ExpressControls from 'pages/Express/ExpressControls';
import MaterialPanel from 'components/UIKit/MaterialPanel';
import ScrollContainer from 'components/UIKit/ScrollContainer';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import WeightInputs from 'components/CountsAndWeight/WeightInputs';
import ScrapInputs from 'components/CountsAndWeight/ScrapInputs';
import CountInputs from 'components/CountsAndWeight/CountInputs';
import TabMenu from 'components/UIKit/TabMenu';
import Overlay from 'components/UIKit/Overlay';

// utils/lib
import { addEcoCountAndAdjustments, calculateNetWeights } from 'utils/totalContainers';
import { crossReferenceWeightLimits } from 'utils/stateCompliance';
import { materialTabs } from 'lib/tabs';
import { getFormattedWeight } from 'utils/formatWeight';

const useStyles = makeStyles((theme) => ({
	main: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	materials: {
		height: '100vh',
		paddingBottom: '190px',
	},
	materialContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	controlsContainer: {
		position: 'absolute',
		right: '0px',
		borderTop: `1px solid ${theme.palette.grey.main}`,
		backgroundColor: theme.palette.background.default,
		height: '100px',
		position: 'fixed',
		bottom: '0px',
		width: '100%',
		paddingLeft: '350px',
	},
	errMessage: {
		position: 'fixed',
		top: '40%',
		left: '45%',
		zIndex: '105',
	},
	errorBadge: {
		position: 'fixed',
		top: '50%',
		left: '45%',
		zIndex: '106',
	},
}));

// Eg. Count, Weight, or Scrap for the selected type of entry
const initSelectedInputType = {
	['PETE']: 'Count',
	['ALU']: 'Count',
	['GLS']: 'Count',
	['HDPE']: 'Count',
	['PVC']: 'Count',
	['LDPE']: 'Count',
	['BMT']: 'Count',
	['PP']: 'Count',
	['PS']: 'Count',
	['OTHER']: 'Count',
	['BIB']: 'Count',
	['MLP']: 'Count',
	['PBC']: 'Count',
};

export default function AdjustCounts() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const prevAdjusted = useSelector((state) => state.adjustments.prevAdjustment);
	const paymentType = useSelector((state) => state.auth.paymentType);
	const adjustments = useSelector((state) => state.adjustments.materials);
	const paymentId = useSelector((state) => state.auth.paymentId);
	const USState = useSelector((state) => state.admin.state);
	const language = useSelector((state) => state.auth.language);
	const process = useSelector((state) => state.process);
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const modal = useSelector((state) => state.modal);
	const data = useSelector((state) => state.process.data);
	const [selectedInputType, setSelectedInputType] = useState(initSelectedInputType);
	const [isPasswordBtnDisabled, setIsPasswordBtnDisabled] = useState(false);
	const [reviewOverlay, setReviewOverlay] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({ text: '', status: '' });
	const [selectedTab, setSelectedTab] = useState('Plastic');
	const [stillProcessingItems, setStillProcessingItems] = useState(false);
	const [tareWeights, setTareWeights] = useState({});
	const [password, setPassword] = useState('');
	const [taskEnded, setTaskEnded] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [weights, setWeights] = useState({});
	const [open, setOpen] = useState(false);

	const materials = [
		{
			type: 'PETE',
			altType: 'pete',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true, // if set to false, "N/A" will be displayed in the EcoCount section and will not pickup on materials that the EcoCount detects
				ecoCount: data.count_pete_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_pete_geq_24oz,
			},
		},
		{
			type: 'ALU',
			altType: 'aluminum',
			displayName: 'Aluminum',
			selected: selectedTab === 'Metal',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_aluminum_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_aluminum_geq_24oz,
			},
		},
		{
			type: 'GLS',
			altType: 'glass',
			displayName: 'Glass',
			selected: selectedTab === 'Glass',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_glass_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_glass_geq_24oz,
			},
		},
		{
			type: 'HDPE',
			altType: 'hdpe',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_hdpe_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_hdpe_geq_24oz,
			},
		},
		{
			type: 'PVC',
			altType: 'pvc',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_pvc_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_pvc_geq_24oz,
			},
		},
		{
			type: 'LDPE',
			altType: 'ldpe',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_ldpe_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_ldpe_geq_24oz,
			},
		},
		{
			type: 'BMT',
			altType: 'bi-metal',
			displayName: 'Bi-Metal',
			scrap: true,
			selected: selectedTab === 'Metal',
			sm: {
				available: true,
				ecoCount: data['count_bi-metal_lt_24oz'],
			},
			md: {
				available: true,
				ecoCount: data['count_bi-metal_geq_24oz'],
			},
		},
		{
			type: 'PP',
			altType: 'pp',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_pp_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_pp_geq_24oz,
			},
		},
		{
			type: 'PS',
			altType: 'ps',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_ps_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_ps_geq_24oz,
			},
		},
		{
			type: 'OTHER',
			altType: 'other',
			displayName: 'Other',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_other_lt_24oz,
			},
			md: {
				available: true,
				ecoCount: data.count_other_geq_24oz,
			},
		},
		{
			type: 'BIB',
			displayName: 'Bag-in-Box',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: false,
				ecoCount: 0,
			},
			md: {
				available: true,
				ecoCount: data['count_bag-in-box_geq_24oz'],
			},
		},
		{
			type: 'MLP',
			displayName: 'Multilayer-Pouch',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: true,
				ecoCount: 0,
			},
			md: {
				available: true,
				ecoCount: data['count_multilayer-pouch_geq_24oz'],
			},
		},
		{
			type: 'PBC',
			displayName: 'Paperboard-Carton',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: true,
				ecoCount: 0,
			},
			md: {
				available: true,
				ecoCount: data['count_paperboard-carton_geq_24oz'],
			},
		},
	];

	useEffect(() => {
		isErrMsgNeeded();
		isModalOpen();
	}, [isAuthenticated, paymentType, paymentId, language, process.task_id, process.prevTaskId, modal]);

	const isErrMsgNeeded = () => {
		const taskId = process.task_id?.task_id;
		const prevTaskId = process.prevTaskId;

		const errMsgs = [
			{ err: paymentType === 'donate' && !paymentId, msg: 'admin.pleaseSelectCharity' },
			{ err: !paymentType, msg: 'admin.pleaseGoBack' },
			{ err: !isAuthenticated, msg: 'admin.onlyWhenLoggedIn' },
			{ err: taskId && !prevTaskId, msg: 'admin.pleaseFinishProcessing' },
		];

		errMsgs.map((type) => type.err && setErrMsg(t(type.msg)));

		if (taskId && !prevTaskId) setStillProcessingItems(true);
		if (prevTaskId) setStillProcessingItems(false);
	};

	const setTab = (tab) => {
		setSelectedTab(tab);
	};

	const isModalOpen = () => {
		if (modal.id) {
			setOpen(true);
		}
	};

	// closes the custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	const selectMaterialToRun = () => {
		dispatch({
			type: MODAL_SELECT_MATERIAL_TO_RUN,
			payload: {
				message: t('messages.modal.chooseMaterial'),
				heading: t('messages.modal.pleaseSelect'),
			},
		});
	};

	const stop = () => {
		readyToStop &&
			dispatch(pauseTask()).then(() => {
				setIsBeltRunning(false);
				setSnackbarMessage({
					text: t('messages.success.machineStopped'),
					status: 'success',
				});
			});
	};

	const end = (netWeights) => {
		const totalCounts = addEcoCountAndAdjustments();
		const redemptionAmountOnly = true;

		dispatch({
			type: PREV_ADJUSTMENT,
			payload: true,
		});

		if (!prevTaskId) {
			dispatch(manuallyInsertCounts(netWeights)).then(() => {
				dispatch(getTaskStats(redemptionAmountOnly));
				setTaskEnded(true);
			});
		} else if (prevTaskId) {
			dispatch(manuallyChangeCounts(totalCounts, prevTaskId, password, netWeights)).then(() => {
				dispatch(getTaskStats(redemptionAmountOnly));
				setTaskEnded(true);
			});
		}
	};

	const cannotSubmit = (data, reason) => {
		closeReviewOverlay();

		if (reason === 'overweight') {
			dispatch({
				type: MODAL_GENERAL_ERROR,
				payload: {
					message: t('messages.modal.overweightItems'),
					info: data,
					heading: t('messages.modal.overweight'),
				},
			});
		}
	};

	// checks if material inputs are valid and can be submitted
	const screenMaterialEntries = async () => {
		const netWeights = await calculateNetWeights(weights, tareWeights);

		if (USState !== 'CA') {
			return end(netWeights);
		}

		if (USState === 'CA') {
			dispatch(calculateWeights(netWeights)).then((res) => {
				let overlimitList = [];

				res.map((obj) => {
					overlimitList.push(Object.keys(obj)[0]);
				});

				const status = crossReferenceWeightLimits(netWeights, adjustments, overlimitList);

				return status.ok ? end(netWeights) : cannotSubmit(status.over, 'overweight');
			});
		}
	};

	const closeReviewOverlay = () => {
		setReviewOverlay(false);
	};

	const closeSnackbar = () => {
		setSnackbarMessage({ text: '', status: '' });
	};

	const handleGrossWeight = (weight, material) => {
		setWeights((prevState) => ({
			...prevState,
			[material]: weight,
		}));
	};

	const handleTareWeight = (weight, material) => {
		setTareWeights((prevState) => ({
			...prevState,
			[material]: weight,
		}));
	};

	const deleteWeight = (material) => {
		setWeights((current) => {
			const copy = { ...current };

			delete copy[material];

			return copy;
		});

		setTareWeights((current) => {
			const copy = { ...current };

			delete copy[material];

			return copy;
		});
	};

	/**
	 *
	 * @param {string} materialType - Eg. 'HDPE', 'GLS, or 'PETE'
	 * @param {string} inputType - Eg. 'Count', 'Weight', or 'Scrap'
	 *
	 */
	const setInputType = (materialType, inputType) => {
		setSelectedInputType((prevState) => ({
			...prevState,
			[materialType]: inputType,
		}));
	};

	const showReview = () => {
		setReviewOverlay(true);
	};

	return (
		<>
			<Overlay open={reviewOverlay} title={t('overlay.review.reviewAndFinalize')} zIndex={525}>
				<ReviewAndFinalize
					tareWeights={tareWeights}
					grossWeights={weights}
					taskEnded={taskEnded}
					adjustCounts
					materials={materials}
					password={location.state}
					setPassword={setPassword}
					closeOverlay={closeReviewOverlay}
					calculateNetWeights={calculateNetWeights}
					screenMaterialEntries={screenMaterialEntries}
					handleGrossWeight={handleGrossWeight}
					handleTareWeight={handleTareWeight}
					deleteWeight={deleteWeight}
				/>
			</Overlay>
			<CustomSnackbar
				message={snackbarMessage.text}
				status={snackbarMessage.status}
				close={closeSnackbar}
				showCloseButton
			/>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
			/>
			<PageHeader title={t('admin.adjustCounts')} />
			{!prevAdjusted && (
				<Box className={classes.main}>
					<Box className={classes.materials}>
						<ScrollContainer>
							<TabMenu tabs={materialTabs} setTab={setTab} selected={selectedTab} />
							{materials.map(
								(material) =>
									material.selected && (
										<Box className={classes.materialContainer}>
											<ContainerInputTypes
												setType={setInputType}
												materialType={material.type}
												scrap={material.scrap}
												selectedInputTypes={selectedInputType}
											/>
											<MaterialPanel
												name={material.type}
												fullName={material.displayName}
											>
												<CountInputs
													material={material}
													show={selectedInputType[material.type] === 'Count'}
												/>
												<WeightInputs
													show={selectedInputType[material.type] === 'Weight'}
													materialType={material.type}
													handleGrossWeight={handleGrossWeight}
													handleTareWeight={handleTareWeight}
												/>
												<ScrapInputs
													show={selectedInputType[material.type] === 'Scrap'}
													materialType={material.type}
												/>
											</MaterialPanel>
										</Box>
									),
							)}
						</ScrollContainer>
					</Box>
					<Box className={classes.controlsContainer}>
						{!stillProcessingItems && (
							<ExpressControls
								isBeltRunning={false}
								adjustCounts={true}
								selectMaterialToRun={selectMaterialToRun}
								taskEnded={taskEnded}
								showReview={showReview}
								stop={stop}
							/>
						)}
					</Box>
				</Box>
			)}
			<Box className={classes.errorBadge}>
				<CustomErrorBadge errorMessage={prevAdjusted ? t('admin.cannotAdjust') : errMsg} />
			</Box>
		</>
	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/UIKit/CustomButton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& button': {
			margin: '12px 0px',
		},
	},
}));

export default function ContainerInputTypes({ setType, materialType, scrap, selectedInputTypes }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.main}>
			<CustomButton
				name={`cy-count-${materialType}`}
				variant='secondary'
				selected={selectedInputTypes[materialType] === 'Count'}
				size='small'
				onClick={() => setType(materialType, 'Count')}
			>
				{t('common.buttons.count')}
			</CustomButton>
			<CustomButton
				name={`cy-weight-${materialType}`}
				variant='secondary'
				selected={selectedInputTypes[materialType] === 'Weight'}
				size='small'
				onClick={() => setType(materialType, 'Weight')}
			>
				{t('common.buttons.weight')}
			</CustomButton>
			<CustomButton
				name={`cy-scrap-${materialType}`}
				variant='secondary'
				selected={selectedInputTypes[materialType] === 'Scrap'}
				disabled={!scrap}
				size='small'
				onClick={() => setType(materialType, 'Scrap')}
			>
				{t('common.buttons.scrap')}
			</CustomButton>
		</Box>
	);
}

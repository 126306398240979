import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AdminInput from 'components/Admin/AdminInput';
import CustomButton from 'components/UIKit/CustomButton';
import { getFormattedWeight } from 'utils/formatWeight';
import AnimatedCheckmark from 'components/UIKit/AnimatedCheckmark/AnimatedCheckmark';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingBottom: '10px',
	},
	inputs: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	submitted: {
		minHeight: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
}));

export default function WeightInputs({
	show,
	materialType,
	prevWeightEntry,
	handleGrossWeight,
	handleTareWeight,
	editor,
	handleCloseEdit,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [gross, setGross] = useState({ weight: prevWeightEntry?.gross || '', material: materialType });
	const [tare, setTare] = useState({ weight: prevWeightEntry?.tare || '', material: materialType });
	const [showSubmitted, setShowSubmitted] = useState(false);
	const [adjusted, setAdjusted] = useState(false);

	const handleSetGross = (e, material) => {
		const formatted = getFormattedWeight(e.target);

		setGross({ weight: formatted, material });
		setAdjusted(true);
	};

	const handleSetTare = (e, material) => {
		const formatted = getFormattedWeight(e.target);

		setTare({ weight: formatted, material });
		setAdjusted(true);
	};

	const save = () => {
		if (!adjusted && (prevWeightEntry?.gross || prevWeightEntry?.tare)) {
			handleGrossWeight(prevWeightEntry.gross, gross.material);
			handleTareWeight(prevWeightEntry.tare, tare.material);
		} else {
			handleGrossWeight(Number(gross.weight || 0), gross.material);
			handleTareWeight(Number(tare.weight || 0), tare.material);
		}

		setShowSubmitted(true);

		setTimeout(() => {
			editor && handleCloseEdit();
			setShowSubmitted(false);
		}, 1800);
	};

	return (
		<>
			{show && (
				<>
					{!showSubmitted && (
						<Box className={classes.container}>
							<Box className={classes.inputs}>
								<AdminInput
									name={`cy-gross-weight-input-${materialType}`}
									label={t('admin.enterGrossWeight')}
									value={gross.weight}
									input='number'
									handleChange={(e) => handleSetGross(e, materialType)}
								/>
								<AdminInput
									name={`cy-tare-weight-input-${materialType}`}
									label={t('admin.tareWeight')}
									placeholder={prevWeightEntry?.tare}
									value={tare.weight}
									input='number'
									handleChange={(e) => handleSetTare(e, materialType)}
								/>
							</Box>
							<CustomButton size='small' onClick={save}>
								{t('common.buttons.save')}
							</CustomButton>
						</Box>
					)}
					{showSubmitted && (
						<Box className={classes.submitted}>
							<Typography>{t('admin.weightSubmitted')}</Typography>
							<AnimatedCheckmark />
						</Box>
					)}
				</>
			)}
		</>
	);
}

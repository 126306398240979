import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import logo_gradient from 'assets/images/gradientLogo.png';
import CustomBox from 'components/UIKit/CustomBox';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: '4%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	spinnerImg: {
		width: '309px',
		height: '274px',
		animation: '$rotation 2s infinite linear',
	},
	'@keyframes rotation': {
		from: {
			transform: 'rotate(0deg)',
		},
		to: {
			transform: 'rotate(359deg)',
		},
	},
	detectItemText: {
		marginTop: '28px',
		color: theme.palette.secondary.dark,
		fontWeight: '350',
	},
}));

function ItemDetect() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<CustomBox>
			<Box className={classes.container}>
				<img className={classes.spinnerImg} src={logo_gradient} alt='loading spinner' />
				<Typography className={classes.detectItemText} variant='h3'>
					{t('beginProcess.detectingItems')}
				</Typography>
			</Box>
		</CustomBox>
	);
}

export default ItemDetect;

// the sizes for scrap that the machine knows about
// sizes outside of this may not register
export const sizes = [
	{ value: 7.5, text: '7.5 oz' },
	{ value: 8.4, text: '8.4 oz' },
	{ value: 12, text: '12 oz' },
	{ value: 16, text: '16 oz' },
	{ value: 24, text: '24 oz' },
	{ value: 32, text: '32 oz' },
	{ value: 64, text: '64 oz' },
	{ value: 128, text: '128 oz (1 gl)' },
];

// lib
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import { SET_ADMIN_AUTH } from 'redux/actions/types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// components
import RecycletekLogo from 'components/Background/RecycletekLogo';
import NavButton from 'components/Admin/NavButton';
import AdminLogout from 'components/Admin/AdminLogout';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100vh',
		width: 'max-content',
		backgroundColor: '#004F71',
		position: 'relative',
		zIndex: '2',
	},
	logoContainer: {
		backgroundColor: '#FFFFFF',
	},
	navContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	lowerNavContainer: {
		marginTop: '20px',
	},
}));

export default function AdminNavBar() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.auth.language);
	const isAuthorized = useSelector((state) => state.admin.isAuthenticated);
	const prevLocation = useSelector((state) => state.admin.prevLocation);
	const prevAdjusted = useSelector((state) => state.adjustments.prevAdjustment);
	const machineStatsPage = window.location.toString().includes('/machine-stats');
	const adjustCountsPage = window.location.toString().includes('/adjust-counts');
	const settingsPage = window.location.toString().includes('/settings');
	const operatingModePage = window.location.toString().includes('/operating-mode');
	const developersPage = window.location.toString().includes('/developers');

	const redirect = (path) => {
		if (isAuthorized) {
			return history.push(path);
		} else {
			return <Redirect to='/admin/password' />;
		}
	};

	const handleBackNavigation = () => {
		dispatch({
			type: SET_ADMIN_AUTH,
			payload: false,
		});
		history.push(prevLocation);
	};

	const handleDeveloperNavigation = () => {
		history.push('/admin/developers');
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.logoContainer}>
				<RecycletekLogo />
			</Box>
			<Box className={classes.navContainer}>
				<Box className={classes.upperNavContainer}>
					<NavButton
						label={t('admin.machineStats')}
						active={machineStatsPage}
						onClick={() => redirect('/admin/machine-stats')}
					/>
					<NavButton
						label={t('admin.operatingMode')}
						active={operatingModePage}
						onClick={() => redirect('/admin/operating-mode')}
					/>
					<NavButton
						label={t('admin.adjustCounts')}
						active={adjustCountsPage}
						disabled={prevAdjusted}
						onClick={() => redirect('/admin/adjust-counts')}
					/>
					<NavButton
						label={t('admin.settings')}
						active={settingsPage}
						onClick={() => redirect('/admin/settings')}
					/>
					<NavButton
						label={t('admin.developers')}
						unprotected={true}
						active={developersPage}
						onClick={handleDeveloperNavigation}
					/>
				</Box>
				<Box className={classes.lowerNavContainer}>
					<NavButton
						label={t('admin.backToKioskApp')}
						unprotected={true}
						onClick={handleBackNavigation}
					/>
				</Box>
			</Box>
			<AdminLogout />
		</Box>
	);
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import CustomButton from 'components/UIKit/CustomButton';
import PaymentMethods from 'components/Express/PaymentMethods';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		padding: '20px 10px',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '400px',
		minWidth: '300px',
	},
	settingsButton: {
		position: 'fixed',

		bottom: '75px',
		right: '20px',
		[theme.breakpoints.down('lg')]: {
			bottom: '10px',
			right: '140px',
		},
		'& button': {
			minWidth: '124px',
		},
	},
}));

export default function ExpressSettingsMenu({ taskEnded }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box className={classes.settingsButton}>
				<CustomButton size='small' variant='secondary' onClick={handleClick}>
					{t('paymentSelect.paymentOptions')}
				</CustomButton>
			</Box>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<Box className={classes.content}>
					<PaymentMethods taskEnded={taskEnded} />
				</Box>
			</Popover>
		</>
	);
}

// lib
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// assets
import plasticIcon from 'assets/images/recycletek-plastic-icon.png';
import glassIcon from 'assets/images/recycletek-glass-icon.png';
import aluminumIcon from 'assets/images/recycletek-aluminum-icon.png';
import notAllowed from 'assets/images/recycletek-non-deposit.png';

// components
import { getTaskStats, pauseTask, stopProcess } from 'redux/actions/process';
import CustomNotification from 'components/UIKit/CustomNotification';
import ProcessingItemCard from 'components/UIKit/ProcessingItemCard/ProcessingItemCard';
import CustomButton from 'components/UIKit/CustomButton';
import CustomBox from 'components/UIKit/CustomBox';
import RedemptionAmount from 'components/CountsAndWeight/RedemptionAmount';
import { SET_DETECTED, NEXT_STEP, SET_READY_TO_STOP, SET_MATERIAL_TO_LOAD } from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		marginBottom: '8px',
	},
	totalEarned: {
		textAlign: 'center',
		marginTop: '30px',
	},
	main: {
		alignItems: 'center',
	},
	warning: {
		fontSize: '18px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '300',
		color: theme.palette.text.primary,
	},
	itemsContainer: {
		marginTop: '60px',
		display: 'flex',
		flexDirection: 'row',
		marginRight: 'auto',
		marginLeft: 'auto',
		justifyContent: 'center',
	},
	bar: {
		height: '6px',
		width: '46%',
		opacity: 0.7,
		borderRadius: '10px',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginBottom: '22px',
		backgroundColor: theme.palette.tertiary.main,
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	buttonGroup: {
		width: '70%',
		margin: '40px auto',
		display: 'flex',
		justifyContent: 'space-evenly',
	},
}));

function StartProcess() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const selectedMaterials = useSelector((state) => state.process.selectedMaterials);
	const nonDepositCount = useSelector((state) => state.process.data.count_non_redemption);
	const glassCount = useSelector((state) => state.process.data.count_glass);
	const redemptionCount = useSelector((state) => state.process.data.redemption_amount_cents);
	const aluminumCount = useSelector((state) => state.process.data.count_aluminum);
	const plasticCount = useSelector((state) => state.process.data.count_plastic);
	const language = useSelector((state) => state.auth.language);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const modal = useSelector((state) => state.modal);
	const stop = useSelector((state) => state.process.ready_to_stop);
	const full = useSelector((state) => state.process.data.bag_full);
	const step = useSelector((state) => state.steps.activeStep);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	useEffect(() => {
		const interval = setInterval(() => {
			taskId && dispatch(getTaskStats());
		}, 2500);
		return () => clearInterval(interval);
	}, []);

	// closes custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (full) {
			dispatch(pauseTask());
			history.push('/machine-paused');
		}
	}, [full]);

	const handleContinue = () => {
		dispatch({
			type: SET_MATERIAL_TO_LOAD,
			payload: '',
		});
		dispatch(pauseTask()).then(() => {
			dispatch({ type: SET_DETECTED, payload: false });
			dispatch({ type: NEXT_STEP, payload: step - 3 });
			history.push('/begin-process');
			dispatch({ type: SET_READY_TO_STOP, payload: false }); // cleanup, if it does not reset, could trigger notification instantly
		});
	};

	return (
		<>
			<CustomBox position='relative'>
				<CustomNotification
					open={open}
					closeMessage={closeMessage}
					id={modal.id}
					heading={modal.heading}
					message={modal.message}
				/>
				<Typography className={classes.header} variant='h4'>
					{!stop ? t('startProcess.title') : t('startProcess.secondaryTitle')}
				</Typography>
				{!stop && <LinearProgress className={classes.bar} />}
				<Box className={classes.itemsContainer}>
					<ProcessingItemCard
						count={plasticCount}
						title={t('startProcess.plasticBottles')}
						img={plasticIcon}
					/>
					<ProcessingItemCard
						count={aluminumCount}
						title={t('startProcess.aluminumCans')}
						img={aluminumIcon}
					/>
					<ProcessingItemCard
						count={glassCount}
						title={t('startProcess.glass')}
						img={glassIcon}
					/>
					<ProcessingItemCard
						count={nonDepositCount}
						title={t('startProcess.nonDeposit')}
						img={notAllowed}
					/>
				</Box>
				<Box>
					{redemptionCount > 0 && <RedemptionAmount amount={redemptionCount / 100 + 0.001} />}
				</Box>
				<Box className={classes.buttonGroup}>
					<CustomButton disabled={!stop} onClick={handleContinue} size='expanded'>
						{t('common.buttons.continue')}
					</CustomButton>
				</Box>
			</CustomBox>
		</>
	);
}

export default StartProcess;

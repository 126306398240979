// lib
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// components
import oversizedIcon from 'assets/images/recycletek-oversized-icon.png';
import aluminumIcon from 'assets/images/recycletek-aluminum-icon.png';
import plasticIcon from 'assets/images/recycletek-plastic-icon.png';
import allowedIcon from 'assets/images/recycletek-green-circle.png';
import cancelIcon from 'assets/images/recycletek-not-allowed.png';
import glassIcon from 'assets/images/recycletek-glass-icon.png';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		overflow: 'hidden',
		display: 'grid',
		gridTemplateColumns: '50% 50%',
	},
	imageContainer: {
		height: '186px',
		width: '190px',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	materialImg: {
		position: 'absolute',
		zIndex: '2',
	},
	allowedImg: {
		position: 'absolute',
		opacity: '0.5',
		height: '132px',
		width: '132px',
		zIndex: '1',
	},
	cancelImg: {
		position: 'absolute',
		opacity: '0.7',
		height: '128px',
		width: '128px',
		zIndex: '3',
	},
	label: {
		position: 'absolute',
		top: '0',
	},
}));

export default function AllowedContainersImages() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const materialType = useSelector((state) => state.admin.materialType);
	const materialToLoad = useSelector((state) => state.process.materialToLoad);

	const options = {
		plastic: {
			label: t('beginProcess.plastic'),
			icon: plasticIcon,
			iconHeight: '150px',
			allowed: materialToLoad === 'plastic',
		},
		aluminum: {
			label: t('beginProcess.aluminum'),
			icon: aluminumIcon,
			iconHeight: '84px',
			allowed: materialToLoad === 'aluminum',
		},
		glass: {
			label: t('beginProcess.glass'),
			icon: glassIcon,
			iconHeight: '112px',
			allowed: false,
		},
		oversized: {
			label: t('beginProcess.oversized'),
			icon: oversizedIcon,
			iconHeight: '120px',
			allowed: false,
		},
	};

	return (
		<Box className={classes.container}>
			{Object.values(options).map((option) => (
				<Box className={classes.imageContainer}>
					<Box className={classes.label}>
						<Typography variant='body1'>{option.label}</Typography>
					</Box>
					<img
						className={classes.materialImg}
						src={option.icon}
						style={{ height: option.iconHeight }}
						alt='material type image'
					/>
					{!option.allowed && (
						<img className={classes.cancelImg} src={cancelIcon} alt='cancelled out' />
					)}
					{option.allowed && (
						<img className={classes.allowedImg} src={allowedIcon} alt='green circle' />
					)}
				</Box>
			))}
		</Box>
	);
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import { Provider } from 'react-redux';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { store } from 'redux/storage';
import 'i18n';
import 'fonts/HelveticaNeueBold.ttf';
import 'fonts/HelveticaNeueLight.ttf';
import 'fonts/HelveticaNeueMedium.ttf';
import 'fonts/HelveticaNeueThin.ttf';

if (module.hot) {
	module.hot.accept();
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

if (window.Cypress) {
	window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
		color: '#004F71',
		minWidth: '484px',
		minHeight: '260px',
		maxHeight: '260px',
		marginLeft: '-24px',
		marginTop: '-24px',
		backgroundColor: '#FFF',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
		zIndex: '400',
		overflow: 'hidden',
	},
	stepContainer: {
		position: 'absolute',
		top: '-40px',
		left: '-40px',
		width: '80px',
		height: '80px',
		transform: 'rotate(45deg)',
		backgroundColor: theme.palette.primary.dark,
	},
	stepNumber: {
		position: 'absolute',
		top: '0',
		left: '10px',
		color: '#FFFFFF',
	},
	instructions: {
		padding: '30px',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export default function EcoCardInstructions({ step, text }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Box className={classes.container}>
			<Box className={classes.stepContainer} />
			<Typography className={classes.stepNumber} variant='h6'>
				{step}
			</Typography>
			<Typography className={classes.instructions}>
				<Trans>{t(text)}</Trans>
			</Typography>
		</Box>
	);
}

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '10px',
		paddingLeft: '2px',
	},
	content: {
		position: 'relative',
	},
	notVisible: {
		position: 'absolute',
		width: '102%',
		marginLeft: '-1px',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
		backdropFilter: 'blur(4px)',
	},
}));

export default function VisibilityToggle({ content }) {
	const classes = useStyles();
	const [visible, setVisible] = useState(false);

	const classNames = clsx({
		[classes.visible]: visible,
		[classes.notVisible]: !visible,
	});

	const handleVisibility = () => {
		setVisible((current) => !current);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.content}>
				<Box className={classNames} />
				<Box>{content}</Box>
			</Box>
			<IconButton aria-label='toggle password visibility' onClick={handleVisibility}>
				{visible ? <Visibility /> : <VisibilityOff />}
			</IconButton>
		</Box>
	);
}

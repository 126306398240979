import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Odometer from 'react-odometerjs';
import { useTranslation } from 'react-i18next';
import 'odometer/themes/odometer-theme-default.css';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		bottom: '-20px',
		right: '-20px',
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
		color: '#004F71',
		width: '250px',
		minHeight: '120px',
		padding: '2px 6px',
		backgroundColor: '#FFF',
		overflow: 'hidden',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
		animation: '$fadeIn 1500ms',
	},
	value: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '7px',
		width: '100%',
		alignItems: 'center',
		'& .MuiTypography-root': {
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiTypography-root .odometer .odometer-digit:last-child': {
			visibility: 'hidden',
		},
	},
	['@keyframes fadeIn']: {
		'0%': { opacity: '0' },
		'100%': { opacity: '1' },
	},
}));

export default function RedemptionAmount({ amount }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [value, setValue] = useState(0);

	useEffect(() => {
		const timeoutId = setTimeout(() => setValue(amount), 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [amount]);

	return (
		<Box className={classes.container}>
			<Typography>{t('startProcess.totalEarned')}</Typography>
			<Box className={classes.value}>
				<Typography variant='h4'>
					<Box>$&nbsp;</Box>
					<Odometer value={value} format='(,ddd).ddd' theme='default' />
				</Typography>
			</Box>
		</Box>
	);
}

// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// components
import { confirmEcoWallet, confirmCash, confirmCharity } from 'redux/actions/modal';
import { setPaymentType, getPaymentData } from 'redux/actions/user';
import CustomNotification from 'components/UIKit/CustomNotification';
import CustomErrorBadge from 'components/UIKit/CustomErrorBadge';
import PaymentTypeCard from 'components/UIKit/PaymentTypeCard';
import CustomButton from 'components/UIKit/CustomButton';
import SetupEcoCard from 'pages/Process/SetupEcoCard';
import CustomBox from 'components/UIKit/CustomBox';

// assets
import ecoCard from 'assets/images/recycletek-ecocard.png';
import dollarBill from 'assets/images/recycletek-cash-bill.png';
import cashDonation from 'assets/images/recycletek-donate-cash.png';
import ecoWallet from 'assets/images/recycletek-ecowallet-black.png';

const useStyles = makeStyles((theme) => ({
	options: {
		width: '100%',
		padding: '8px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: '120px',
		'& span': {
			fontWeight: '100',
		},
	},
	header: {
		color: '#004F71',
		textAlign: 'center',
		fontSize: '42px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '300',
		marginTop: '27px',
	},
	paymentOption: {
		margin: '22px',
	},
	headers: {
		color: '#004F71',
		textAlign: 'center',
		fontSize: '34px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '300',
		marginTop: '16px',
	},
	buttonText: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	ecoWalletText: {
		fontWeight: '200',
	},
	submitTitleEco: {
		fontSize: '30px',
		fontFamily: 'HelveticaNeue',
		color: 'white',
		textTransform: 'none',
		fontWeight: '200',
	},
	ButtonGrid: {
		marginTop: '60px',
	},
	donateLink: {
		textDecoration: 'none',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: '10px',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default function PaymentSelect() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const modal = useSelector((state) => state.modal);
	const isGuest = useSelector((state) => state.auth.isGuest);
	const language = useSelector((state) => state.auth.language);
	const admin = useSelector((state) => state.admin);
	const payment = useSelector((state) => state.auth.paymentData);
	const [setupEcoCard, setSetupEcoCard] = useState(false);
	const [showKeepOptions, setShowKeepOptions] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [showKeep, setShowKeep] = useState(true); // temporary
	const [open, setOpen] = useState(false);

	const description = {
		cash: t('paymentSelect.cashDescription'),
		card: t('paymentSelect.ecoCardDescription'),
		wallet: isGuest
			? t('paymentSelect.ecoWalletDescriptionGuest')
			: t('paymentSelect.ecoWalletDescription'),
		donation: t('paymentSelect.donationDescription'),
		keep: t('paymentSelect.keepEarnings'),
	};

	const icons = {
		wallet: ecoWallet,
		cash: dollarBill,
		card: ecoCard,
	};

	// check if the payment data got populated in store, retry call if store is empty
	useEffect(() => {
		const paymentDataExists = Object.keys(payment).length > 0;
		const setupFromExpress = history.location.state?.setupEcoCard;

		setupFromExpress && setSetupEcoCard(true);
		!paymentDataExists && dispatch(getPaymentData());
	}, []);

	// temporary, will remove once machine id is sent in payment options call
	useEffect(() => {
		if (admin.state === 'CA' && isGuest) {
			setShowKeep(false);
		}

		if (!admin.state) {
			setErrMsg(t('messages.error.noUSStateLocationAssigned'));
		} else if (admin.state) {
			setErrMsg('');
		}
	}, [language, admin]);

	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	const setPayment = (type) => {
		const heading = t('messages.modal.pleaseConfirm');
		const methods = payment.keep?.methods;

		switch (type) {
			case 'keep':
				setShowKeepOptions(true);
				break;
			case 'donate':
				dispatch(confirmCharity(t('messages.modal.confirmDonation'), heading));
				break;
			case 'wallet':
				dispatch(confirmEcoWallet(t('messages.modal.confirmEcoWalletPayment'), heading));
				dispatch(setPaymentType(methods.wallet.name, methods.wallet.id, payment.keep.id));
				break;
			case 'cash':
				dispatch(confirmCash(t('messages.modal.confirmCash'), heading));
				dispatch(setPaymentType(methods.cash.name, methods.cash.id, payment.keep.id));
				break;
			case 'card':
				dispatch(confirmCash(t('messages.modal.confirmCard'), heading));
				dispatch(setPaymentType(methods.card.name, methods.card.id, payment.keep.id));
				break;
			default:
				dispatch(confirmCash(t('messages.modal.confirmCash'), heading));
				dispatch(setPaymentType(methods.cash.name, methods.cash.id, payment.keep.id));
		}
	};

	const getEcoTitle = (text) => {
		let formattedText = text.charAt(0).toUpperCase() + text.slice(1);
		return (
			<>
				<span>Eco</span>
				{formattedText}
			</>
		);
	};

	const getTitle = (type) => {
		const wallet = type === 'wallet';
		const card = type === 'card';
		const ecoTitle = wallet || card;

		if (ecoTitle) {
			return getEcoTitle(type);
		} else if (!ecoTitle) {
			return t(`paymentSelect.${type}`);
		}
	};

	const back = () => {
		!setupEcoCard && setShowKeepOptions(false);
		setSetupEcoCard(false);
	};

	const handleSetupCard = () => {
		setSetupEcoCard(true);
		dispatch(
			setPaymentType(payment.keep.methods.card.name, payment.keep.methods.card.id, payment.keep.id),
		);
	};

	const returnToPaymentSelect = () => {
		setSetupEcoCard(false);
	};

	return (
		<>
			<CustomBox
				title={setupEcoCard ? t('paymentSelect.setupEcoCard') : t('paymentSelect.title')}
				center={true}
				position='relative'
			>
				<CustomNotification
					open={open}
					id={modal.id}
					heading={modal.heading}
					message={modal.message}
				/>
				<CustomErrorBadge errorMessage={errMsg} />
				{!setupEcoCard && admin.state && (
					<Box className={classes.options}>
						{!showKeepOptions && (
							<>
								{showKeep && (
									<PaymentTypeCard
										title={t('paymentSelect.keep')}
										icon={dollarBill}
										description={description.keep}
										cyData='payment-btn-keep'
										handleSelect={() => setPayment('keep')}
									/>
								)}
								<PaymentTypeCard
									title={t('paymentSelect.donation')}
									icon={cashDonation}
									description={description.donation}
									cyData='payment-btn-donate'
									handleSelect={() => setPayment('donate')}
								/>
							</>
						)}

						{showKeepOptions && payment.keep && (
							<>
								{Object.values(payment.keep.methods).map((option, index) => (
									<Box className={classes.cardContainer} key={index}>
										<PaymentTypeCard
											title={getTitle(option.name)}
											name={option.name}
											hidden={admin.state === 'CA' && option.name === 'cash'}
											icon={icons[option.name]}
											description={description[option.name]}
											cyData={`payment-btn-${option.name}`}
											handleSelect={() => setPayment(option.name)}
											isGuest={isGuest}
											setupCard={handleSetupCard}
										/>
									</Box>
								))}
							</>
						)}
					</Box>
				)}
				{setupEcoCard && <SetupEcoCard returnToPaymentSelect={returnToPaymentSelect} />}
				{showKeepOptions && !setupEcoCard && (
					<Box className={classes.buttonContainer}>
						<CustomButton variant='alternate' size='small' onClick={back}>
							{t('common.buttons.back')}
						</CustomButton>
					</Box>
				)}
			</CustomBox>
		</>
	);
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/UIKit/CustomButton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	tabs: {
		marginTop: '10px',
		textAlign: 'left',
		display: 'block',
		maxWidth: '100%',
		bottom: '-2px',
		'& button': {
			marginRight: '20px',
		},
	},
}));

export default function TabMenu({ tabs, setTab, selected }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const materialToLoad = useSelector((state) => state.process.materialToLoad);

	useEffect(() => {
		autoSelectTab();
	}, [materialToLoad]);

	const handleSelected = (tab) => {
		setTab(tab);
	};

	const autoSelectTab = () => {
		const plastic = materialToLoad === 'plastic';
		const metal = materialToLoad === 'aluminum';

		plastic && handleSelected('Plastic');
		metal && handleSelected('Metal');
	};

	return (
		<Box className={classes.tabs}>
			{tabs.map((tab) => (
				<CustomButton
					selected={tab.name === selected}
					variant='primary'
					onClick={() => handleSelected(tab.name)}
				>
					{t(tab.label)}
				</CustomButton>
			))}
		</Box>
	);
}

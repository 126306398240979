import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TabMenu from 'components/UIKit/TabMenu';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'inline-block',
		width: '100%',
		minHeight: '142px',
		position: 'relative',
		padding: '16px',
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontWeight: '600',
			letterSpacing: '0.3px',
		},
	},
	tabs: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default function PageHeader({ title, tabs, setTab, selected }) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography variant='h4'>{title}</Typography>
			<Box className={classes.tabs}>
				{tabs && <TabMenu tabs={tabs} setTab={setTab} selected={selected} />}
			</Box>
		</Box>
	);
}

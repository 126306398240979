import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Transition } from 'react-transition-group';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		margin: '16px 16px 4px 16px',
		backgroundColor: theme.palette.primary.dark,
		border: '1px solid red',
		borderRadius: '4px',
		width: '200px',
		height: '300px',
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
	},
	heading: {
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		'& .MuiTypography-root': {
			color: theme.palette.text.secondary,
		},
	},
	icon: {
		'& img': {
			maxHeight: '100px',
			width: 'auto',
		},
	},
	count: {
		fontSize: '28px',
		color: '#004F71',
		backgroundColor: '#FFFFFF',
		borderRadius: '0px 0px 9px 9px',
		borderTop: '1px solid rgba(0,0,0,0.3)',
		bottom: '0px',
		paddingBottom: '20px',
		paddingTop: '20px',
		width: '100%',
		maxHeight: '123px',
		textAlign: 'center',
		minHeight: '95px',
	},
}));

const duration = 300;

const defaultStyle = {
	transition: `transform ${duration}ms ease-in-out`,
	transform: 'scale(1)',
};

const transitionStyles = {
	entering: { transform: 'scale(1.2)' },
	entered: { transform: 'scale(1)' },
	exiting: { transform: 'scale(1)' },
	exited: { transform: 'scale(1)' },
};

export default function ProcessingItemCard({ count, title, img }) {
	const classes = useStyles();
	const nodeRef = useRef(null);
	const [inProp, setInProp] = useState(false);

	useEffect(() => {
		setInProp(true);
		setTimeout(() => {
			setInProp(false);
		}, 250);
	}, [count]);

	return (
		<Box className={classes.container}>
			<Box className={classes.heading}>
				<Box className={classes.icon}>
					<img src={img} alt='material icon' />
				</Box>
				<Typography variant='h6'>{title}</Typography>
			</Box>
			<Box className={classes.count}>
				<Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
					{(state) => (
						<Box
							ref={nodeRef}
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}
						>
							<Typography variant='h4'>{count ? count : '0'}</Typography>
						</Box>
					)}
				</Transition>
			</Box>
		</Box>
	);
}

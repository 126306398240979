import { useEffect } from 'react';

// keydown event listener hook
const useKeyDown = (callback, keys) => {
	const onKeyDown = (event) => {
		const wasAnyKeyPressed = keys.some((key) => event.key === key);
		if (wasAnyKeyPressed) {
			event.preventDefault();
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [onKeyDown]);
};

export { useKeyDown };

// Example usage:
// useKeyDown(() => {
//     loginUser();
//   }, ["Enter"]);

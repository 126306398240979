import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '48px',
		boxShadow: '2px 5px 3px rgba(0,0,0,0.4)',
		opacity: 1,
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		marginRight: 'auto',
		marginLeft: 'auto',
		minWidth: '1040px',
		minHeight: '520px',
		height: '624px',
		width: '90%',
		maxWidth: '1040px',
	},
	relative: {
		position: 'relative',
	},
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	},
	header: {
		textAlign: 'center',
		margin: '16px 2px 24px 2px',
	},
	yellowHeading: {
		color: '#f7e220',
		textShadow: '2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000',
	},
	hidden: {
		display: 'none',
	},
}));

export default function CustomBox(props) {
	const classes = useStyles();
	const modal = useSelector((state) => state.modal);

	const container = clsx({
		[classes.container]: true,
		[classes.hidden]: modal.id,
		[classes.relative]: props.position === 'relative',
	});

	const content = clsx({
		[classes.content]: true,
		[classes.center]: props.center,
	});

	return (
		<Box className={container} name='data-cy-main-container'>
			<Typography
				className={`${classes.header} ${props.yellowHeading && classes.yellowHeading}`}
				variant='h4'
			>
				{props.title}
			</Typography>
			<Box className={content}>{props.children}</Box>
		</Box>
	);
}

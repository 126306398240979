import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	cautionLabel: {
		marginRight: '4px',
		color: '#BE4D00',
		backgroundColor: '#FFC845',
		padding: '1px 5px',
		borderRadius: '3px',
	},
}));

export default function CautionText({ textVariant }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Typography variant={textVariant}>
			<span className={classes.cautionLabel}>{t('ecoVision.caution')}</span>{' '}
			{t('ecoVision.cautionText')}
		</Typography>
	);
}

// lib
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// mui
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

// components
import DevelopmentInfoPanel from 'components/Admin/DevelopmentInfoPanel';
import { bluetoothPrint } from 'utils/printer';
import CustomButton from 'components/UIKit/CustomButton';
import PageHeader from 'components/Admin/PageHeader';
import Section from 'components/Admin/Section';

const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px',
	},
}));

export default function DevelopmentInfo() {
	const classes = useStyles();
	const dryrun = useSelector((state) => state.process.dryrun);
	const throwError = useSelector((state) => state.process.throwError);

	const handleBluetoothPrint = () => {
		bluetoothPrint('test');
	};

	return (
		<>
			<PageHeader title='Developers Info Panel' />
			<Section>
				<DevelopmentInfoPanel dryrun={dryrun} throwError={throwError} />
			</Section>

			<Section title='Bluetooth Printer'>
				<Box>
					<Typography>
						Test a bluetooth-connected StarMicronics Printer. Note:{' '}
						<Link
							href='https://apps.apple.com/us/app/star-passprnt/id979827520?platform=ipad'
							target='_blank'
							color='secondary'
						>
							PassPrnt-app
						</Link>
						&nbsp; must be installed and set-up.
					</Typography>
				</Box>
				<Box className={classes.buttonContainer}>
					<CustomButton
						variant='secondary'
						size='small'
						name='cy-settings-test_prnt'
						disabled={false}
						onClick={handleBluetoothPrint}
					>
						Print
					</CustomButton>
				</Box>
			</Section>
		</>
	);
}

import { SET_METRICS } from 'redux/actions/types';
import { SERVER_URL } from 'redux/actions/config.js';
import { configHeader, employeeConfigHeader } from 'utils/configHeader';
import axios from 'axios';

export const getMetrics = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();
		axios
			.get(`${SERVER_URL}/customer/stats`, config)
			.then((res) => {
				const data = res.data;
				dispatch({
					type: SET_METRICS,
					payload: { ...data },
				});
				resolve();
			})
			.catch((err) => {
				console.error('Metrics Error:', err);
			});
	});
};

export const getLedgerEntries = (date) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const machine = getState().process.machine;
		const payload = {
			date,
			machine_id: machine.belt_id,
		};
		const body = JSON.stringify(payload);

		axios
			.post(`${SERVER_URL}/tenant/per_machine_ledger`, body, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log('Error retrieving ledger entries:', err);
				reject();
			});
	});
};

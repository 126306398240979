// lib
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// components
import WeightLimitInfo from 'pages/Express/WeightLimitInfo';
import ExpressInfoMenu from 'pages/Express/ExpressInfoMenu';

const useStyles = makeStyles((theme) => ({
	info: {
		border: `1px solid ${theme.palette.grey.main}`,
		overflow: 'hidden',
		marginBottom: '30px',
		width: '155px',
		borderRadius: '4px',
		padding: '8px',
		'& .MuiTypography-root': {
			fontSize: '16px',
		},
		'& .MuiTypography-caption': {
			fontSize: '18px',
		},
	},
	section: {
		marginTop: '20px',
		'&:first-of-type': {
			marginTop: '0px',
		},
	},
	hiddenSmDown: {
		'@media (max-width: 1265px)': {
			display: 'none',
		},
	},
	hiddenSmUp: {
		'@media (min-width: 1265px)': {
			display: 'none',
		},
	},
	weightInfo: {
		'& .MuiTypography-body2': {
			marginBottom: 'unset',
			marginTop: 'unset',
		},
	},
}));

export default function ExpressInfoSection() {
	const classes = useStyles();
	const { t } = useTranslation();
	const auth = useSelector((state) => state.auth);
	const location = useSelector((state) => state.admin.state);
	const language = useSelector((state) => state.auth.language);

	return (
		<>
			{/* TOP INFO SECTION, SMALLER SCREENS */}
			<Box className={classes.hiddenSmUp}>
				<ExpressInfoMenu customer={auth.customer} paymentType={auth.paymentType} />
			</Box>
			{/* LEFT INFO SECTION, LARGER SCREENS */}
			<Box className={`${classes.sectionLeft} ${classes.hiddenSmDown}`}>
				<Box className={classes.info}>
					<Box className={classes.section}>
						<Typography>{t('express.customer')}</Typography>
						<Typography variant='caption'>
							{auth.customer.first_name || auth.customer.email}
						</Typography>
					</Box>
					<Box className={classes.section}>
						<Typography>{t('express.paymentMethod')}:</Typography>
						<Typography variant='caption'>{auth.paymentType}</Typography>
						<Box className={classes.weightInfo}>
							{location === 'CA' && <WeightLimitInfo />}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
}

import React from 'react';
import spinner from './spinner.css'; // this import is needed do not delete
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	loadingText: {
		marginTop: '40px',
	},
}));

export default function CustomSpinner({ text }) {
	const classes = useStyles();

	return (
		<div>
			<div className='main'>
				<div className='inside'></div>
			</div>
			<Typography variant='body1' className={classes.loadingText}>
				{text}
			</Typography>
		</div>
	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		overflowY: 'scroll',
		height: '100%',
		paddingBottom: '50px',
		'&::-webkit-scrollbar': {
			width: '10px',
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey.main,
			borderRadius: '8px',
		},
	},
	hidden: {
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
}));

export default function ScrollContainer(props) {
	const classes = useStyles();

	const classNames = clsx({
		[classes.container]: true,
		[classes.hidden]: props.hideScrollBar,
	});

	return <Box className={classNames}>{props.children}</Box>;
}

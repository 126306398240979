import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import MachineStats from 'pages/Admin/MachineStats';
import AdjustCounts from 'pages/Admin/AdjustCounts';
import OperatingMode from 'pages/Admin/OperatingMode';
import Settings from 'pages/Admin/Settings';

export default function ProtectedRoutes() {
	const isAuthorized = useSelector((state) => state.admin.isAuthenticated);

	if (!isAuthorized) {
		return <Redirect to='/admin/password' />;
	} else {
		return (
			<>
				<Route path={'/admin/operating-mode'} component={OperatingMode} />
				<Route path={'/admin/machine-stats'} component={MachineStats} />
				<Route path={'/admin/adjust-counts'} component={AdjustCounts} />
				<Route path={'/admin/settings'} component={Settings} />
			</>
		);
	}
}

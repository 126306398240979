import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-verification-code-input';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// assets/components
import logo from 'assets/images/logo-gradient-full.png';
import PortalTextField from 'components/UIKit/PortalTextField';
import AnimatedCheckmark from 'components/UIKit/AnimatedCheckmark/AnimatedCheckmark';
import PasswordValidation from 'pages/SignUp/PasswordValidation';
import CustomButton from 'components/UIKit/CustomButton';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import GoogleAddressAutoComplete from 'components/UIKit/GoogleAddressAutoComplete';
import SelectATenantWindow from 'pages/SignUp/SelectATenantWindow';
import {
	signUpUserFull,
	verificationRequest,
	verificationStart,
	emailAndPhoneVerification,
	getTenantByShortcode,
} from 'redux/actions/auth';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		height: '100vh',
		[theme.breakpoints.down('sm')]: {
			padding: '10px',
			paddingBottom: '100px',
		},
	},
	logo: {
		height: '84px',
		margin: '26px',
		[theme.breakpoints.down('lg')]: {
			height: '52px',
			margin: '14px',
		},
	},
	main: {
		width: '100%',
	},
	form: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '20px',
	},
	inputs: {
		marginTop: '40px',
		width: '650px',
		marginBottom: '60px',
		'& .MuiInputBase-root': {
			marginTop: '20px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			maxWidth: '100vw',
		},
	},
	info: {
		padding: '20px',
		width: '60%',
		backgroundColor: theme.palette.secondary.dark,
		color: theme.palette.text.secondary,
		'& .MuiTypography-body1': {
			marginBottom: '10px',
		},
		'& .MuiTypography-body2': {
			marginBottom: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	inputPassword: {
		'-webkit-text-security': 'disc',
	},
	apt: {
		color: theme.palette.secondary.dark,
		display: 'flex',
		marginTop: '20px',
		marginLeft: '110px',
		alignItems: 'center',
		'& .MuiTypography-root': {
			marginLeft: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '50px',
		},
	},
	button: {
		marginTop: '40px',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	buttonGroup: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '650px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100vw',
		},
	},
	captcha: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	question: {
		marginBottom: '20px',
	},
	captchaImages: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '350px',
		'& img': {
			height: '150px',
			width: 'auto',
			margin: '10px',
		},
	},
	verificationCodes: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& h6': {
			textAlign: 'center',
		},
	},
	verificationInput: {
		marginTop: '50px',
		marginBottom: '20px',
	},
	thankYou: {
		width: '100%',
		marginTop: '40px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		'& h5': {
			marginBottom: '20px',
		},
	},
	icon: {
		marginTop: '20px',
		color: theme.palette.primary.dark,
	},
	footer: {
		position: 'absolute',
		bottom: '10px',
		left: '10px',
	},
}));

// TODO: Add the phone and email verification for step 3, and then submit the customer data

export default function FullSignUp() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const uniqueId = uuid().slice(0, 8);
	const tenantShortcode = useSelector((state) => state.admin.tenantShortcode);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [firstName, setFirstName] = useState('');
	const [captchaData, setCaptchaData] = useState(null);
	const [password, setPassword] = useState('');
	const [lastName, setLastName] = useState('');
	const [showApt, setShowApt] = useState(false);
	const [verificationIds, setVerificationIds] = useState({});
	const [emailVerificationCode, setEmailVerificationCode] = useState('');
	const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
	const [snackbarMessage, setSnackbarMessage] = useState({ text: '', status: '' });
	const [captchaCount, setCaptchaCount] = useState(null);
	const [address, setAddress] = useState(null);
	const [count, setCount] = useState(0);
	const [email, setEmail] = useState('');
	const [step, setStep] = useState(1);
	const [apt, setApt] = useState('');

	const [showTenantCodes, setShowTenantCodes] = useState(false);
	const [associatedSignup, setAssociatedSignup] = useState('');

	useEffect(() => {
		checkTenantByShortCode();
	}, []);

	const formatBusinessName = (business) => {
		// some special characters show up in html as the unconverted character code eg. &amp;
		const formatted = business.replace('&', ' and ');

		setAssociatedSignup(formatted);
	};

	// note: a tenant is typically a redemption center
	const checkTenantByShortCode = () => {
		const hash = window.location.href.split('#')[1];

		if (hash) {
			dispatch(getTenantByShortcode(hash))
				.then((business) => {
					formatBusinessName(business);
					setShowTenantCodes(false);
				})
				.catch((res) => {
					setShowTenantCodes(true);
				});
		}
	};

	const hideTenantSelect = () => {
		setShowTenantCodes(false);
	};

	const clearData = () => {
		setPhoneNumber('');
		setFirstName('');
		setCaptchaData(null);
		setPassword('');
		setLastName('');
		setShowApt(false);
		setVerificationIds({});
		setEmailVerificationCode('');
		setPhoneVerificationCode('');
		setCaptchaCount(null);
		setAddress(null);
		setStep(1);
	};

	const next = () => {
		setStep((prevState) => prevState + 1);
	};

	const back = () => {
		setStep((prevState) => prevState - 1);
	};

	const handleShowApt = () => {
		setShowApt(true);
	};

	const handleSetAddress = (selectedAddress) => {
		const data = selectedAddress.address_components;
		const formattedAddress = {
			street: data[1].long_name,
			housenumber: data[0].long_name,
			state: data[5].short_name,
			city: data[2].long_name,
			apt: apt,
			zipcode: data[7].long_name,
		};
		setAddress(formattedAddress);
	};

	const submitNewCustomer = () => {
		const newCustomer = {
			type: 0,
			password: password,
			first_name: firstName,
			last_name: lastName,
			street: address.street,
			housenumber: address.housenumber,
			apt: apt,
			city: address.city,
			state: address.state,
			zipcode: address.zipcode,
			email: email,
			phone_number: phoneNumber.replace(/[- )(]/g, ''),
			from_tenant_shortcode: tenantShortcode,
			preferred_cashout_option: 2,
			preferred_payment_option: 1,
		};

		!email && delete newCustomer.email;
		!phoneNumber && delete newCustomer.phone_number;

		dispatch(signUpUserFull(newCustomer))
			.then(() => {
				setStep(5);
				setTimeout(() => {
					clearData();
				}, 4000);
			})
			.catch(() => {
				setSnackbarMessage({
					message: t('mobileSignup.errorCreatingAccount'),
					status: 'error',
				});
			});
	};

	const closeSnackbar = () => {
		setSnackbarMessage({ message: '', status: '' });
	};

	// formats to (###) ###-####
	const formatPhoneNumber = (input) => {
		input = input.replace(/\D/g, '').substring(0, 10); //Strip everything but 1st 10 digits
		var size = input.length;
		if (size > 0) {
			input = '(' + input;
		}
		if (size > 3) {
			input = input.slice(0, 4) + ') ' + input.slice(4);
		}
		if (size > 6) {
			input = input.slice(0, 9) + '-' + input.slice(9);
		}
		setPhoneNumber(input);
	};

	// makes a request to receive captcha data like photos
	const handleVerification = () => {
		dispatch(verificationRequest(email, phoneNumber.replace(/[- )(]/g, '')))
			.then((res) => {
				setCaptchaData(res.data);
				next();
			})
			.catch((err) => {
				setSnackbarMessage({
					text: t('mobileSignup.errorCaptcha'),
					status: 'error',
				});
			});
	};

	// submits the captcha answer and the associated id returned earlier
	const handleCaptchaSubmit = () => {
		dispatch(verificationStart(captchaCount, captchaData.id))
			.then((res) => {
				setVerificationIds(res.data);
				next();
			})
			.catch((err) => {
				// TODO: add err message
				console.error(err);
			});
	};

	// takes the code input by customer and submits with the associated id returned earlier
	const handleVerificationCodes = () => {
		const emailVerification = {
			id: verificationIds.id_email_auth,
			code: emailVerificationCode,
		};

		const phoneVerification = {
			id: verificationIds.id_phone_number_auth,
			code: phoneVerificationCode,
		};

		dispatch(emailAndPhoneVerification(emailVerification, phoneVerification))
			.then(() => {
				submitNewCustomer();
			})
			.catch(() => {
				setSnackbarMessage({
					text: t('mobileSignup.errorConfirmationCodes'),
					status: 'error',
				});
			});
	};

	const handleLogoClick = () => {
		setCount((prevState) => prevState + 1);
		if (count === 4) {
			setStep(1);
			clearData();
			setCount(0);
		}
	};

	return (
		<>
			<CustomSnackbar
				message={snackbarMessage.text}
				status={snackbarMessage.status}
				close={closeSnackbar}
				showCloseButton
			/>
			<Box className={classes.container}>
				<Box className={classes.main}>
					<img
						onClick={handleLogoClick}
						className={classes.logo}
						src={logo}
						alt='Recycletek logo'
					/>
					<Box className={classes.form}>
						<Typography variant='h4'>{t('mobileSignup.memberSignUp')}</Typography>
						{showTenantCodes && <SelectATenantWindow hideTenantSelect={hideTenantSelect} />}
						{!showTenantCodes && (
							<Box className={classes.inputs}>
								{step === 1 && (
									<>
										<PortalTextField
											id={uniqueId}
											placeholder={t('mobileSignup.firstName')}
											fullWidth
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
										<PortalTextField
											id={uniqueId}
											placeholder={t('mobileSignup.lastName')}
											fullWidth
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
										<PortalTextField
											id={uniqueId}
											className={classes.inputPassword}
											placeholder={t('mobileSignup.password')}
											type='password'
											fullWidth
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<PasswordValidation input={password} doNotMatch />
										<Box className={classes.button}>
											<CustomButton
												disabled={!firstName || !lastName || !password}
												onClick={next}
											>
												{t('common.buttons.continue')}
											</CustomButton>
										</Box>
									</>
								)}
								{step === 2 && (
									<>
										<PortalTextField
											id={uniqueId}
											placeholder={t('mobileSignup.phoneNumber')}
											value={phoneNumber}
											fullWidth
											onChange={(e) => formatPhoneNumber(e.target.value)}
										/>
										<PortalTextField
											id={uniqueId}
											placeholder={t('mobileSignup.email')}
											fullWidth
											onChange={(e) => setEmail(e.target.value)}
										/>
										<GoogleAddressAutoComplete
											id={uniqueId}
											setAddress={handleSetAddress}
										/>
										{!showApt && (
											<Box className={classes.apt} onClick={handleShowApt}>
												<AddCircleOutlineIcon />
												<Typography variant='body2'>
													<strong>{t('mobileSignup.addApt')}</strong>
												</Typography>
											</Box>
										)}
										{showApt && (
											<PortalTextField
												id={uniqueId}
												placeholder={t('mobileSignup.apt')}
												fullWidth
												value={apt}
												onChange={(e) => setApt(e.target.value)}
											/>
										)}
										<Box className={classes.buttonGroup}>
											<CustomButton variant='alternate' onClick={back}>
												{t('common.buttons.back')}
											</CustomButton>
											<CustomButton onClick={handleVerification}>
												{t('mobileSignup.submit')}
											</CustomButton>
										</Box>
									</>
								)}
								{step === 3 && (
									<Box className={classes.captcha}>
										<Typography className={classes.question}>
											{captchaData.looking_for === 'plastic'
												? t('mobileSignup.howManyPlastic')
												: t('mobileSignup.howManyAlu')}
										</Typography>
										<Box className={classes.captchaImages}>
											{captchaData.images?.map((image, index) => (
												<img
													key={index}
													src={`data:image/png;base64, ${image}`}
												/>
											))}
										</Box>
										<PortalTextField
											id={uniqueId}
											placeholder={t('mobileSignup.enterCount')}
											value={captchaCount}
											onChange={(e) => setCaptchaCount(e.target.value)}
										/>
										<Box className={classes.button}>
											<CustomButton onClick={handleCaptchaSubmit}>
												{t('mobileSignup.submit')}
											</CustomButton>
										</Box>
									</Box>
								)}
								{step === 4 && (
									<Box className={classes.verificationCodes}>
										<Typography variant='h6'>
											{t('mobileSignup.checkForCodes')}
										</Typography>
										{email && (
											<Box className={classes.verificationInput}>
												<Typography>{t('mobileSignup.emailCode')}</Typography>
												<ReactCodeInput
													className={classes.numberInput}
													id='email_verification_code'
													name='email verification code'
													type='number'
													fields={4}
													autoFocus={false}
													onChange={(e) => setEmailVerificationCode(e)}
												/>
											</Box>
										)}
										{phoneNumber && (
											<Box className={classes.verificationInput}>
												<Typography>{t('mobileSignup.phoneCode')}</Typography>
												<ReactCodeInput
													className={classes.numberInput}
													id='phone_verification_code'
													name='phone verification code'
													type='number'
													fields={4}
													autoFocus={false}
													onChange={(e) => setPhoneVerificationCode(e)}
												/>
											</Box>
										)}
										<Box className={classes.button}>
											<CustomButton onClick={handleVerificationCodes}>
												{t('mobileSignup.submit')}
											</CustomButton>
										</Box>
									</Box>
								)}
								{step === 5 && (
									<Box className={classes.thankYou}>
										<Typography variant='h5'>
											{t('mobileSignup.accountCreatedSuccessfully')}
										</Typography>
										<AnimatedCheckmark />
									</Box>
								)}
							</Box>
						)}
					</Box>
				</Box>
				<Box className={classes.info}>
					<Typography>
						<strong>{t('mobileSignup.whyNumberAndEmail')}</strong>
					</Typography>
					<Typography variant='body2'>{t('mobileSignup.emailExplanation')}</Typography>
					<Typography>
						<strong>{t('mobileSignup.whyAddress')}</strong>
					</Typography>
					<Typography variant='body2'>{t('mobileSignup.addressExplanation')}</Typography>
				</Box>
				<Box className={classes.footer}>
					{associatedSignup && <Typography variant='caption'>{associatedSignup}</Typography>}
				</Box>
			</Box>
		</>
	);
}

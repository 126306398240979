import axios from 'axios';
import {
	WALLET_BALANCE,
	MODAL_LOGOUT,
	SET_PAYMENT_ID,
	SET_PAYMENT_TYPE,
	MODAL_GENERAL_ERROR,
	SET_PAYMENT_DATA,
} from './types';
import { SERVER_URL } from './config';
import i18next from 'i18next';
import { configHeader, employeeConfigHeader } from '../../utils/configHeader';

export const getTransactionHistory = () => (dispatch, getState) => {
	const config = configHeader();
	return axios.get(`${SERVER_URL}/ledger/`, config);
};

export const getCustomerBalance = () => async (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch(getTransactionHistory())
			.then((res) => {
				dispatch({
					type: WALLET_BALANCE,
					payload: res.data[0],
				});
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

// Logout Modal
export const signUpError = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_LOGOUT,
		payload: {
			message,
			heading: t('Please Confirm', 'spa001'),
		},
	});
};

export const setPaymentType = (paymentType, paymentOptionId, paymentTypeId) => (dispatch, getState) => {
	dispatch({
		type: SET_PAYMENT_TYPE,
		payload: {
			type: paymentType,
			id: paymentTypeId, // eg. "1" for keep, "2" for donate
			optionId: paymentOptionId, // eg. "2" for ecowallet, "3" for ecocard
		},
	});
};

export const setPaymentId = (id) => (dispatch, getState) => {
	dispatch({
		type: SET_PAYMENT_ID,
		payload: id,
	});
};

export const getPaymentData = () => (dispatch, getState) => {
	const config = configHeader();
	const getPaymentOptions = axios.get(`${SERVER_URL}/ledger/paymentoptions`, config);
	const getCashoutOptions = axios.get(`${SERVER_URL}/ledger/cashoutoptions`, config);

	return new Promise((resolve, reject) => {
		Promise.all([getPaymentOptions, getCashoutOptions])
			.then((res) => {
				const paymentOptions = res[0].data;
				const cashoutOptions = res[1].data;
				const options = {};

				paymentOptions.map((option) => {
					if (option.name === 'keep') options.keep = option;
					if (option.name === 'donate') options.donate = option;
				});

				cashoutOptions.map((option) => {
					const method = option.name;
					options.keep.methods = { ...options.keep.methods, [method]: option };
				});

				dispatch({
					type: SET_PAYMENT_DATA,
					payload: options,
				});

				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.msg;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: '',
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject(err);
			});
	});
};

export const getCharities = () => (dispatch, getState) => {
	const config = configHeader();
	return new Promise((resolve, reject) => {
		axios
			.get(`${SERVER_URL}/donee/`, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const setupEcoCard = (barcode) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader(30000);
		const auth = getState().auth;
		const process = getState().process;
		const payload = {
			customer_id: auth.customer?.id,
			card_package_id: barcode,
			dryrun: process.dryrun,
		};
		const body = JSON.stringify(payload);

		axios
			.post(`${SERVER_URL}/customer/create_card_account`, body, config)
			.then(() => {
				resolve();
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const ecoCardPayout = (cents) => (dispatch, getState) => {
	const config = configHeader();
	const payoutInDollars = cents / 100;
	const process = getState().process;
	const payload = {
		payout_amount: payoutInDollars.toFixed(2),
		handler: 'onbe',
	};
	const body = JSON.stringify(payload);

	if (!process.dryrun) {
		axios
			.post(`${SERVER_URL}/customer/payout_onbe`, body, config)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				const errorInfo = err.response.data.msg;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: i18next.t('messages.modal.failedToPayoutEcoCard'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
			});
	} else if (process.dryrun) {
		alert(
			`This is a dryrun, the call to /customer/payout_onbe with a payload of ${body} will be skipped.`,
		);
	}
};

export const cashPayout = () => (dispatch, getState) => {
	const config = configHeader();
	const taskId = getState().process.prevTaskId;
	const payload = {
		task_id: taskId,
	};
	const body = JSON.stringify(payload);

	axios
		.post(`${SERVER_URL}/customer/payout_cash_for_task`, body, config)
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			const errorInfo = err.response.data.msg;
			dispatch({
				type: MODAL_GENERAL_ERROR,
				payload: {
					message: 'Cash payout failed.',
					info: `${errorInfo ? errorInfo : err}`,
					heading: i18next.t('messages.modal.error'),
				},
			});
		});
};

export const freeBagsReceived = (password) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const process = getState().process;
		const customer = getState().auth.customer;
		const payload = {
			belt_id: process.machine?.belt_id,
			customer_id: customer.id,
			password: password,
		};
		const body = JSON.stringify(payload);

		axios
			.post(`${SERVER_URL}/task/handed_out_bags`, body, config)
			.then((res) => {
				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.message;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: i18next.t('messages.modal.failedCallToHandedOutBags'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
			});
	});
};


// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_LOGOUT } from 'redux/actions/types';
import { useTranslation } from 'react-i18next';

// components
import CustomStepper from 'components/UIKit/CustomStepper';
import LogoutButton from 'components/Background/LogoutButton';
import backgroundLogo from 'assets/images/logo_gradient_cropped.svg';
import RecycletekLogo from 'components/Background/RecycletekLogo';
import WelcomeSection from 'components/Background/WelcomeSection';
import HopperLockIcon from 'components/Background/HopperLockIcon';

//  mui
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	'@global': {
		body: {
			height: '101vh',
			backgroundImage: `url(${backgroundLogo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right bottom',
			backgroundSize: '38%',
			overflow: 'hidden',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
	},
	header: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: '1fr',
	},
	topRight: {
		marginRight: '34px',
		marginTop: '34px',
		[theme.breakpoints.down('lg')]: {
			margin: '12px',
		},
	},
	topCenter: {
		width: '650px',
		position: 'absolute',
		top: '8px',
		left: '50%',
		transform: 'translate(-50%, -0%)',
		[theme.breakpoints.down('sm')]: {
			width: '400px',
			'& .MuiTypography-root': {
				fontSize: '12px',
			},
		},
	},
	content: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	lockIcon: {
		position: 'absolute',
		top: '50%',
		right: '20px',
		zIndex: '600',
	},
	logout: {
		position: 'absolute',
		left: '10px',
		bottom: '10px',
	},
}));

export default function Background(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const activeStep = useSelector((state) => state.steps.activeStep);
	const language = useSelector((state) => state.auth.language);
	const containerType = useSelector((state) => state.process.materialToLoad);
	const step = useSelector((state) => state.steps.activeStep);
	const [showLockIcon, setShowLockIcon] = useState(false);
	const [lockStatus, setLockStatus] = useState('open');

	useEffect(() => {
		const stepZero = step === 0;
		const stepOne = step === 1;

		if (containerType === 'other' && (stepZero || stepOne)) {
			setShowLockIcon(true);
			setLockStatus('open');
		} else if (step === 2) {
			setLockStatus('closed');
		} else if (step > 2 || !containerType) {
			setShowLockIcon(false);
		}
	}, [containerType, step]);

	const logout = () => {
		dispatch({
			type: MODAL_LOGOUT,
			payload: {
				message: t('messages.modal.confirmLogout'),
				heading: t('messages.modal.pleaseConfirm'),
			},
		});
	};

	return (
		<>
			<Box className={classes.header}>
				<Box className={classes.topLeft}>
					<RecycletekLogo />
				</Box>
				<Box className={classes.topCenter}>
					<CustomStepper />
				</Box>
				<Box className={classes.topRight}>
					<WelcomeSection />
				</Box>
				<HopperLockIcon />
			</Box>

			<Box className={classes.content}>{props.children}</Box>

			<Box className={classes.logout}>{activeStep !== 2 && <LogoutButton logout={logout} />}</Box>
		</>
	);
}

// lib
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// mui
import { Box } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';

// components
import PaymentSelectDemo from 'components/DemoMode/PaymentSelectDemo.js';
import CustomButton from 'components/UIKit/CustomButton';
import ExpressDemo from 'components/DemoMode/ExpressDemo';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'fixed',
		top: '90px',
		right: '20px',
		zIndex: '5',
	},
	popover: {
		minHeight: '200px',
		minWidth: '400px',
		padding: '5px 15px',
	},
	closeButton: {
		width: '100%',
		textAlign: 'right',
	},
}));

const pages = {
	paymentSelect: '/payment-select',
	express: '/express',
};

export default function DemoMode() {
	const classes = useStyles();
	const location = useLocation();
	const demoMode = useSelector((state) => state.admin.demoMode);
	const [anchorEl, setAnchorEl] = useState(null);
	const available = [pages.paymentSelect, pages.express];

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{demoMode && (
				<>
					<Box className={classes.container}>
						<CustomButton
							size='small'
							variant='secondary'
							disabled={!available.includes(location.pathname)}
							onClick={handleClick}
						>
							Info
						</CustomButton>
					</Box>
					<Popover
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
					>
						<Box className={classes.popover}>
							<PaymentSelectDemo show={location.pathname === pages.paymentSelect} />
							<ExpressDemo show={location.pathname === pages.express} />
						</Box>
					</Popover>
				</>
			)}
		</>
	);
}

// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// mui
import { isTaskReady } from 'redux/actions/process';
import { Redirect } from 'react-router-dom';
import ItemDetect from 'pages/Process/ItemDetect';
import Box from '@material-ui/core/Box';

// components
import { NEXT_STEP, SET_MATERIAL_TO_LOAD } from 'redux/actions/types';
import LoadHopperInstructions from 'pages/Process/LoadHopper/LoadHopperInstructions';
import SelectContainerType from 'pages/Process/LoadHopper/SelectContainerType';
import CustomNotification from 'components/UIKit/CustomNotification';
import CustomButton from 'components/UIKit/CustomButton';
import CustomBox from 'components/UIKit/CustomBox';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-evenly',
	},
}));

function BeginProcess() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const itemsDetected = useSelector((state) => state.process.detected);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const step = useSelector((state) => state.steps.activeStep);
	const modal = useSelector((state) => state.modal);
	const language = useSelector((state) => state.auth.language);
	const typeSelected = useSelector((state) => state.process.materialToLoad);
	const [detectingItems, setDetectingItems] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	// third step if hopper is successfully primed
	const isReady = () => {
		setDetectingItems(false);
		dispatch({ type: NEXT_STEP, payload: step });
	};

	// checks if hopper is successfully primed, second step
	const detecting = () => {
		dispatch(isTaskReady())
			.then(() => {
				isReady();
			})
			.catch(() => {
				setDetectingItems(false);
			});
	};

	// inital step in the item detection process
	const detectItem = () => {
		setDetectingItems(true); // controls the loading spinner that reads "Detecting items..."
		detecting();
	};

	const back = () => {
		dispatch({
			type: SET_MATERIAL_TO_LOAD,
			payload: '',
		});
	};

	if (detectingItems) {
		return <ItemDetect />;
	}

	if (itemsDetected && !detectingItems) {
		return <Redirect to='/eco-vision' />;
	}

	return (
		<>
			<CustomBox
				title={
					typeSelected
						? t('beginProcess.instructionsTitle')
						: t('beginProcess.recycleTypeTitle')
				}
				position='relative'
				center={typeSelected}
			>
				<CustomNotification
					open={open}
					id={modal.id}
					heading={modal.heading}
					message={modal.message}
				/>
				<Box className={classes.container}>
					{typeSelected ? (
						<LoadHopperInstructions type={typeSelected} />
					) : (
						<SelectContainerType />
					)}
					{typeSelected && (
						<Box className={classes.buttonContainer}>
							<CustomButton variant='alternate' onClick={back}>
								{t('common.buttons.back')}
							</CustomButton>
							{typeSelected !== 'other' && (
								<CustomButton variant='primary' onClick={detectItem}>
									{t('common.buttons.continue')}
								</CustomButton>
							)}
						</Box>
					)}
				</Box>
			</CustomBox>
		</>
	);
}

export default BeginProcess;

import {
	ADJUST_COUNT_PETE,
	ADJUST_COUNT_ALU,
	ADJUST_COUNT_GLS,
	ADJUST_COUNT_BMT,
	ADJUST_COUNT_HDPE,
	ADJUST_COUNT_LDPE,
	ADJUST_COUNT_PP,
	ADJUST_COUNT_PS,
	ADJUST_COUNT_OTHER,
	ADJUST_COUNT_BIB,
	ADJUST_COUNT_MLP,
	ADJUST_COUNT_PBC,
	ADJUST_COUNT_PVC,
	ADD_SCRAP_ITEM,
	DELETE_SCRAP_ITEM,
	SET_ECOCOUNT_SCRAP,
	ADD_ECOCOUNT_SCRAP_ITEM,
	SET_ECOCOUNT_SCRAP_TOTAL,
	DELETE_ECOCOUNT_SCRAP_ITEM,
	RESET_ADJUSTMENTS_STATE,
	PREV_ADJUSTMENT,
} from '../actions/types';

const initialState = {
	prevAdjustment: false,
	materials: {
		['PETE']: { sm: 0, md: 0 },
		['ALU']: { sm: 0, md: 0 },
		['GLS']: { sm: 0, md: 0 },
		['BMT']: { sm: 0, md: 0 },
		['HDPE']: { sm: 0, md: 0 },
		['LDPE']: { sm: 0, md: 0 },
		['PP']: { sm: 0, md: 0 },
		['PS']: { sm: 0, md: 0 },
		['PVC']: { sm: 0, md: 0 },
		['OTHER']: { sm: 0, md: 0 },
		['BIB']: { sm: 0, md: 0 }, // bag-in-box, use md only - one size
		['MLP']: { sm: 0, md: 0 }, // multilayed-pouch, use md only - one size
		['PBC']: { sm: 0, md: 0 }, // paperboard-carton, use md only - one size
	},
	scrap: {
		['PETE']: [],
		['ALU']: [],
		['GLS']: [],
		['HDPE']: [],
		['LDPE']: [],
		['BMT']: [],
		['PP']: [],
		['PS']: [],
		['PVC']: [],
		['OTHER']: [],
		['BIB']: [],
		['MLP']: [],
		['PBC']: [],
	},
	ecoCountScrap: {},
	ecoCountScrapTotal: 0,
};

// TODO: refactor adjustment cases into a singular action like the current delete srap action
export const adjustments = (state = initialState, action) => {
	switch (action.type) {
		case PREV_ADJUSTMENT:
			return {
				...state,
				prevAdjustment: action.payload,
			};
		case ADJUST_COUNT_PETE:
			return {
				...state,
				materials: {
					...state.materials,
					['PETE']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_ALU:
			return {
				...state,

				materials: {
					...state.materials,
					['ALU']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_GLS:
			return {
				...state,

				materials: {
					...state.materials,
					['GLS']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_BMT:
			return {
				...state,

				materials: {
					...state.materials,
					['BMT']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_HDPE:
			return {
				...state,

				materials: {
					...state.materials,
					['HDPE']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_PVC:
			return {
				...state,

				materials: {
					...state.materials,
					['PVC']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_LDPE:
			return {
				...state,

				materials: {
					...state.materials,
					['LDPE']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_PP:
			return {
				...state,

				materials: {
					...state.materials,
					['PP']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_PS:
			return {
				...state,

				materials: {
					...state.materials,
					['PS']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_BIB:
			return {
				...state,

				materials: {
					...state.materials,
					['BIB']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_MLP:
			return {
				...state,

				materials: {
					...state.materials,
					['MLP']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_PBC:
			return {
				...state,

				materials: {
					...state.materials,
					['PBC']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADJUST_COUNT_OTHER:
			return {
				...state,

				materials: {
					...state.materials,
					['OTHER']: { sm: action.payload.sm, md: action.payload.md },
				},
			};
		case ADD_SCRAP_ITEM:
			return {
				...state,
				scrap: {
					...state.scrap,
					[`${action.payload.material}`]: [
						...state.scrap[action.payload.material],
						action.payload.entry,
					],
				},
			};

		case DELETE_SCRAP_ITEM:
			return {
				...state,
				scrap: {
					...state.scrap,
					[`${action.payload.material}`]: state.scrap[action.payload.material].filter(
						(item, index) => item !== action.payload.entry,
					),
				},
			};
		case SET_ECOCOUNT_SCRAP:
			return {
				...state,
				ecoCountScrap: action.payload,
			};
		case ADD_ECOCOUNT_SCRAP_ITEM:
			return {
				...state,
				ecoCountScrap: {
					...state.ecoCountScrap,
					[`${action.payload.material}`]: [
						...state.ecoCountScrap[action.payload.material],
						action.payload.entry,
					],
				},
			};
		case SET_ECOCOUNT_SCRAP_TOTAL:
			return {
				...state,
				ecoCountScrapTotal: action.payload,
			};
		case DELETE_ECOCOUNT_SCRAP_ITEM:
			return {
				...state,
				ecoCountScrap: {
					...state.ecoCountScrap,
					[`${action.payload.material}`]: state.ecoCountScrap[action.payload.material].filter(
						(item, index) => item !== action.payload.entry,
					),
				},
			};
		case RESET_ADJUSTMENTS_STATE:
			return {
				...state,
				prevAdjustment: false,
				materials: {
					['PETE']: { sm: 0, md: 0 },
					['ALU']: { sm: 0, md: 0 },
					['GLS']: { sm: 0, md: 0 },
					['BMT']: { sm: 0, md: 0 },
					['HDPE']: { sm: 0, md: 0 },
					['PVC']: { sm: 0, md: 0 },
					['LDPE']: { sm: 0, md: 0 },
					['PP']: { sm: 0, md: 0 },
					['PS']: { sm: 0, md: 0 },
					['BIB']: { sm: 0, md: 0 },
					['MLP']: { sm: 0, md: 0 },
					['PBC']: { sm: 0, md: 0 },
					['OTHER']: { sm: 0, md: 0 },
				},
				scrap: {
					['PETE']: [],
					['ALU']: [],
					['GLS']: [],
					['HDPE']: [],
					['PVC']: [],
					['LDPE']: [],
					['BMT']: [],
					['PP']: [],
					['PS']: [],
					['OTHER']: [],
					['BIB']: [],
					['MLP']: [],
					['PBC']: [],
				},
				ecoCountScrap: {},
				ecoCountScrapTotal: 0,
			};
		default:
			return state;
	}
};

// lib
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//  mui
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	lockIcon: {
		position: 'absolute',
		top: '50%',
		right: '20px',
		zIndex: '600',
	},
	lockClosed: {
		color: theme.palette.error.main,
	},
	lockOpen: {
		color: theme.palette.success.main,
	},
}));

export default function HopperLockIcon() {
	const classes = useStyles();
	const containerType = useSelector((state) => state.process.materialToLoad);
	const step = useSelector((state) => state.steps.activeStep);
	const [showLockIcon, setShowLockIcon] = useState(false);
	const [lockStatus, setLockStatus] = useState('open');

	useEffect(() => {
		const allowed = containerType !== 'other';

		if (allowed && (step === 0 || step === 1)) {
			setShowLockIcon(true);
			setLockStatus('open');
		} else if (step === 2) {
			setLockStatus('closed');
		} else if (step > 2 || !containerType) {
			setShowLockIcon(false);
		}
	}, [containerType, step]);

	return (
		<>
			{showLockIcon && (
				<Box className={classes.lockIcon}>
					{lockStatus === 'closed' && (
						<LockIcon className={classes.lockClosed} fontSize='large' />
					)}
					{lockStatus === 'open' && (
						<LockOpenIcon className={classes.lockOpen} fontSize='large' />
					)}
				</Box>
			)}
		</>
	);
}

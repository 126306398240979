import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '35vw',
		marginTop: '20px',
		marginBottom: '20px',
		'& img': {
			border: `1px solid ${theme.palette.grey.main}`,
			borderRadius: '4px',
			marginBottom: '20px',
			width: 'auto',
		},
	},
}));

export default function DemoSection({ text, src, imgHeight }) {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<img src={src} alt='Demo information photo' style={{ height: imgHeight }} />
			<Typography variant='body1'>{text}</Typography>
		</Box>
	);
}

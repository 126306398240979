import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import CustomNotification from 'components/UIKit/CustomNotification';
import { isDevelopmentEnv } from 'utils/environment';
import { useTranslation } from 'react-i18next';

export default function SnackbarAndModalController() {
	const { t, i18n } = useTranslation();
	const modal = useSelector((state) => state.modal);
	const machine = useSelector((state) => state.process.machine);
	const dailyWeightLimits = useSelector((state) => state.process.dailyWeightLimits);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const employeeToken = useSelector((state) => state.auth.employee.token);
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const kiosk = useSelector((state) => state.admin.kiosk);
	const express = useSelector((state) => state.admin.express);
	const setMaterials = useSelector((state) => state.admin.materialType);
	const dryrun = useSelector((state) => state.process.dryrun);
	const newSignUp = useSelector((state) => state.auth.isNewSignUp);
	const language = useSelector((state) => state.auth.language);
	const machineLocation = useSelector((state) => state.admin.state);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarButton, setSnackbarButton] = useState(false);
	const [showClose, setShowClose] = useState(true);
	const [duration, setDuration] = useState(null);
	const [snackbarTitle, setSnackbarTitle] = useState('');
	const [status, setStatus] = useState('');
	const [open, setOpen] = useState(false);

	// close modal
	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	useEffect(() => {
		if (newSignUp) {
			setSnackbarMessage(t('messages.success.signUp'));
			setDuration(3500);
			setDuration(null); // needed so machine message does not disapear
			setTimeout(() => setMachineMessage(), 3800);
		}
	}, [newSignUp]);

	useEffect(() => {
		// need to delay for intial app load
		setTimeout(() => {
			setMachineMessage();
		}, 1000);
	}, [
		machine.belt_id,
		machine.secret,
		language,
		setMaterials,
		machineLocation,
		express,
		kiosk,
		employeeToken,
	]);

	useEffect(() => {
		setDryrunMessage();
	}, [dryrun]);

	useEffect(() => {
		checkWeightLimits();
	}, [dailyWeightLimits]);

	const closeSnackbar = () => {
		setSnackbarTitle('');
		setDuration(null);
		setSnackbarMessage('');
	};

	const checkWeightLimits = () => {
		const withinTenPercent = dailyWeightLimits.withinTenPercent;
		const overLimit = dailyWeightLimits.overweight;
		const warningOnly = withinTenPercent.length && !overLimit.length;
		const typesNearLimit = withinTenPercent.toString().split(',').join(', ');
		const typesOverLimit = overLimit.toString().split(',').join(', ');
		const nearLimitText = `${t('messages.weightLimits.almostOverweight')} ${
			typesNearLimit || t('messages.weightLimits.none')
		}`;
		const overLimitText = `${t('messages.weightLimits.overweight')} ${
			typesOverLimit || t('messages.weightLimits.none')
		}`;

		if ((taskId || prevTaskId) && (overLimit.length || withinTenPercent.length)) {
			setStatus(warningOnly ? 'warning' : 'error');
			setDuration(90000);
			setShowClose(false);
			setSnackbarTitle(overLimitText);
			setSnackbarMessage(nearLimitText);
		}

		if (!overLimit.length && !withinTenPercent.length) {
			closeSnackbar(); // close the snackbar when the store is cleared aka user logs out
		}
	};

	// machine related messages such as belt id,
	// TODO: refactor this function
	const setMachineMessage = () => {
		const machineSetup = machine.belt_id && machine.secret;
		const fullSignUpPage = window.location.toString().includes('full-sign-up');

		if (fullSignUpPage) return;

		if (!machineSetup && setMaterials.length) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.machineIssue.noBeltId'));
		} else if (!kiosk && !express) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.machineIssue.noModeEnabled'));
		} else if (machineSetup && !setMaterials.length) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.machineIssue.noMaterialType'));
		} else if (!setMaterials.length && !machineSetup) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.machineIssue.noBeltIdNoMaterialType'));
		} else if (!machineLocation) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.warning.noMachineLocation'));
		} else if (!employeeToken) {
			setSnackbarButton(false);
			setShowClose(false);
			setSnackbarMessage(t('messages.warning.noEmployeeLogin'));
		} else {
			setSnackbarMessage('');
		}
	};

	// dryrun related messages
	const setDryrunMessage = () => {
		if (!dryrun && isDevelopmentEnv()) {
			setShowClose(true);
			setSnackbarMessage(t('messages.warning.dryrunOff'));
			setDuration(4000);
		}
	};

	return (
		<>
			<CustomSnackbar
				message={snackbarMessage}
				button={snackbarButton}
				autoHideDuration={duration}
				showCloseButton={showClose}
				status={status}
				title={snackbarTitle}
				close={closeSnackbar}
			/>
			<CustomNotification
				open={open}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
			/>
		</>
	);
}

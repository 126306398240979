import axios from 'axios';
import { store } from 'redux/storage';
import { MODAL_GENERAL_ERROR } from 'redux/actions/types';
import { connectionSpeed } from 'utils/connection';
import i18next from 'i18next';

// if a request takes more than 2 seconds or whatever the timeout is set to, an error message pops up with the estimated connection speed
const connectionTimeout = (error) => {
	if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
		const speedMbps = store.getState().admin.networkSpeed;

		connectionSpeed();

		setTimeout(() => {
			store.dispatch({
				type: MODAL_GENERAL_ERROR,
				payload: {
					message: i18next.t('messages.modal.requestTimedOut'),
					info: `${i18next.t('messages.modal.estimatedConnectionSpeed')} ${speedMbps}`,
					heading: i18next.t('messages.modal.error'),
				},
			});
		}, 300);
	}
};

// put response interceptor logic here
const responseInterceptors = axios.interceptors.response.use(
	(response) => response,
	(error) => {
		connectionTimeout(error);
		return Promise.reject(error);
	},
);

export const interceptors = {
	response: responseInterceptors,
};

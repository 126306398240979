import React from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// components
import VisibilityToggle from 'components/UIKit/VisibilityToggle';
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';

// utils
import { formatIntlUSD } from 'utils/formatCurrency';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '80%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiTypography-h4': {
			marginBottom: '20px',
		},
	},
	balance: {
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		marginTop: '60px',
	},
}));

export default function FinalTotal({ redemptionAmount, language, newWalletBalance, paymentType }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			<Typography variant='h4'>{t('overlay.review.totalRedemption')}</Typography>
			{redemptionAmount >= 0 && (
				<>
					<Typography name='cy-redemption-total' variant='h3'>
						{formatIntlUSD(redemptionAmount, language, 'cents')}
					</Typography>
					{paymentType === 'wallet' && (
						<Box className={classes.balance}>
							<Typography variant='h5'>EcoWallet Balance:</Typography>
							<VisibilityToggle
								content={
									<Typography variant='h5'>
										{formatIntlUSD(newWalletBalance, language, 'cents')}
									</Typography>
								}
							/>
						</Box>
					)}
				</>
			)}
			{!redemptionAmount && redemptionAmount !== 0 && <CustomSpinner />}
		</Box>
	);
}

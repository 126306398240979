// lib
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Odometer from 'react-odometerjs';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// components
import CustomButton from 'components/UIKit/CustomButton';
import { getLedgerEntries } from 'redux/actions/metrics';

// utils
import { formatDateYYYYmmdd } from 'utils/dates';

// stylesheets
import 'odometer/themes/odometer-theme-default.css';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		marginTop: '55px',
		width: '100%',
		height: '200px',
		backgroundColor: theme.palette.grey.dark,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .react-datepicker-wrapper': {
			'& input': {
				backgroundColor: 'inherit',
				border: 'none',
				pointerEvents: 'none',
				fontSize: '20px',
				fontWeight: 'inherit',
				fontFamily: 'inherit',
				caretColor: 'transparent',
			},
			'& input:focus': {
				outline: 'none',
			},
		},
		'& .react-datepicker': {
			transform: 'scale(2)',
			marginTop: '140px',
		},
	},
	buttonGroup: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		'& .MuiButtonBase-root': {
			margin: '10px 60px',
		},
	},
	date: {
		position: 'absolute',
		top: '10px',
		left: '20px',
		'& .react-datepicker-popper': {
			zIndex: '17',
		},
	},
	total: {
		display: 'flex',
		alignItems: 'center',
		'& h4': {
			paddingBottom: '8px',
			minWidth: '150px',
			marginLeft: '40px',
		},
	},
}));

// TODO: format the selectedDate to YYYY-MM-dd to submit to getLedgerEntries
export default function TotalContainersByDate({ back }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.auth.language);
	const [value, setValue] = useState(0);
	const [displayedDate, setDisplayedDate] = useState(new Date());
	const [formattedDate, setFormattedDate] = useState();
	const [showDatePicker, setShowDatePicker] = useState(false);

	useEffect(() => {
		const date = formattedDate ? formattedDate : formatDateYYYYmmdd(new Date());
		date &&
			dispatch(getLedgerEntries(date)).then((res) => {
				const timeoutId = setTimeout(() => setValue(getTotal(res)), 1500);
				return () => {
					clearTimeout(timeoutId);
				};
			});
	}, [formattedDate]);

	useEffect(() => {}, []);

	// gets the ledger entries from the day and adds them up to get a daily
	const getTotal = (ledgerEntries) => {
		let transactions = [];
		let total = 0;

		if (ledgerEntries) {
			ledgerEntries.forEach((entry) => {
				transactions.push(entry.note.replace(/[^0-9]/g, ''));
			});
		}

		if (transactions.length > 0) {
			transactions.forEach((transaction) => {
				total = total + Number(transaction);
			});
		}

		return total;
	};

	const changeDateHandler = () => {
		setShowDatePicker(!showDatePicker);
	};

	const handleFormatDate = (date) => {
		const dateFormatted = formatDateYYYYmmdd(date);

		setDisplayedDate(date);
		setFormattedDate(dateFormatted);
	};

	return (
		<>
			<Box className={classes.main}>
				<Box className={classes.container}>
					<Box className={classes.date}>
						<DatePicker
							popperProps={{ strategy: 'fixed' }}
							selected={displayedDate}
							onChange={(date) => {
								handleFormatDate(date);
								setShowDatePicker(false);
							}}
							open={showDatePicker}
							maxDate={new Date()}
						/>
					</Box>
					<Box className={classes.total}>
						<Typography>{t('admin.totalContainersRecycled')}</Typography>
						<Typography variant='h4'>
							<Odometer value={value} theme='default' />
						</Typography>
					</Box>
				</Box>

				<Box className={classes.buttonGroup}>
					<CustomButton variant='secondary' size='small' onClick={back}>
						{t('common.buttons.back')}
					</CustomButton>
					<CustomButton variant='secondary' size='small' onClick={changeDateHandler}>
						{t('admin.changeDate')}
					</CustomButton>
				</Box>
			</Box>
		</>
	);
}

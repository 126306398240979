import React from 'react';

import cashPaymentOption from 'assets/images/demo/demo-cash-payment-option.png';
import DemoSection from 'components/DemoMode/DemoSection';
import DemoTitle from 'components/DemoMode/DemoTitle';

export default function PaymentSelectDemo({ show }) {
	return (
		<>
			{show && (
				<>
					<DemoTitle title='Payment Select Info' />
					<DemoSection
						src={cashPaymentOption}
						imgHeight={350}
						text='Payment methods can be adjusted to help suit the needs of a redemption center. For example, cash payouts can be made available, as well as configuring the ability to handout and setup EcoCards.'
					/>
				</>
			)}
		</>
	);
}

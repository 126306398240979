import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		textTransform: 'none',
		borderRadius: '8px',
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.primary.main,
		minWidth: '176px',
		minHeight: '72px',
		fontSize: '28px',
		margin: '4px',
		maxWidth: '100%',
		lineHeight: '1',
		padding: '2px 4px',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		'& .MuiTypography-root': {
			color: theme.palette.primary.contrastText,
		},
		[theme.breakpoints.down('lg')]: {
			minWidth: '140px',
			minHeight: '57px',
		},
	},
	primary: {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	secondary: {
		backgroundColor: theme.palette.secondary.main,

		'& .MuiTypography-root': {
			color: theme.palette.secondary.contrastText,
		},
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	alternate: {
		backgroundColor: theme.palette.grey.main,
		'&:hover': {
			backgroundColor: theme.palette.grey.main,
		},
	},
	danger: {
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.main,
		},
	},
	large: {
		height: '118px',
		width: '194px',
	},
	extraLarge: {
		height: '136px',
		width: '238px',
	},
	small: {
		borderRadius: '4px',
		fontSize: '14px',
		minHeight: 'unset',
		height: '48px',
		minWidth: '88px',
		paddingRight: '8px',
		paddingLeft: '8px',
		width: 'max-content',
	},
	extraSmall: {
		minWidth: 'unset',
		minHeight: 'unset',
		height: 'max-content',
		width: 'auto',
		padding: '0px 16px',
	},
	expanded: {
		width: 'auto',
		letterSpacing: 'unset',
		padding: '0px 16px',
	},
	disabled: {
		opacity: '0.4',
		backgroundColor: theme.palette.grey.main,
	},
}));

export default function CustomButton(props) {
	const classes = useStyles();

	const classNames = clsx({
		[classes.root]: true,
		[classes.primary]: props.variant === 'primary',
		[classes.secondary]: props.variant === 'secondary' || props.selected,
		[classes.alternate]: props.variant === 'alternate',
		[classes.danger]: props.variant === 'danger',
		[classes.extraSmall]: props.size === 'extra-small',
		[classes.small]: props.size === 'small',
		[classes.large]: props.size === 'large',
		[classes.extraLarge]: props.size === 'extra-large',
		[classes.expanded]: props.size === 'expanded', // text appears on one line
		[classes.disabled]: props.disabled,
	});

	const getVariant = () => {
		let variant = '';

		if (props.size !== 'small') {
			variant = 'h5';
		}

		return variant;
	};

	return (
		<Button
			className={classNames}
			onClick={props.onClick}
			disabled={props.disabled}
			name={props.name}
			onPointerDown={props.onPointerDown}
			onPointerUp={props.onPointerUp}
			onPointerLeave={props.onPointerLeave}
		>
			<Typography variant={getVariant()}>{props.children}</Typography>
		</Button>
	);
}

import { store } from 'redux/storage';

// Calculations for determining total containers by count or weight


const getEcoCountScrap = () => {
	const unadjustedVal = store.getState().process.data.count_non_redemption;
	const adjustedVal = store.getState().adjustments.ecoCountScrapTotal;

	return adjustedVal ? adjustedVal : unadjustedVal;
};

// oversized containers are included in count_plastic
// count_aluminum = count_alu_lt_24oz + count_alu_geq_24oz
// count_plastic = count_plastic_lt_24oz + count_alu_geq_24oz
// count_glass = count_glass_lt_24oz + count_glass_geq_24oz
export const getTotalContainers = () => {
	const data = store.getState().process.data;

	const total =
		data.count_aluminum +
		data.count_glass +
		data.count_plastic +
		data.count_unknown +
		getEcoCountScrap() +
		data['count_wds-bbp'];

	return total || 0;
};

export const getRedeemableTotalContainers = () => {
	const data = store.getState().process.data;
	const total = data.count_aluminum + data.count_glass + data.count_plastic + data['count_wds-bbp'];

	return total || 'N/A';
};

export const getTotalAdjustments = () => {
	const data = store.getState().adjustments.materials;

	const total =
		data.ALU.md +
		data.ALU.sm +
		data.PETE.md +
		data.PETE.sm +
		data.GLS.sm +
		data.GLS.md +
		data.BMT.sm +
		data.BMT.md +
		data.HDPE.sm +
		data.HDPE.md +
		data.PVC.sm +
		data.PVC.md +
		data.LDPE.sm +
		data.LDPE.md +
		data.PP.sm +
		data.PP.md +
		data.PS.sm +
		data.PS.md +
		data.OTHER.sm +
		data.OTHER.md +
		data.BIB.md +
		data.MLP.md +
		data.PBC.md;

	return total || 0;
};

// EcoCount scrap is scrap that the machine picks up on and classifies as scrap.
// Not a manual scrap entry.
export const getTotalEcoCountScrap = () => {
	const data = store.getState().adjustments.ecoCountScrap;
	let count = 0;

	if (data) {
		Object.keys(data).map((material) => {
			data[material].forEach((entry) => {
				count = count + entry.count;
			});
		});
	}

	return count;
};

// adds the manual adjustments and the Eco Count containers together
// used in the manuallyChangeCounts payload
export const addEcoCountAndAdjustments = () => {
	const adjustments = store.getState().adjustments.materials;
	const ecoCount = store.getState().process.data;

	const totalCounts = {
		ALU: {
			sm: ecoCount.count_aluminum_lt_24oz + adjustments.ALU.sm,
			md: ecoCount.count_aluminum_geq_24oz + adjustments.ALU.md,
		},
		BMT: {
			sm: ecoCount['count_bi-metal_lt_24oz'] + adjustments.BMT.sm,
			md: ecoCount['count_bi-metal_geq_24oz'] + adjustments.BMT.md,
		},
		GLS: {
			sm: ecoCount.count_glass_lt_24oz + adjustments.GLS.sm,
			md: ecoCount.count_glass_geq_24oz + adjustments.GLS.md,
		},
		PETE: {
			sm: ecoCount.count_pete_lt_24oz + adjustments.PETE.sm,
			md: ecoCount.count_pete_geq_24oz + adjustments.PETE.md,
		},
		HDPE: {
			sm: ecoCount.count_hdpe_lt_24oz + adjustments.HDPE.sm,
			md: ecoCount.count_hdpe_geq_24oz + adjustments.HDPE.md,
		},
		PVC: {
			sm: ecoCount.count_pvc_lt_24oz + adjustments.PVC.sm,
			md: ecoCount.count_pvc_geq_24oz + adjustments.PVC.md,
		},
		LDPE: {
			sm: ecoCount.count_ldpe_lt_24oz + adjustments.LDPE.sm,
			md: ecoCount.count_ldpe_geq_24oz + adjustments.LDPE.md,
		},
		PP: {
			sm: ecoCount.count_pp_lt_24oz + adjustments.PP.sm,
			md: ecoCount.count_pp_geq_24oz + adjustments.PP.md,
		},
		PS: {
			sm: ecoCount.count_ps_lt_24oz + adjustments.PS.sm,
			md: ecoCount.count_ps_geq_24oz + adjustments.PS.md,
		},
		BIB: {
			sm: 0,
			md: adjustments.BIB.md,
		},
		MLP: {
			sm: 0,
			md: adjustments.MLP.md,
		},
		PBC: {
			sm: 0,
			md: adjustments.PBC.md,
		},
		OTHER: {
			sm: ecoCount.count_other_lt_24oz + adjustments.OTHER.sm,
			md: ecoCount.count_other_geq_24oz + adjustments.OTHER.md,
		},
	};

	return totalCounts;
};

// calculates the weight entry of a material type minus the tare weight
// eg. 2 lbs of aluminum and the bucket that holds the aluminum weighs 0.5 so net weight is 1.5 lbs
export const calculateNetWeights = (grossWeight, tareWeight) => {
	return new Promise((resolve) => {
		const netWeights = {};

		Object.keys(grossWeight).map((material) => {
			netWeights[material] = Number(grossWeight[material]) - Number(tareWeight[material] ?? 0);
		});

		resolve(netWeights);
	});
};

// calculates the total of all material type weights
export const getTotalWeight = () => {
	const data = store.getState().process.data;

	const total =
		data.weight_aluminum_lbs +
		data['weight_bag-in-box_lbs'] +
		data['weight_bi-metal_lbs'] +
		data.weight_glass_lbs +
		data.weight_hdpe_lbs +
		data.weight_ldpe_lbs +
		data['weight_multilayer-pouch_lbs'] +
		data.weight_other_lbs +
		data['weight_paperboard-carton_lbs'] +
		data.weight_pete_lbs +
		data.weight_pp_lbs +
		data.weight_ps_lbs;

	return total || 'N/A';
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from 'assets/images/logo-gradient-full.png';
import { makeStyles } from '@material-ui/core/styles';
import { SET_USER_FLOW_LOCATION } from 'redux/actions/types';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	logo: {
		height: '84px',
		margin: '26px',
		[theme.breakpoints.down('lg')]: {
			height: '52px',
			margin: '14px',
		},
	},
}));

export default function RecycletekLogo() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [count, setCount] = useState(0);
	const adminPage = window.location.toString().includes('admin');

	// clicking the logo a few times will redirect to admin page
	const handleCount = () => {
		if (!adminPage) {
			setCount((prevState) => prevState + 1);
			if (count === 4) {
				dispatch({
					type: SET_USER_FLOW_LOCATION,
					payload: window.location.pathname,
				});
				history.push('/admin');
				setCount(0);
			}
		} else if (adminPage) {
			history.push('/admin/password');
		}
	};

	return (
		<Box onClick={handleCount}>
			<img className={classes.logo} src={logo} alt='Recycletek logo' />
		</Box>
	);
}

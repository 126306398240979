import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// components
import CustomButton from 'components/UIKit/CustomButton';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.background.default,
		borderTop: `1px solid ${theme.palette.grey.main}`,
		position: 'absolute',
		bottom: '0px',
		padding: '10px 0px 20px 0px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		zIndex: '300',
		'& button': {
			margin: '0px 20px',
		},
	},
}));

export default function FinalTotalFooter({ digitalReceipt, machineUrl, printReceipt, remotePrinter }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			<CustomButton name='cy-express-digital-receipt' size='extra-large' onClick={digitalReceipt}>
				<Typography variant='body6'>
					<Trans>{t('express.digitalReceipt')}</Trans>
				</Typography>
			</CustomButton>

			<CustomButton
				size='extra-large'
				disabled={machineUrl.includes('virtual') && !remotePrinter}
				onClick={printReceipt}
			>
				<Typography variant='body6'>
					<Trans>{t('express.digitalAndPaperReceipt')}</Trans>
				</Typography>
			</CustomButton>
		</Box>
	);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Stepper, Step, StepLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
	},
	stepper: {
		backgroundColor: 'inherit',
		marginTop: '16px',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-12px',
			transform: 'scale(0.8)',
		},
	},
	step: {
		'& $completed': {
			color: '#004F71',
		},
		'& $active': {
			color: '#97D700',
		},
	},
	active: {},
	completed: {},
}));

function CustomStepper() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const { activeStep } = useSelector((state) => state.steps);
	const paymentSelectPage = window.location.href.includes('/payment-select');

	const steps = [
		t('common.stepper.placeItems'),
		t('common.stepper.startEcoVision'),
		t('common.stepper.processItems'),
		t('common.stepper.payout'),
	];

	return (
		<Box className={classes.container}>
			<Stepper
				activeStep={paymentSelectPage ? 0 : activeStep}
				className={classes.stepper}
				alternativeLabel
			>
				{steps.map((label) => (
					<Step
						classes={{
							root: classes.step,
							completed: classes.completed,
							alternativeLabel: classes.active,
						}}
						key={label}
					>
						<StepLabel
							classes={{
								alternativeLabel: classes.alternativeLabel,
								labelContainer: classes.labelContainer,
							}}
							StepIconProps={{
								classes: {
									root: classes.step,
									completed: classes.completed,
									active: classes.active,
									disabled: classes.disabled,
								},
							}}
						>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}

export default CustomStepper;

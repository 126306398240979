import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import CustomButton from 'components/UIKit/CustomButton';
import AdminInput from 'components/Admin/AdminInput';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.background.default,
		borderTop: `1px solid ${theme.palette.grey.main}`,
		position: 'absolute',
		bottom: '0px',
		padding: '10px 0px 20px 0px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		zIndex: '300',
		'& button': {
			margin: '0px 20px',
		},
	},
	continue: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '-20px',
		'& .MuiIconButton-root': {
			marginRight: '0px',
		},
		'& button': {
			marginLeft: '0px',
		},
	},
}));

export default function TotalsFooter({ handleCloseOverlay, setPassword, password, verifyEmployee }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const handleVerifyEmployee = () => {
		setIsButtonDisabled(true);
		verifyEmployee();
		setTimeout(() => {
			setIsButtonDisabled(false);
		}, 2500);
	};

	return (
		<Box className={classes.container}>
			<CustomButton variant='alternate' onClick={handleCloseOverlay}>
				{t('common.buttons.back')}
			</CustomButton>
			<Box className={classes.continue}>
				<AdminInput
					label={t('admin.employeePassword')}
					name='cy-express-password'
					variant='secondary'
					value={password}
					isPassword={true}
					handleChange={(e) => setPassword(e.target.value)}
				/>
				<CustomButton disabled={!password || isButtonDisabled} onClick={handleVerifyEmployee}>
					{t('common.buttons.continue')}
				</CustomButton>
			</Box>
		</Box>
	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import MaterialLabel from 'components/UIKit/MaterialLabel';

const useStyles = makeStyles((theme) => ({
	main: {
		margin: '20px 10px',
	},
	container: {
		position: 'relative',
		width: '1000px',
		height: '300px',
		backgroundColor: theme.palette.background.default,
		border: `1px solid ${theme.palette.grey.main}`,
		borderRadius: '10px',
		[theme.breakpoints.down('lg')]: {
			width: '760px',
		},
	},
}));

export default function MaterialPanel(props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.main}>
			<MaterialLabel name={props.name} fullName={props.fullName} />
			<Box className={classes.container}>{props.children}</Box>
		</Box>
	);
}

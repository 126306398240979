import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	bgBlur: {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.80)',
		backdropFilter: 'blur(3px)',
	},
	content: {
		position: 'fixed',
		minWidth: '65%',
		minHeight: '70%',
		maxHeight: '100%',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		backgroundColor: theme.palette.background.default,
		borderRadius: '18px',
		border: '1px solid #000',
		overflow: 'hidden',
	},
	title: {
		borderBottom: '1px solid #000',
		padding: '4px 4px 4px 14px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		'& .MuiTypography-root': {
			fontWeight: '700',
			letterSpacing: '1px',
		},
	},
}));

export default function Overlay(props) {
	const classes = useStyles();

	return (
		<>
			{props.open && (
				<Box className={classes.bgBlur} style={{ zIndex: props.zIndex }}>
					<Box className={classes.content} style={{ zIndex: props.zIndex + 1 }}>
						<Typography className={classes.title} variant='h4'>
							{props.title}
						</Typography>
						<Box>{props.children}</Box>
					</Box>
				</Box>
			)}
		</>
	);
}

// lib
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import TotalContainersByDate from 'components/MachineStats/TotalContainersByDate';
import TransactionsByDate from 'components/MachineStats/TransactionsByDate';
import CustomButton from 'components/UIKit/CustomButton';
import PageHeader from 'components/Admin/PageHeader';
import Section from 'components/Admin/Section';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	button: {
		marginTop: '10px',
		marginRight: 'auto',
		marginLeft: 'auto',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default function MachineStats() {
	const classes = useStyles();
	const { t } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const [view, setView] = useState('');
	const [statTitle, setStatTitle] = useState('');

	const stats = [
		{
			title: 'admin.totalContainersByDate',
			type: 'daily-total',
			buttonType: 'view',
		},
		{
			title: 'admin.transactionHistoryByDate',
			type: 'daily-transaction',
			buttonType: 'view',
		},
	];

	const handleViewClicked = (stat) => {
		setView(stat.type);
		setStatTitle(stat.title);
	};

	const back = () => {
		setView('');
		setStatTitle('');
	};

	return (
		<>
			<PageHeader title={!statTitle ? t('admin.machineStats') : t(statTitle)} />
			<>
				{!view && (
					<Box className={classes.container}>
						{stats.map((stat) => (
							<Section title={t(stat.title)}>
								{stat.buttonType === 'view' && (
									<Box className={classes.button}>
										<CustomButton
											variant='secondary'
											size='small'
											onClick={() => handleViewClicked(stat)}
										>
											{t('admin.view')}
										</CustomButton>
									</Box>
								)}
							</Section>
						))}
					</Box>
				)}
				{view === 'daily-total' && <TotalContainersByDate back={back} />}
				{view === 'daily-transaction' && <TransactionsByDate back={back} />}
			</>
		</>
	);
}

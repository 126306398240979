import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import CustomNotification from 'components/UIKit/CustomNotification';
import { MODAL_GENERAL_ERROR } from 'redux/actions/types';

// displays notifcation when app goes offline
export default function NoConnection() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [online, setOnline] = useState(true);
	const [open, setOpen] = useState(false);
	const modal = useSelector((state) => state.modal);

	useEffect(() => {
		setOnline(navigator.onLine);
	}, []);

	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	window.addEventListener('online', () => {
		setOnline(true);
	});

	window.addEventListener('offline', () => {
		setOnline(false);
		dispatch({
			type: MODAL_GENERAL_ERROR,
			payload: {
				message: 'No internet connection. Please alert an operator.', // translations do not work offline
				heading: 'Error',
			},
		});
	});

	return (
		<CustomNotification
			open={open && !online}
			id={modal.id}
			heading={modal.heading}
			message={modal.message}
		/>
	);
}

import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
	// TODO: add colour palette
	typography: {
		fontFamily: 'HelveticaNeue, Helvetica, Arial, sans-serif',
		h2: {
			fontSize: '64px',
			fontWeight: 600,
			lineHeight: 1,
			color: '#004F71',
		},
		h3: {
			fontSize: '52px',
			fontWeight: 600,
			lineHeight: 1,
			color: '#004F71',
		},
		h4: {
			fontSize: '44px',
			fontWeight: 600,
			color: '#004F71',
		},
		h5: {
			fontSize: '30px',
			fontWeight: 600,
			color: '#004F71',
		},
		h6: {
			fontSize: '24px',
			fontWeight: 600,
			textTransform: 'inherit',
			color: '#004F71',
		},
		subtitle1: {
			fontSize: '24px',
			fontWeight: 300,
			lineHeight: 1.4,
		},
		body1: {
			fontSize: '20px',
			fontWeight: 300,
			lineHeight: 1.4,
		},
		body2: {
			fontSize: '18px',
			fontWeight: 300,
			lineHeight: 1.4,
		},
		body1_large: {
			fontSize: '22px',
			fontWeight: 300,
			lineHeight: 1.4,
		},
		caption: {
			fontSize: '13px',
			fontWeight: 400,
			lineHeight: 1.2,
		},
	},
	palette: {
		primary: {
			main: '#78D64B', // bright green
			dark: '#5EBA7D', // green
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#008EAA', // light blue
			dark: '#004F71', // dark blue
			contrastText: '#FFFFFF',
		},
		tertiary: {
			main: '#97D700', // bright green
		},
		grey: {
			main: '#BFC4C8',
			dark: '#D9D9D9',
		},
		background: {
			default: '#FFFFFF',
		},
		text: {
			primary: '#000000',
			secondary: '#FFFFFF',
		},
		error: {
			main: '#EB4545',
		},
		success: {
			main: '#5eba7d',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1450,
			xl: 1536,
		},
	},
});

export default theme;

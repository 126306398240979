import { store } from 'redux/storage';

// returns true/false if user has entered scrap material
export const isScrapEntered = () => {
	const scrap = store.getState().adjustments.scrap;
	let result = false;

	Object.keys(scrap).forEach((material) => {
		if (material.length > 0) {
			result = true;
		}
	});
	return result;
};

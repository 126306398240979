import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.text.secondary,
		width: 'max-content',
		maxWidth: '500px',
		padding: '4px 6px',
		borderRadius: '4px',
		margin: '18px auto',
		animation: '$fadeIn 700ms',
		textAlign: 'center',
	},
	['@keyframes fadeIn']: {
		'0%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
}));

export default function CustomErrorBadge({ errorMessage }) {
	const classes = useStyles();

	return (
		<>
			{errorMessage && (
				<Box className={classes.container}>
					<Typography variant='body2'>{errorMessage}</Typography>
				</Box>
			)}
		</>
	);
}

// lib
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/UIKit/CustomButton';
import Box from '@material-ui/core/Box';

// components
import ExpressSettingsMenu from 'pages/Express/ExpressSettingsMenu';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '240px',
		'& button': {
			marginBottom: '18px',
		},
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'row-reverse',
			width: '100%',
			height: '100%',
			justifyContent: 'center',
			paddingRight: '80px',
			'& button': {
				marginBottom: '0px',
				marginRight: '15px',
				marginLeft: '15px',
			},
		},
	},
	alt: {
		display: 'flex',
		alignItems: 'center',
		height: '100px',
		flexDirection: 'row-reverse',
		justifyContent: 'center',
		'& button': {
			marginRight: '15px',
			marginLeft: '15px',
		},
	},
}));

export default function ExpressControls({
	showReview,
	selectMaterialToRun,
	adjustCounts,
	isBeltRunning,
	taskEnded,
	stop,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const readyToStop = useSelector((state) => state.process.ready_to_stop);
	const data = useSelector((state) => state.admin);

	return (
		<Box className={!adjustCounts ? classes.container : classes.alt}>
			{!taskEnded && !adjustCounts && (
				<>
					<CustomButton
						variant='primary'
						disabled={isBeltRunning || data.url.includes('virtual')}
						onClick={selectMaterialToRun}
					>
						{t('express.run')}
					</CustomButton>
					<CustomButton variant='secondary' disabled={!readyToStop} onClick={stop}>
						{t('express.stop')}
					</CustomButton>
				</>
			)}
			{!taskEnded && (
				<>
					<CustomButton variant='secondary' disabled={isBeltRunning} onClick={showReview}>
						{t('express.end')}
					</CustomButton>
				</>
			)}
			{!adjustCounts && <ExpressSettingsMenu taskEnded={taskEnded} />}
		</Box>
	);
}

// lib
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import TransactionsByDateTable from 'components/Tables/TransactionsByDateTable';
import CustomButton from 'components/UIKit/CustomButton';
import { getLedgerEntries } from 'redux/actions/metrics';

// utils
import { formatDateYYYYmmdd } from 'utils/dates';

const useStyles = makeStyles((theme) => ({
	table: {
		margin: '8% 11%',
	},
	date: {
		marginBottom: '10px',
		'& .react-datepicker-wrapper': {
			'& input': {
				backgroundColor: 'inherit',
				border: 'none',
				pointerEvents: 'none',
				fontSize: '20px',
				fontWeight: 'inherit',
				fontFamily: 'inherit',
				caretColor: 'transparent',
			},
			'& input:focus': {
				outline: 'none',
			},
		},
		'& .react-datepicker': {
			transform: 'scale(2)',
			marginTop: '140px',
		},
	},
	buttonGroup: {
		marginTop: '20px',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		'& .MuiButtonBase-root': {
			margin: '10px 60px',
		},
	},
}));

// TODO: format the selectedDate to YYYY-MM-dd to submit to getLedgerEntries
export default function TransactionsByDate({ back }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [displayedDate, setDisplayedDate] = useState(new Date());
	const [formattedDate, setFormattedDate] = useState();
	const [transactions, setTransactions] = useState('');

	useEffect(() => {
		const date = formattedDate ? formattedDate : formatDateYYYYmmdd(new Date());
		date &&
			dispatch(getLedgerEntries(date)).then((res) => {
				setTransactions(res);
			});
	}, [formattedDate]);

	const changeDateHandler = () => {
		setShowDatePicker(!showDatePicker);
	};

	const handleFormatDate = (date) => {
		const dateFormatted = formatDateYYYYmmdd(date);

		setDisplayedDate(date);
		setFormattedDate(dateFormatted);
	};

	return (
		<>
			<Box className={classes.table}>
				<Box className={classes.date}>
					<DatePicker
						popperProps={{ strategy: 'fixed' }}
						selected={displayedDate}
						onChange={(date) => {
							handleFormatDate(date);
							setShowDatePicker(false);
						}}
						open={showDatePicker}
						maxDate={new Date()}
					/>
				</Box>
				<TransactionsByDateTable data={transactions} />
				<Box className={classes.buttonGroup}>
					<CustomButton variant='secondary' size='small' onClick={back}>
						{t('common.buttons.back')}
					</CustomButton>
					<CustomButton variant='secondary' size='small' onClick={changeDateHandler}>
						{t('admin.changeDate')}
					</CustomButton>
				</Box>
			</Box>
		</>
	);
}

import { store } from 'redux/storage';
import { translation } from 'utils/translation.js';

// header for api calls
export const configHeader = (customTimeout) => {
	const token = store.getState().auth.access_token;
	const config = {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		timeout: customTimeout || 4000,
	};

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
};

export const employeeConfigHeader = (customTimeout) => {
	const token = store.getState().auth.employee.token;
	const config = {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		timeout: customTimeout || 4000,
	};

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
};

// TODO: need to deprecate, using i18n now
// converting english text to spanish text
export const t = (text, code) => {
	const language = store.getState().auth.language;
	let translatedText = text || 'error retrieving text';

	if (language === 'en') {
		return translatedText;
	} else if (language === 'es') {
		Object.keys(translation).map((key) => {
			if (key === code) {
				translatedText = translation[key].spa;
				return;
			}
		});
	}

	return translatedText;
};

import * as Sentry from '@sentry/react';
import { store } from 'redux/storage';
import { SET_DRYRUN, SET_IS_EMAIL_RECEIPT_ON } from 'redux/actions/types';

// sets dryrun status and email confirmation receipts based on environment
// dryrun is set to true if localhost
// transaction email receipt is off if localhost
export const configEnvironmentSettings = () => {
	const dryrun = store.getState().process.dryrun;

	if (dryrun === 'unset') {
		store.dispatch({
			type: SET_DRYRUN,
			payload: isDevelopmentEnv(),
		});
		store.dispatch({
			type: SET_IS_EMAIL_RECEIPT_ON,
			payload: !isDevelopmentEnv(),
		});
	}
};

// for making adjustments based on prod/dev
export const isDevelopmentEnv = () => {
	return process.env.NODE_ENV === 'development';
};

export const initSentry = () => {
	Sentry.init({
		dsn: 'https://e94c037a3d0f4066527a5f4f04cce19b@o4507392042991616.ingest.us.sentry.io/4507392060817408',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};

import React, { useEffect, useState } from 'react';
import { confirmInactivity } from 'redux/actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { isDevelopmentEnv } from 'utils/environment';
import { useTranslation } from 'react-i18next';

export default function InactivityTimer() {
	const { t } = useTranslation();
	const dev = isDevelopmentEnv();
	const dispatch = useDispatch();
	const containerType = useSelector((state) => state.process.materialToLoad);
	const [isIdle, setIsIdle] = useState(false);

	// location vars
	const placeItemsPage = window.location.toString().includes('/begin-process');
	const paymentPage = window.location.toString().includes('/payment-select');
	const checkoutPage = window.location.toString().includes('/checkout');
	const donatePage = window.location.toString().includes('/donate');

	// timer vars
	const needsOperator = containerType === 'other';
	const devTimeout = 300000; // 5 min
	const prodTimeout = 180000; // 3 min
	const timeout = dev ? devTimeout : prodTimeout;
	const [remaining, setRemaining] = useState(timeout);

	const handleOnActive = () => setIsIdle(false);
	const handleOnIdle = () => setIsIdle(true);
	const { getRemainingTime } = useIdleTimer({
		timeout,
		onActive: handleOnActive,
		onIdle: handleOnIdle,
	});

	useEffect(() => {
		setRemaining(getRemainingTime());

		setInterval(() => {
			setRemaining(getRemainingTime());
		}, 1000);
	}, []);

	useEffect(() => {
		if (isIdle && (paymentPage || placeItemsPage || donatePage || checkoutPage)) {
			dispatch(confirmInactivity(t('messages.modal.appearsInactive')));
		}
	}, [isIdle]);

	return null;
}

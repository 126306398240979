// lib
import React from 'react';
import { Route } from 'react-router-dom';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// components
import NavBar from 'components/Admin/NavBar';
import AdminPassword from 'pages/Admin/AdminPassword';
import DevelopmentInfo from 'pages/Admin/DevelopmentInfo';
import ProtectedRoutes from 'components/Admin/ProtectedRoutes';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		backgroundColor: '#EEEEEE',
	},
	main: {
		width: '100%',
	},
}));

export default function Admin() {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<NavBar />
			<Box className={classes.main}>
				<ProtectedRoutes />
				<Route path={'/admin/developers'} component={DevelopmentInfo} />
				<Route path={'/admin/password'} component={AdminPassword} />
			</Box>
		</Box>
	);
}

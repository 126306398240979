import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/UIKit/CustomButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({}));

export default function CheckoutCompleteButton({ finalizedBalance, completeTransaction, disabled }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<>
			{finalizedBalance && (
				<CustomButton disabled={disabled} onClick={completeTransaction} size='expanded'>
					{t('common.buttons.completeTransaction')}
				</CustomButton>
			)}
		</>
	);
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		width: '100%',
		textAlign: 'center',
		bottom: '0',
	},
}));

export default function CheckoutFooter({ finalizedBalance }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<>
			{finalizedBalance && (
				<Box className={classes.container}>
					<Typography variant='body2' className={classes.text}>
						{t('checkout.incorrectTotals')}
					</Typography>
				</Box>
			)}
		</>
	);
}

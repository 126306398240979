import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import MaterialTypeNumber from 'components/UIKit/MaterialTypeNumber';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function MaterialLabel({ name, fullName }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			<Typography variant='h5'>{fullName ? fullName : name}</Typography>
			<MaterialTypeNumber type={name} />
		</Box>
	);
}

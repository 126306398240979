import { SERVER_URL } from 'redux/actions/config.js';
import { store } from 'redux/storage';

// when printing remotely eg. using an iPad and need to print receipt
export const bluetoothPrint = async (taskId) => {
	const dryrun = store.getState().process.dryrun;

	if (!dryrun) {
		let url =
			'starpassprnt://v1/print/nopreview?&url=' +
			SERVER_URL +
			'/task/get_receipt_pdf/' +
			taskId +
			'&back=googlechrome://';
		window.location.href = url; // note: just calling with axios does not work, it's a iOS-system-shortcut-url...
	}
};

const disableRightClick = () => {
	window.addEventListener(
		'contextmenu',
		(e) => {
			e.preventDefault();
		},
		false,
	);
};

export default disableRightClick;

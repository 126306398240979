import { store } from 'redux/storage';
import { SET_NETWORK_SPEED } from 'redux/actions/types';

// downloads an image and measures the connection speed.
export const connectionSpeed = () => {
	let imageAddr =
		'https://upload.wikimedia.org/wikipedia/commons/8/85/Aerial_view_of_St._John%27s%2C_Newfoundland_at_sunset_by_photographer_Erik_Mclean_of_Introspective_Design.jpg';
	let downloadSize = 2900000; //bytes
	let startTime, endTime;
	let download = new Image();
	download.onload = function () {
		endTime = new Date().getTime();
		showResults();
	};

	download.onerror = function (err) {
		console.error('Speed test error:', err);
	};

	startTime = new Date().getTime();
	let cacheBuster = '?nnn=' + startTime;
	download.src = imageAddr + cacheBuster;

	const showResults = () => {
		let duration = (endTime - startTime) / 1000;
		let bitsLoaded = downloadSize * 8;
		let speedBps = (bitsLoaded / duration).toFixed(2);
		let speedKbps = (speedBps / 1024).toFixed(2);
		let speedMbps = (speedKbps / 1024).toFixed(2);

		store.dispatch({
			type: SET_NETWORK_SPEED,
			payload: `${speedMbps} Mbps`,
		});
	};
};

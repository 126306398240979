// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation, Trans } from 'react-i18next';

//mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// redux
import { setPaymentType } from 'redux/actions/user';
import { login } from 'redux/actions/auth.js';

// components
import GuestAndSignUpButtons from 'components/GuestAndSignUpButtons';
import CustomNotification from 'components/UIKit/CustomNotification';
import PortalTextField from 'components/UIKit/PortalTextField';
import RecycletekLogo from 'components/Background/RecycletekLogo';
import CustomButton from 'components/UIKit/CustomButton';

// utils and lib
import { isDevelopmentEnv } from 'utils/environment';
import { useKeyDown } from 'utils/useKeyDown';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '101vh',
		overflow: 'hidden',
	},
	image: {
		backgroundImage: 'url(/medBlueLogo.svg)',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#004F71',
		backgroundSize: '158% 162%',
		maxHeight: '100vh',
	},
	paper: {
		margin: theme.spacing(15, 5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: 'max-content',
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	submit: {
		background: '#97D700',
		color: '#004F71',
		margin: '30px',
		height: '137px',
		width: '165px',
		borderRadius: '13px',
		'&:hover,&:focus': {
			color: 'white',
			background: '#008EAA',
		},
		boxShadow: '4px 4px 20px #000000D6',
		overflow: 'hidden',
	},
	loginButton: {
		background: '#97D700',
		color: '#004F71',
		margin: '38px 0px',
		fontSize: '30px',
		height: '73px',
		width: '176px',
		borderRadius: '10px',
		textTransform: 'none',
		'&:hover,&:focus': {
			color: 'white',
			background: '#008EAA',
		},
		boxShadow: '0px 3px 6px #00000074',
		marginRight: '10px',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	loginSection: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '10%',
	},
	heading: {
		color: 'white',
		margin: '32px',
		'& .MuiTypography-root': {
			margin: '0px 0px 10px 10px',
			color: '#FFFFFF',
		},
	},
	input: {
		marginBottom: '38px',
		minWidth: '280px',
		'& .MuiInputBase-root': {
			width: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: '370px',
		},
	},
}));

function SignIn() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const uniqueId = uuid().slice(0, 8);
	const auth = useSelector((state) => state.auth);
	const kiosk = useSelector((state) => state.admin.kiosk);
	const modal = useSelector((state) => state.modal);
	const express = useSelector((state) => state.admin.express);
	const machine = useSelector((state) => state.process.machine);
	const payment = useSelector((state) => state.auth.paymentData);
	const [email, setEmail] = useState(isDevelopmentEnv() ? 'weis.tobi@googlemail.com' : '');
	const [barcode, setBarcode] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	useKeyDown(() => {
		email && loginUser();
		barcode && expressLogin();
	}, ['Enter']);

	const closeMessage = () => {
		setOpen(false);
	};

	const textFieldClick = () => {
		// TODO: onclick field might be necessary for the keyboard,
		// see if this empty function can be removed
		// if (isKeyboardHidden) Keyboard.open();
	};

	const expressLogin = () => {
		const machineSetup = machine.belt_id && machine.secret;

		if (machineSetup && barcode) {
			setIsButtonDisabled(true);
			dispatch(login(barcode))
				.then(() => {
					history.push('/express');
					setIsButtonDisabled(false);
				})
				.catch(() => {
					setIsButtonDisabled(false);
				});
		}
	};

	const loginUser = () => {
		if (email) {
			setIsButtonDisabled(true);
			dispatch(login(email))
				.then(() => setIsButtonDisabled(false))
				.catch(() => setIsButtonDisabled(false));
		}
	};

	// when backspace is held down, or clearInput prop is triggered
	const clearInput = () => {
		setEmail('');
	};

	if (auth.isAuthenticated) {
		const eligibleFreeBags = !auth.customer.received_free_bags && !auth.isGuest;
		const route = eligibleFreeBags ? '/promotion' : '/payment-select';

		return <Redirect to={route} />;
	}

	return (
		<Grid container component='main' className={classes.root}>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				heading={modal.heading}
				message={modal.message}
			/>
			<Grid item xs={6} sm={7} md={7} className={classes.logo}>
				<RecycletekLogo />
				<Box className={classes.main}>
					{kiosk && (
						<Box className={classes.loginSection}>
							<Typography variant='h4'>{t('signIn.memberLogin')}</Typography>
							<form className={classes.form}>
								<PortalTextField
									className={classes.input}
									id={uniqueId}
									labelText='Email'
									placeholder={t('signIn.email')}
									name='email'
									fullWidth
									type='email'
									clearInput={clearInput}
									onChange={(e) => setEmail(e.target.value)}
									onClick={textFieldClick}
									defaultValue={email}
									value={email}
								/>
								<div>
									<CustomButton disabled={isButtonDisabled} onClick={loginUser}>
										{t('signIn.login')}
									</CustomButton>
								</div>
							</form>
						</Box>
					)}
					{express && (
						<Box className={classes.loginSection}>
							<Typography variant='h4'>{t('signIn.expressMode')}</Typography>
							<form className={classes.form}>
								<PortalTextField
									className={classes.input}
									id={uniqueId}
									labelText='Barcode'
									placeholder={t('signIn.barcode')}
									name='barcode'
									fullWidth
									clearInput={clearInput}
									onChange={(e) => setBarcode(e.target.value)}
									onClick={textFieldClick}
									defaultValue={barcode}
									value={barcode}
								/>
								<Box>
									<CustomButton
										name='data-cy-express-login'
										disabled={isButtonDisabled}
										onClick={expressLogin}
									>
										{t('signIn.login')}
									</CustomButton>
								</Box>
							</form>
						</Box>
					)}
				</Box>
			</Grid>
			<Grid container item xs={6} sm={5} md={5} lg={5} xl={5} className={classes.image}>
				<Box className={classes.heading}>
					<Typography variant='h2'>{t('messages.main.welcome')}</Typography>
					<Trans>
						<Typography variant='subtitle1'>{t('messages.main.makingAChange')}</Typography>
					</Trans>
				</Box>
				<GuestAndSignUpButtons />
			</Grid>
		</Grid>
	);
}
export default SignIn;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

// components
import CustomButton from 'components/UIKit/CustomButton';
import MaterialTypeNumber from 'components/UIKit/MaterialTypeNumber';

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '10px 0px',
		maxHeight: 300,
	},
	table: {
		minWidth: 650,
	},
	material: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function CountEntriesTable({ grossWeights, tareWeights, editWeight }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [weights, setWeights] = useState([]);

	useEffect(() => {
		getWeights();
	}, []);

	const getWeights = () => {
		const grossAndTareWeights = {};

		Object.entries(grossWeights).map((entry) => {
			grossAndTareWeights[entry[0]] = { gross: Number(entry[1]), tare: 0 };
		});

		Object.entries(tareWeights).map((entry) => {
			grossAndTareWeights[entry[0]].tare = Number(entry[1]);
		});

		setWeights(grossAndTareWeights);
	};

	return (
		<TableContainer className={classes.container} component={Paper}>
			<Table className={classes.table} stickyHeader aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell>{t('overlay.review.material')}</TableCell>
						<TableCell align='right'>{t('overlay.review.gross')}</TableCell>
						<TableCell align='right'>{t('overlay.review.tare')}</TableCell>
						<TableCell align='right'>{t('overlay.review.net')}</TableCell>
						<TableCell align='right'>{t('overlay.review.editEntry')}</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{Object.keys(weights).map((material) => (
						<TableRow>
							<TableCell component='th' scope='row'>
								<Box className={classes.material}>
									{material}
									<MaterialTypeNumber type={material} />
								</Box>
							</TableCell>
							<TableCell align='right'>{weights[material].gross}</TableCell>
							<TableCell align='right'>{weights[material].tare}</TableCell>
							<TableCell align='right'>
								{(weights[material].gross - weights[material].tare).toFixed(1)}
							</TableCell>

							<TableCell align='right'>
								<CustomButton
									size='extra-small'
									variant='secondary'
									name={`cy-edit-weight-${material}`}
									onClick={() => editWeight(material)}
								>
									<EditIcon />
								</CustomButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

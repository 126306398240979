// lib
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import { makeStyles } from '@material-ui/core/styles';

// components
import PrintReceiptErrMsg from 'pages/Process/PrintReceiptErrMsg';
import { printReceipt } from 'redux/actions/process';
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';
import { NEXT_STEP } from 'redux/actions/types';
import CustomButton from 'components/UIKit/CustomButton';
import CustomBox from 'components/UIKit/CustomBox';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: '9%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	text: {
		color: theme.palette.secondary.dark,
		textAlign: 'center',
		fontSize: '22px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '300',
		marginTop: '18px',
	},
}));

export default function PrintReceipt() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dryrun = useSelector((state) => state.process.dryrun);
	const throwError = useSelector((state) => state.process.throwError); // mock an error, used for testing purposes
	const step = useSelector((state) => state.steps.activeStep);
	const [printingInProgress, setPrintingInProgress] = useState(true);

	useEffect(() => {
		handlePrintReceipt();
	}, []);

	const print = () => {
		dispatch(printReceipt())
			.then(() => {
				setTimeout(() => {
					history.push('/thank-you');
					dispatch({ type: NEXT_STEP, payload: step });
				}, 5000); // syncs with the time it takes for the receipt to actually print
			})
			.catch(() => {
				setPrintingInProgress(false);
			});
	};

	const handlePrintReceipt = () => {
		setPrintingInProgress(true);

		if (!dryrun || throwError) {
			print();
		} else {
			setTimeout(() => {
				history.push('/thank-you');
				dispatch({ type: NEXT_STEP, payload: step });
			}, 4000);
		}
	};

	return (
		<CustomBox
			title={
				!printingInProgress
					? t('printReceipt.printReceiptError')
					: t('printReceipt.printingReceipt')
			}
		>
			<Box className={classes.container}>
				{printingInProgress && (
					<>
						<CustomSpinner />
						<Typography className={classes.text} variant='h6'>
							{t('printReceipt.logoutAfterReceipt')}
						</Typography>
					</>
				)}
				{!printingInProgress && (
					<>
						<PrintReceiptErrMsg />
						<CustomButton variant='primary' onClick={handlePrintReceipt}>
							{t('common.buttons.retry')}
						</CustomButton>
					</>
				)}
			</Box>
		</CustomBox>
	);
}

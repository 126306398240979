// lib
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// components
import { NEXT_STEP, SET_READY_TO_STOP } from 'redux/actions/types';
import { taskStarted, resumeTask } from 'redux/actions/process';
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';
import { Redirect } from 'react-router-dom';
import CustomButton from 'components/UIKit/CustomButton';
import CautionText from 'pages/Process/CautionText';
import recycleIcon from 'assets/images/recycletek-recycle-icon.png';
import CustomBox from 'components/UIKit/CustomBox';
import causion from 'assets/images/causion.png';

const useStyles = makeStyles((theme) => ({
	header: {
		color: '#004F71',
		textAlign: 'center',
		fontSize: '42px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '300',
		marginTop: '27px',
	},
	submit: {
		background: '#008EAA',
		color: 'white',
		height: '67px',
		width: '181px',
		borderRadius: '13px',
		margin: '18px',
		fontSize: '30px',
		textTransform: 'capitalize',
	},
	cautionImg: {
		height: '26px',
		width: 'auto',
	},
	warning: {
		marginTop: '60px',
		fontSize: '18px',
		fontFamily: 'HelveticaNeue',
		fontWeight: '500',
		color: theme.palette.text.primary,
		maxWidth: '800px',
		lineHeight: '1.6',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: '120px',
		'& img': {
			height: '220px',
			width: 'auto',
		},
	},
	footer: {
		position: 'absolute',
		bottom: '0px',
		margin: '0px 14px 14px 14px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	loadingText: {
		animation: '$fadeIn 3000ms',
	},
	ecoVisionText: {
		fontWeight: '200',
	},
	['@keyframes fadeIn']: {
		'0%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
}));

function EcoVision() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const taskId = useSelector((state) => state.process.task_id);
	const step = useSelector((state) => state.steps.activeStep);
	const language = useSelector((state) => state.auth.language);
	const [isDisabled, setIsDisabled] = useState(false);
	const [started, setStarted] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const startProcess = () => {
		dispatch({ type: SET_READY_TO_STOP, payload: false }); // clear any old values in case of slow internet
		setIsDisabled(true);
		setLoading(true);
		dispatch({ type: NEXT_STEP, payload: step });

		dispatch(taskId ? resumeTask() : taskStarted()) // resumeTask if the machine was paused and loading more items
			.then(() => {
				setStarted(true);
				setIsDisabled(false);
				setLoading(false);
			})
			.catch(() => {
				setIsDisabled(false);
				setLoading(false);
			});
	};

	if (taskId && started) {
		return <Redirect to='/start-process' />;
	}
	return (
		<CustomBox
			title={
				<>
					{!loading && (
						<span>
							{t('ecoVision.itemsDetected')}{' '}
							<span className={classes.ecoVisionText}>Eco</span>Vision.
						</span>
					)}
					{loading && (
						<span className={classes.loadingText}>
							{t('ecoVision.starting')} <span className={classes.ecoVisionText}>Eco</span>
							Vision
						</span>
					)}
				</>
			}
			position='relative'
			center={true}
		>
			<Box className={classes.container}>
				{!loading && (
					<Box className={classes.main}>
						<img src={recycleIcon} alt='recycling symbol' />
						<CustomButton disabled={isDisabled} onClick={startProcess}>
							{t('common.buttons.start')}
						</CustomButton>
					</Box>
				)}
				{loading && <CustomSpinner />}
				<Box className={classes.footer}>
					<img className={classes.cautionImg} src={causion} alt='caution sign' />
					<CautionText textVariant='body2' />
				</Box>
			</Box>
		</CustomBox>
	);
}

export default EcoVision;

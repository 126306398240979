import { NEXT_STEP, PREVIOUS_STEP, RESET_STEPS } from '../actions/types';

const initialState = {
  activeStep: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEXT_STEP:
      return {
        ...state,
        activeStep: action.payload + 1,
      };
    case PREVIOUS_STEP:
      return {
        ...state,
        activeStep: action.payload - 1,
      };
    case RESET_STEPS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

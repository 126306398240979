// lib
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// assets/components
import recycletekLogo from 'assets/images/gradientLogo.png';
import ecoWalletIcon from 'assets/images/recycletek-wallet-icon.png';
import cashDonation from 'assets/images/recycletek-donate-cash.png';
import dollarBill from 'assets/images/recycletek-cash-bill.png';

const useStyles = makeStyles((theme) => ({
	container: {
		color: '#FFF',
		borderRadius: '10px',
		fontSize: '16px',
		overflow: 'none',
		backgroundColor: theme.palette.primary.dark,
		height: '270px',
		width: '610px',
		animation: '$fadeIn 1200ms',
	},
	['@keyframes fadeIn']: {
		'0%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		height: '100px',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		margin: '2px 10px',
	},
	iconWallet: {
		height: '28px',
		marginLeft: '4px',
	},
	dollarIcon: {
		height: '20px',
		marginLeft: '7px',
	},
	iconLogo: {
		height: '18px',
		marginLeft: '6px',
		color: '#FFF',
		filter: 'contrast(0%) brightness(195%)',
	},
	topLeft: {
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
		color: '#004F71',
		width: '484px',
		height: 'max-content',
		maxHeight: '134px',
		marginLeft: '-24px',
		marginTop: '-24px',
		backgroundColor: '#FFF',
		overflow: 'hidden',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
	},
	amountEarned: {
		height: '100px',
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '22px',
		paddingRight: '4px',
	},
	primaryValue: {
		fontWeight: 'bold',
		fontSize: '52px',
		marginTop: 'auto',
		marginBottom: 'auto',
		width: '100%',
		textAlign: 'center',
	},
	secondaryValue: {
		fontWeight: 'bold',
		fontSize: '38px',
	},
	topRight: {
		width: '100%',
		textAlign: 'center',
		paddingTop: '10px',
		fontWeight: '500',
		margin: '0px auto',
		'& $title': {
			justifyContent: 'center',
		},
	},
	footer: {
		marginTop: '36px',
		fontWeight: '500',
	},
	footerContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		padding: '16px 16px 6px 16px',
		lineHeight: '1.3',
	},
	listItem: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
}));

export default function RedemptionTotals({ options, type }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const language = useSelector((state) => state.auth.language);

	return (
		<Box className={classes.container}>
			<Box className={classes.header}>
				<Box className={classes.topLeft}>
					{type === 'wallet' && (
						<Box className={classes.title}>
							<Typography variant='body1'>
								Eco<b>Wallet</b> {t('checkout.balance')}
							</Typography>
							<img
								className={classes.iconWallet}
								src={ecoWalletIcon}
								alt='ecowallet icon'
							/>
						</Box>
					)}
					{type === 'cash' ||
						(type === 'card' && (
							<Box className={classes.title}>
								<Typography variant='body1'>{t('checkout.cashEarned')}</Typography>
								<img
									className={classes.dollarIcon}
									src={dollarBill}
									alt='dollar bill icon'
								/>
							</Box>
						))}
					{type === 'donate' && (
						<Box className={classes.title}>
							<Typography variant='body1'>{t('checkout.donationAmount')}</Typography>
							<img
								className={classes.dollarIcon}
								src={cashDonation}
								alt='cash donation icon'
							/>
						</Box>
					)}
					<Typography>
						<Box className={classes.amountEarned}>
							<Box className={classes.primaryValue}>
								{type === 'wallet' && options.mainValue.ecowallet}
								{type === 'cash' && options.mainValue.cash}
								{type === 'donate' && options.mainValue.donation}
								{type === 'card' && options.mainValue.card}
							</Box>
						</Box>
					</Typography>
				</Box>
				<Box className={classes.topRight}>
					<Box className={classes.title}>
						<Typography variant='body1'>{t('checkout.containersRecycledToday')}</Typography>
						<img
							className={classes.iconLogo}
							src={recycletekLogo}
							alt='small Recycletek Logo'
						/>
					</Box>
					<Typography>
						<Box className={classes.primaryValue} name='data-cy-total-containers'>
							{options.containersRecycled}
						</Box>
					</Typography>
				</Box>
			</Box>
			<Box className={classes.footer}>
				<Divider variant='middle' />
				<Box className={classes.footerContent}>
					{type === 'wallet' && (
						<>
							{options.ecowallet.map((item) => (
								<>
									<Box className={classes.listItem}>
										<Typography variant='body2'>
											<Box>{item.title}</Box>
											<Box
												className={classes.secondaryValue}
												name='data-cy-deposit-today'
											>
												{item.value}
											</Box>
										</Typography>
									</Box>
									<Box>
										{item !== options.ecowallet[options.ecowallet.length - 1] && (
											<Divider orientation='vertical' />
										)}
									</Box>
								</>
							))}
						</>
					)}

					{type === 'cash' && (
						<Box className={classes.listItem}>
							<Typography variant='body2'>
								<Box>{options.cash}</Box>
							</Typography>
						</Box>
					)}

					{type === 'donate' && (
						<Box className={classes.listItem}>
							<Typography variant='body2'>
								<Box>{options.donation}</Box>
							</Typography>
						</Box>
					)}

					{type === 'card' && (
						<Box className={classes.listItem}>
							<Typography variant='body2'>
								<Box>{options.card}</Box>
							</Typography>
						</Box>
					)}

					{options.totalWeight > 0 && (
						<>
							<Box>
								<Divider orientation='vertical' />
							</Box>
							<Box className={classes.listItem}>
								<Typography variant='body2'>
									<Box>{t('checkout.totalNetWeight')}</Box>
									<Box>{`${options.totalWeight} lbs`}</Box>
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
}

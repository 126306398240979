import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	logoutButton: {
		textTransform: 'unset',
		cursor: 'pointer',
	},
	icon: {
		backgroundColor: '#97D700',
		borderRadius: '33px',
		padding: '4px',
		marginRight: '8px',
		fontSize: '28px',
	},
}));

export default function LogoutButton({ logout }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Box>
			<Button className={classes.logoutButton} onClick={logout}>
				<ArrowBackIcon className={classes.icon} />
				<Typography variant='body1'>{t('common.buttons.logout')}</Typography>
			</Button>
		</Box>
	);
}

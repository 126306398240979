import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutEmployee } from 'redux/actions/auth';
import CustomButton from 'components/UIKit/CustomButton';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		bottom: '20px',
		left: '20px',
	},
}));

export default function AdminLogout() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const employee = useSelector((state) => state.auth.employee);
	const [message, setMessage] = useState({ text: '', status: '' });
	const loginPage = window.location.toString().includes('/password');

	const logout = () => {
		dispatch(logoutEmployee());
		setMessage({ text: 'Logout Successful', status: 'success' });
	};

	const closeSnackbar = () => {
		setMessage({ message: '', status: '' });
	};

	return (
		<>
			<CustomSnackbar message={message.text} status={message.status} close={closeSnackbar} />
			<Box className={classes.container}>
				{(employee.token || employee.username) && loginPage && (
					<CustomButton variant='secondary' size='small' onClick={logout}>
						{t('admin.employeeLogout')}
					</CustomButton>
				)}
			</Box>
		</>
	);
}

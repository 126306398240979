// lib
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// mui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Typography } from '@material-ui/core';

// components
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';

const useStyles = makeStyles({
	container: {
		maxHeight: '440px',
	},
	table: {
		minWidth: 650,
	},
	noData: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
});

export default function TransactionsByDateTable({ data }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<>{!data && <CustomSpinner />}</>
			{data.length > 0 && (
				<TableContainer className={classes.container} component={Paper}>
					<Table className={classes.table} stickyHeader aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>{t('admin.time')}</TableCell>
								<TableCell align='right'>{t('admin.count')}</TableCell>
								<TableCell align='right'>{t('admin.transactionType')}</TableCell>
								<TableCell align='right'>{t('admin.value')}&nbsp;($)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className={classes.tableBody}>
							{data.map((transaction) => (
								<TableRow key={transaction.datetime}>
									<TableCell component='th' scope='row'>
										{transaction.datetime.split(' ')[1].split('.')[0]}
									</TableCell>
									<TableCell align='right'>
										{transaction.note.replace(/[^0-9]/g, '')}
									</TableCell>
									<TableCell align='right'>
										{transaction.transaction_type.replace('_', ' ')}
									</TableCell>
									<TableCell align='right'>
										{transaction.value_dollars.toFixed(2)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{!data.length && (
				<Box className={classes.noData}>
					<Typography variant='h6'>{t('admin.noTransactionsToDisplay')}</Typography>
				</Box>
			)}
		</>
	);
}

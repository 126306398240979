import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: '20px',
	},
}));

export default function DemoTitle({ title }) {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<Typography variant='h6'>{title}</Typography>
			<Divider />
		</Box>
	);
}

import React from 'react';

import overOneHundredCA from 'assets/images/demo/demo-express-ca-over-100.png';
import DemoSection from 'components/DemoMode/DemoSection';
import DemoTitle from 'components/DemoMode/DemoTitle';

export default function ExpressDemo({ show }) {
	return (
		<>
			{show && (
				<>
					<DemoTitle title='Express Mode Info' />
					<DemoSection
						src={overOneHundredCA}
						imgHeight={280}
						text='If the user is in California and the transaction is over $100, they will be asked to provide ID and an e-signature. If they do not have an ID, they need to provide an explanation.'
					/>
				</>
			)}
		</>
	);
}

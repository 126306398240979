import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { formatIntlUSD } from 'utils/formatCurrency.js';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		margin: '2px 60px 20px 60px',
	},
	text: {
		margin: '6px',
	},
}));

export default function PrintReceiptErrMsg() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const redemptionAmount = useSelector(
		(state) => state.auth.wallet?.total_transaction_value_microdollars,
	);
	const paymentType = useSelector((state) => state.auth.paymentType);

	return (
		<Box className={classes.container}>
			<Box className={classes.text}>
				<Typography variant='body2'>{t('printReceipt.cannotPrintInfo')}</Typography>
			</Box>
			<Box className={classes.text}>
				<Typography variant='body1'>
					{t('printReceipt.paymentType')} {paymentType ? paymentType : 'N/A'}
				</Typography>
			</Box>
			<Box className={classes.text}>
				{redemptionAmount && (
					<Typography variant='body1'>
						{t('printReceipt.redemptionAmount')} {formatIntlUSD(redemptionAmount, language)}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

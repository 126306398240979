import {
	MODAL_RESET,
	MODAL_LOGIN_ERROR,
	MODAL_CASH_CONFIRM,
	MODAL_ECO_CONFIRM,
	MODAL_CHARITY_CONFIRM,
	MODAL_PRINT_RECEIPT,
	MODAL_INACTIVITY,
	MODAL_EMERGENCY_STOP,
	MODAL_EMERGENCY_STOP_CONFIRM,
	MODAL_EMERGENCY_STOP_FAILED,
	MODAL_ALERT_OPERATOR,
	SET_CONTAINER_TYPE,
	MODAL_CONFIRM_NO_ADJUSTMENT,
} from 'redux/actions/types';
import { t } from 'i18next';

export const clearModal = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_RESET,
	});
};

export const signUpError = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_LOGIN_ERROR,
		payload: message,
	});
};

export const confirmEcoWallet = (message, heading) => (dispatch, getState) => {
	dispatch({
		type: MODAL_ECO_CONFIRM,
		payload: {
			message,
			heading,
		},
	});
};

export const confirmCash = (message, heading) => (dispatch, getState) => {
	dispatch({
		type: MODAL_CASH_CONFIRM,
		payload: {
			message,
			heading,
		},
	});
};

export const confirmCharity = (message, heading) => (dispatch, getState) => {
	dispatch({
		type: MODAL_CHARITY_CONFIRM,
		payload: {
			message,
			heading,
		},
	});
};

export const confirmReceipt = (message, heading) => (dispatch, getState) => {
	dispatch({
		type: MODAL_PRINT_RECEIPT,
		payload: {
			message,
			heading,
		},
	});
};

export const emergencyStopModal = (message) => (dispatch) => {
	dispatch({
		type: MODAL_EMERGENCY_STOP,
		payload: message,
	});
};

export const confirmEmergencyStopModal = (message) => (dispatch) => {
	dispatch({
		type: MODAL_EMERGENCY_STOP_CONFIRM,
		payload: message,
	});
};

export const emergencyStopFailedModal = (message) => (dispatch) => {
	dispatch({
		type: MODAL_EMERGENCY_STOP_FAILED,
		paylod: message,
	});
};

export const confirmInactivity = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_INACTIVITY,
		payload: {
			message,
			heading: t('messages.modal.pleaseConfirm'),
			info: '',
		},
	});
};

export const alertOperator = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_ALERT_OPERATOR,
		payload: {
			message,
			heading: t('messages.modal.pleaseConfirm'),
		},
	});
	dispatch({
		type: SET_CONTAINER_TYPE,
		payload: '',
	});
};

export const continueWithoutAdjustment = (message) => (dispatch, getState) => {
	dispatch({
		type: MODAL_CONFIRM_NO_ADJUSTMENT,
		payload: {
			message,
			heading: t('messages.modal.pleaseConfirm'),
		},
	});
};

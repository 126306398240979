// lib
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// components
import CustomNotification from 'components/UIKit/CustomNotification';
import FreeEcoSacks from 'pages/Promotion/FreeEcoSacks.js';
import Background from 'components/Background/Background';
import CustomBox from 'components/UIKit/CustomBox';

export default function Checkout() {
	const { t, i18n } = useTranslation();
	const modal = useSelector((state) => state.modal);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	// closes custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	return (
		<Background>
			<Grid md={12}>
				<CustomNotification
					open={open}
					id={modal.id}
					heading={modal.heading}
					closeMessage={closeMessage}
					message={modal.message}
				/>
				<CustomBox position='relative' title={t('promotion.recycletekPromotion')}>
					<FreeEcoSacks />
				</CustomBox>
			</Grid>
		</Background>
	);
}

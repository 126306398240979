import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/UIKit/CustomButton';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '10px 16%', // hack, signUp needs to be refactored to align content properly
	},
	checklistItem: {
		display: 'flex',
	},
	text: {
		marginLeft: '6px',
		fontSize: '16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
			textAlign: 'left',
		},
	},
	success: {
		color: theme.palette.tertiary.main,
	},
	button: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '36px',
	},
}));

export default function PasswordValidation({
	input,
	confirmPasswordInput,
	handleSubmit,
	doNotMatch,
	includeSubmit,
}) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [lengthCheck, setLengthCheck] = useState(false);
	const [upperCheck, setUpperCheck] = useState(false);
	const [lowerCheck, setLowerCheck] = useState(false);
	const [numberCheck, setNumberCheck] = useState(false);
	const [specialCheck, setSpecialCheck] = useState(false);
	const [matchCheck, setMatchCheck] = useState(false);

	useEffect(() => {
		isPasswordValid();
	}, [input, confirmPasswordInput]);

	const isPasswordValid = () => {
		const length = new RegExp(/^.{8,}$/); // has at least 8 characters
		const upper = new RegExp(/(?=.*[A-Z])/);
		const lower = new RegExp(/(?=.*[a-z])/);
		const number = new RegExp(/(?=.*[0-9])/);
		const special = new RegExp(/(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])/);
		const match = input === confirmPasswordInput;
		const notEmpty = input && confirmPasswordInput !== '';

		if (length.test(input)) {
			setLengthCheck(true);
		} else {
			setLengthCheck(false);
		}

		if (upper.test(input)) {
			setUpperCheck(true);
		} else {
			setUpperCheck(false);
		}

		if (lower.test(input)) {
			setLowerCheck(true);
		} else {
			setLowerCheck(false);
		}

		if (number.test(input)) {
			setNumberCheck(true);
		} else {
			setNumberCheck(false);
		}

		if (special.test(input)) {
			setSpecialCheck(true);
		} else {
			setSpecialCheck(false);
		}

		if ((match && notEmpty) || doNotMatch) {
			setMatchCheck(true);
		} else {
			setMatchCheck(false);
		}
	};

	return (
		<Box className={classes.container}>
			<Box className={`${classes.checklistItem} ${lengthCheck && classes.success}`}>
				{lengthCheck && <CheckCircleIcon fontSize='small' />}
				{!lengthCheck && <CancelIcon fontSize='small' />}
				<Box className={classes.text}>{t('signUp.validation.characterCount')}</Box>
			</Box>
			<Box className={`${classes.checklistItem} ${upperCheck && classes.success}`}>
				{upperCheck && <CheckCircleIcon fontSize='small' />}
				{!upperCheck && <CancelIcon fontSize='small' />}
				<Box className={classes.text}>{t('signUp.validation.uppercase')}</Box>
			</Box>
			<Box className={`${classes.checklistItem} ${lowerCheck && classes.success}`}>
				{lowerCheck && <CheckCircleIcon fontSize='small' />}
				{!lowerCheck && <CancelIcon fontSize='small' />}
				<Box className={classes.text}>{t('signUp.validation.lowercase')}</Box>
			</Box>
			<Box className={`${classes.checklistItem} ${numberCheck && classes.success}`}>
				{numberCheck && <CheckCircleIcon fontSize='small' />}
				{!numberCheck && <CancelIcon fontSize='small' />}
				<Box className={classes.text}>{t('signUp.validation.hasNumber')}</Box>
			</Box>
			<Box className={`${classes.checklistItem} ${specialCheck && classes.success}`}>
				{specialCheck && <CheckCircleIcon fontSize='small' />}
				{!specialCheck && <CancelIcon fontSize='small' />}
				<Box className={classes.text}>{t('signUp.validation.specialCharacter')}</Box>
			</Box>
			{!doNotMatch && (
				<Box className={`${classes.checklistItem} ${matchCheck && classes.success}`}>
					{matchCheck && <CheckCircleIcon fontSize='small' />}
					{!matchCheck && <CancelIcon fontSize='small' />}
					<Box className={classes.text}>{t('signUp.validation.match')}</Box>
				</Box>
			)}
			{includeSubmit && (
				<Box className={classes.button}>
					<CustomButton onClick={handleSubmit}>{t('signUp.submit')}</CustomButton>
				</Box>
			)}
		</Box>
	);
}

import {
	MODAL_LOGIN_ERROR,
	MODAL_LOGOUT,
	MODAL_RESET,
	MODAL_ECO_CONFIRM,
	MODAL_CASH_CONFIRM,
	MODAL_CHARITY_CONFIRM,
	MODAL_PRINT_RECEIPT,
	MODAL_INACTIVITY,
	MODAL_EMERGENCY_STOP,
	MODAL_EMERGENCY_STOP_CONFIRM,
	MODAL_EMERGENCY_STOP_FAILED,
	MODAL_START_ERROR,
	MODAL_NO_ITEMS_DETECTED,
	MODAL_ALERT_OPERATOR,
	MODAL_PRINT_RECEIPT_ERROR,
	MODAL_CONFIRM_NO_ADJUSTMENT,
	MODAL_GENERAL_ERROR,
	MODAL_ECOCARD_SETUP_SUCCESS,
	MODAL_SELECT_MATERIAL_TO_RUN,
	MODAL_OTHER_MATERIALS_SELECTED,
} from '../actions/types.js';

const initialState = {
	id: 0,
	message: '',
	info: '',
	heading: '',
	type: '',
};

export const modal = (state = initialState, action) => {
	switch (action.type) {
		case MODAL_LOGIN_ERROR:
			return {
				...state,
				id: 1,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'error',
			};
		case MODAL_LOGOUT:
			return {
				...state,
				id: 2,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_ECO_CONFIRM:
			return {
				...state,
				id: 3,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_CASH_CONFIRM:
			return {
				...state,
				id: 4,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_CHARITY_CONFIRM:
			return {
				...state,
				id: 5,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_PRINT_RECEIPT:
			return {
				...state,
				id: 6,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_INACTIVITY:
			return {
				...state,
				id: 7,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		case MODAL_EMERGENCY_STOP:
			return {
				...state,
				id: 8,
				message: action.payload.message,
				heading: 'Emergency Stop Activated',
				type: 'error', // TODO: maybe set a 'warning' type when it is built
			};
		case MODAL_EMERGENCY_STOP_CONFIRM:
			return {
				...state,
				id: 9,
				message: action.payload.message,
				heading: 'error',
			};
		case MODAL_EMERGENCY_STOP_FAILED:
			return {
				...state,
				id: 10,
				message: action.payload.message,
				heading: 'Error',
				type: 'error',
			};
		case MODAL_START_ERROR:
			return {
				...state,
				id: 12,
				message: action.payload.message,
				info: action.payload.info,
				heading: action.payload.heading,
				type: 'error',
			};
		case MODAL_NO_ITEMS_DETECTED:
			return {
				...state,
				id: 13,
				message: action.payload.message,
				info: action.payload.info,
				heading: action.payload.heading,
				type: 'error',
			};
		case MODAL_PRINT_RECEIPT_ERROR:
			return {
				...state,
				id: 14,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'error',
			};
		case MODAL_ALERT_OPERATOR:
			return {
				...state,
				id: 15,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};

		case MODAL_CONFIRM_NO_ADJUSTMENT:
			return {
				...state,
				id: 16,
				message: action.payload.message,
				heading: action.payload.heading,
				type: 'ok',
			};
		// TODO: change the name MODAL_GENERAL (can be success, error, or warning)
		case MODAL_GENERAL_ERROR:
			return {
				...state,
				id: 17,
				message: action.payload.message,
				info: action.payload.info,
				heading: action.payload.heading,
				type: 'error',
			};
		case MODAL_ECOCARD_SETUP_SUCCESS:
			return {
				...state,
				id: 18,
				message: action.payload.message,
				heading: action.payload.heading,
			};
		case MODAL_SELECT_MATERIAL_TO_RUN:
			return {
				...state,
				id: 19,
				message: action.payload.message,
				heading: action.payload.heading,
			};
		case MODAL_OTHER_MATERIALS_SELECTED:
			return {
				...state,
				id: 20,
				message: action.payload.message,
				heading: action.payload.heading,
			};
		case MODAL_RESET:
			return {
				...state,
				id: 0,
				message: '',
				info: '',
				heading: '',
				type: '',
			};
		default:
			return state;
	}
};

import React from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// components
import CustomButton from 'components/UIKit/CustomButton';
import ScrapEntriesTable from 'components/Tables/ScrapEntriesTable';
import CountEntriesTable from 'components/Tables/CountEntriesTable';
import WeightEntriesTable from 'components/Tables/WeightEntriesTable';
import ScrapEcoCountTable from 'components/Tables/ScrapEcoCountTable';

const useStyles = makeStyles((theme) => ({
	main: {
		padding: '10px 20px',
		paddingBottom: '100px',
		height: '85vh',
		'& hr': {
			margin: '20px 0px',
		},
	},
	section: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px',
		flexDirection: 'column',
		minHeight: '70px',
		margin: '20px 10px',
		borderRadius: '4px',
		border: `1px solid ${theme.palette.grey.main}`,
	},
	row: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	total: {
		minWidth: '320px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

export default function Totals({
	count,
	weight,
	isScrap,
	editCount,
	editScrap,
	editWeight,
	tareWeights,
	deleteWeight,
	grossWeights,
	scrapEcoCount,
	showCountTable,
	showWeightTable,
	editEcoCountScrap,
	handleShowCountTable,
	handleShowWeightTable,
	getScrapEcoCountTotal,
	showScrapEcoCountTable,
	handleShowScrapEcoCountTable,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box>
			<Box className={classes.section}>
				<Box className={classes.row}>
					<Box className={classes.total}>
						<Typography variant='body2'>
							{t('overlay.review.ecoCountAndAdjustments')}
						</Typography>
						<Typography name='cy-count-total' variant='h6'>
							{count}
						</Typography>
					</Box>
					{count !== 0 && (
						<CustomButton
							size='extra-small'
							variant='secondary'
							onClick={handleShowCountTable}
						>
							{showCountTable ? t('overlay.review.hide') : t('overlay.review.view')}
						</CustomButton>
					)}
				</Box>
				{showCountTable && count !== 0 && <CountEntriesTable editCount={editCount} />}
			</Box>
			<Box className={classes.section}>
				<Box className={classes.row}>
					<Box className={classes.total}>
						<Typography variant='body2'>{t('overlay.review.weightRedemptions')}</Typography>
						<Typography name='cy-weight-total' variant='h6'>
							{weight.toFixed(1)}
						</Typography>
					</Box>
					{weight !== 0 && (
						<CustomButton
							size='extra-small'
							variant='secondary'
							onClick={handleShowWeightTable}
						>
							{showWeightTable ? t('overlay.review.hide') : t('overlay.review.view')}
						</CustomButton>
					)}
				</Box>
				{showWeightTable && weight !== 0 && (
					<WeightEntriesTable
						grossWeights={grossWeights}
						tareWeights={tareWeights}
						deleteWeight={deleteWeight}
						editWeight={editWeight}
					/>
				)}
			</Box>
			{/* SCRAP FROM ECOCOUNT SECTION */}
			<Box className={classes.section}>
				<Box className={classes.row}>
					<Box className={classes.total}>
						<Typography variant='body2'>{t('overlay.review.scrapFromEcoCount')}</Typography>
						<Typography name='cy-count-total' variant='h6'>
							{scrapEcoCount}
						</Typography>
					</Box>
					{scrapEcoCount > 0 && (
						<CustomButton
							size='extra-small'
							variant='secondary'
							onClick={handleShowScrapEcoCountTable}
						>
							{showScrapEcoCountTable ? t('overlay.review.hide') : t('overlay.review.view')}
						</CustomButton>
					)}
				</Box>
				{showScrapEcoCountTable && scrapEcoCount > 0 && (
					<ScrapEcoCountTable
						editScrap={editEcoCountScrap}
						calculateTotal={getScrapEcoCountTotal}
					/>
				)}
			</Box>
			{/* SCRAP ENTRIES SECTION */}
			<Box className={classes.section}>
				{isScrap && (
					<Box className={classes.scrap}>
						<Typography variant='body2'>{t('overlay.review.scrapEntries')}</Typography>
						<ScrapEntriesTable editScrap={editScrap} />
					</Box>
				)}
				{!isScrap && (
					<Box className={classes.row}>
						<Box className={classes.total}>
							<Typography variant='body2'>{t('overlay.review.scrapEntries')}</Typography>
							<Typography variant='body2'>{t('overlay.review.none')}</Typography>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
}

import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import UserInfoQuestionnaireCA from 'components/CACompliance/UserInfoQuestionnaireCA';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '20px',
		zIndex: '700',
	},
}));

export default function OverOneHundredUserInfoCA(props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			<Typography>
				<strong>{t('overlay.sourceOfId')}</strong>
			</Typography>
			<br />
			<Typography variant='body2'>{t('overlay.californiaOverLimit')}</Typography>
			<Box className={classes.main}>
				<UserInfoQuestionnaireCA
					close={props.close}
					cancel={props.cancel}
					resetRedemptionValue={props.resetRedemptionValue}
				/>
			</Box>
		</Box>
	);
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// components
import AdminCounterInput from 'components/Admin/AdminCounterInput';
import CustomButton from 'components/UIKit/CustomButton';
import { ADD_ECOCOUNT_SCRAP_ITEM } from 'redux/actions/types';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	sizeAndCount: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	button: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default function EditEcoCountScrapInputs({ materialType, doneEdit, prevEntry }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [count, setCount] = useState(prevEntry?.count);

	const handleCountChange = (event) => {
		setCount(Number(event.target.value.replace(/\D/g, '')));
	};

	const increaseCount = (adjCount) => {
		setCount((prevState) => prevState + adjCount);
	};

	const decreaseCount = (adjCount) => {
		if (count > 0) setCount((prevState) => prevState - adjCount);
	};

	const handleSubmit = () => {
		if (count >= 0) {
			doneEdit();
			dispatch({
				type: ADD_ECOCOUNT_SCRAP_ITEM,
				payload: {
					material: materialType,
					entry: { category: prevEntry.category, count: count, size_oz: prevEntry.size_oz },
				},
			});
		}
	};

	return (
		<>
			<Box className={classes.container}>
				<Box className={classes.sizeAndCount}>
					<AdminCounterInput
						name='edit-ecocount-scrap-count'
						label={t('overlay.review.enterCount')}
						value={count}
						handleChange={handleCountChange}
						increaseCount={increaseCount}
						decreaseCount={decreaseCount}
					/>
				</Box>
			</Box>
			<Box className={classes.button}>
				<CustomButton onClick={handleSubmit}>{t('common.buttons.continue')}</CustomButton>
			</Box>
		</>
	);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomButton from 'components/UIKit/CustomButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		margin: '28px 28px 4px 28px',
		border: '1px solid rgba(0,0,0,0.3)',
		height: '400px',
		width: '250px',
		textAlign: 'center',
		borderRadius: '8px',
		backgroundColor: theme.palette.primary.dark,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	icon: {
		paddingTop: '20px',
		'& img': {
			height: '94px',
		},
	},
	title: {
		paddingTop: '20px',
		'& .MuiTypography-root': {
			color: '#FFFFFF',
		},
	},
	description: {
		padding: '10px',
		'& .MuiTypography-root': {
			color: '#FFFFFF',
		},
	},
	button: {
		position: 'absolute',
		borderRadius: '0px 0px 7px 7px',
		borderTop: '1px solid #000',
		backgroundColor: '#FFFFFF',
		bottom: '0px',
		paddingBottom: '20px',
		paddingTop: '20px',
		width: '100%',
		maxHeight: '123px',
	},
}));

export default function PaymentTypeCard({
	title,
	name,
	hidden,
	icon,
	description,
	handleSelect,
	cyData,
	isGuest,
	setupCard,
}) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const customer = useSelector((state) => state.auth.customer);
	const admin = useSelector((state) => state.admin);

	const SetupCard = ({ isDisabled }) => {
		return (
			<CustomButton onClick={setupCard} name={cyData} disabled={isDisabled}>
				{t('common.buttons.setupCard')}
			</CustomButton>
		);
	};

	const SelectCard = ({ isDisabled }) => {
		return (
			<CustomButton onClick={handleSelect} name={cyData} disabled={isDisabled}>
				{t('common.buttons.select')}
			</CustomButton>
		);
	};

	return (
		<>
			{!hidden && (
				<Box className={classes.container}>
					<Box className={classes.icon}>
						<img src={icon} alt='payment type icon' />
					</Box>
					<Box className={classes.title}>
						<Typography variant='h5'>{title}</Typography>
					</Box>
					<Box className={classes.description}>
						<Typography variant='body1'>{description}</Typography>
					</Box>
					<Box className={classes.button}>
						{name !== 'card' && (
							<CustomButton
								onClick={handleSelect}
								name={cyData}
								disabled={isGuest && name === 'wallet'}
							>
								{t('common.buttons.select')}
							</CustomButton>
						)}
						{name === 'card' && (
							<>
								{admin.forceEcoCardSetup ? (
									<SetupCard isDisabled={false} />
								) : (
									<>
										{(isGuest || customer.isNewSignUp) && (
											<SelectCard isDisabled={true} />
										)}
										{!isGuest && !customer.isNewSignUp && (
											<>
												{customer.onbe_account_number ? (
													<SelectCard isDisabled={false} />
												) : (
													<>
														{admin.ecoCardSetupEnabled && (
															<SetupCard isDisabled={false} />
														)}
														{!admin.ecoCardSetupEnabled && (
															<SetupCard isDisabled={true} />
														)}
													</>
												)}
											</>
										)}
									</>
								)}
							</>
						)}
					</Box>
				</Box>
			)}
		</>
	);
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setPaymentType, getCharities } from 'redux/actions/user';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import { useTranslation } from 'react-i18next';

export default function CheckPaymentPreference() {
	const dispatch = useDispatch();
	const history = useHistory();
	const paymentType = useSelector((state) => state.auth.paymentType);
	const paymentId = useSelector((state) => state.auth.paymentId);
	const paymentOptionId = useSelector((state) => state.auth.paymentOptionId);
	const paymentData = useSelector((state) => state.auth.paymentData);
	const { t } = useTranslation();
	const customer = useSelector((state) => state.auth.customer);
	const expressCashEnabled = useSelector((state) => state.admin.expressCashEnabled);
	const [snackbarMessage, setSnackbarMessage] = useState({ text: '', status: '' });
	const [preference, setPreference] = useState(false);

	useEffect(() => {
		if (history.location.state?.ecoCardSetup) {
			// if EcoCard was just setup, set payment method to card
			setEcoCardDefault();
		} else if (!preference) {
			setPreferredPaymentData();
		}
	}, [paymentType, paymentId, paymentOptionId]);

	const setEcoCardDefault = () => {
		const message = t('messages.success.paymentMethod', { payment: 'EcoCard' });

		dispatch(
			setPaymentType(
				paymentData.keep?.methods.card.name,
				paymentData.keep?.methods.card.id,
				paymentData.keep?.id,
			),
		);

		setSnackbarMessage({ text: message, status: 'success' });
	};

	const handleSetPaymentType = (preferredPayment, charityName) => {
		const defaultPayment = {
			name: paymentData.keep?.methods.wallet.name,
			option: paymentData.keep?.methods.wallet.id,
			id: paymentData.keep?.id,
		};

		const displayMessage = (type) => {
			const paymentName = type !== 'default' ? preferredPayment?.name : defaultPayment.name;
			const message =
				type === 'donate'
					? t('messages.success.paymentMethodDonate', { charity: charityName })
					: t('messages.success.paymentMethod', { payment: paymentName });

			setSnackbarMessage({ text: message, status: 'success' });
		};

		const setDefaultPayment = () => {
			dispatch(setPaymentType(defaultPayment.name, defaultPayment.option, defaultPayment.id));
			displayMessage('default');
		};

		const setPreferredPayment = (type) => {
			dispatch(setPaymentType(preferredPayment.name, preferredPayment.option, preferredPayment.id));
			displayMessage(type);
		};

		if (preferredPayment.name === 'wallet') {
			setPreferredPayment();
		} else if (preferredPayment.name === 'card') {
			customer.onbe_account_number ? setPreferredPayment() : setDefaultPayment();
		} else if (preferredPayment.name === 'cash') {
			expressCashEnabled ? setPreferredPayment() : setDefaultPayment();
		} else if (preferredPayment.name === 'donate') {
			setPreferredPayment('donate');
		}
	};

	const setPreferredPaymentData = () => {
		const keep = customer.preferred_payment_option === 1;
		const keepId = customer.preferred_cashout_option;
		const donate = customer.preferred_payment_option === 2;
		const donateId = customer.preferred_donee_id;
		const keepMethodsData = paymentData.keep?.methods;

		const preferredPayment = {
			name: '',
			id: null,
			option: null,
		};

		setPreference(true);

		if (keep && keepMethodsData) {
			Object.keys(keepMethodsData).map((payment) => {
				if (keepMethodsData[payment].id === keepId) {
					preferredPayment.name = keepMethodsData[payment].name;
					preferredPayment.id = paymentData.keep.id;
					preferredPayment.option = keepMethodsData[payment].id;

					handleSetPaymentType(preferredPayment);
				}
			});
		} else if (donate && donateId) {
			dispatch(getCharities()).then((res) => {
				res.map((charity) => {
					if (charity.id == donateId) {
						preferredPayment.name = paymentData.donate.name;
						preferredPayment.id = paymentData.donate.id;
						preferredPayment.option = charity.id;

						handleSetPaymentType(preferredPayment, charity.business_name);
					}
				});
			});
		} else {
			preferredPayment.name = paymentData.keep?.methods.wallet.name;
			preferredPayment.id = paymentData.keep?.id;
			preferredPayment.option = paymentData.keep?.methods.wallet.id;

			handleSetPaymentType(preferredPayment);
		}
	};

	const closeSnackbar = () => {
		setSnackbarMessage({ text: '', status: '' });
	};

	return (
		<CustomSnackbar
			message={snackbarMessage.text}
			status={snackbarMessage.status}
			close={closeSnackbar}
			showCloseButton
		/>
	);
}

import { store } from 'redux/storage';

// if the redemption value is over $100, in CA, and has not filled out info yet
export const isUserInfoNeededCA = () => {
	const location = store.getState().admin.state;
	const redemptionCount = store.getState().process.data.redemption_amount_cents;
	const userInfo = store.getState().auth.compliance.overOneHundredCA;
	const infoCompleted = Object.keys(userInfo).length > 0;

	if (location === 'CA' && redemptionCount > 10000 && !infoCompleted) {
		return true;
	} else {
		return false;
	}
};

/**
 *
 * @param {Object} netWeights - materials with current weight entries
 * @param {Object} adjustments - materials with current count entries
 * @param {Array} overweight - materials that are overweight, past or current
 *
 */
export const crossReferenceWeightLimits = (netWeights, adjustments, overweight) => {
	let over = [];
	const status = {
		ok: false,
		over: [],
	};

	// checks weight
	Object.keys(netWeights).forEach((material) => {
		if (overweight.includes(material)) {
			over.push(material);
		}
	});

	// checks container count

	Object.keys(adjustments).forEach((material) => {
		const adjusted = adjustments[material].sm + adjustments[material].md > 0;
		const overweightLimit = overweight.includes(material);

		if (adjusted && overweightLimit) {
			over.push(material);
		}
	});

	// remove duplicates and add a space after any commas
	over = [...new Set(over)].toString().split(',').join(', ');

	status.ok = over.length === 0;
	status.over = over;

	return status;
};

import {
	GET_TASK_STATS,
	SET_DETECTED,
	SET_DRYRUN,
	RESUME_TASK,
	TASK_GENERATED,
	RESET_TASK,
	SET_THROW_ERROR,
	SET_BELT_ID,
	SET_SECRET,
	TASK_END,
	SET_PROCESS_COUNTS,
	RESET_PROCESS_STATE,
	SET_PREV_TASK_ID,
	SET_READY_TO_STOP,
	SET_MATERIAL_TO_LOAD,
	SET_REDEMPTION_AMOUNT,
	SET_DAILY_WEIGHT_LIMITS,
	TRANSACTION_FINALIZED,
} from '../actions/types';

const initialState = {
	dryrun: 'unset',
	finalized: false,
	throwError: false,
	detected: null,
	task_id: null,
	prevTaskId: null,
	isProcessed: false,
	ready_to_stop: false,
	additionalTime: false,
	materialToLoad: '',
	data: {},
	dailyWeightLimits: {
		overweight: [],
		withinTenPercent: [],
		data: {},
	},
	machine: {
		belt_id: '',
		secret: '',
	},
};

export const process = (state = initialState, action) => {
	switch (action.type) {
		case SET_DRYRUN:
			return {
				...state,
				dryrun: action.payload,
			};
		case SET_THROW_ERROR:
			return {
				...state,
				throwError: action.payload,
			};
		case SET_MATERIAL_TO_LOAD:
			return {
				...state,
				materialToLoad: action.payload,
			};
		case GET_TASK_STATS:
			return {
				...state,
				isProcessed: true,
				ready_to_stop: action.payload.ready_to_stop,
				data: action.payload.data,
			};
		case SET_REDEMPTION_AMOUNT:
			return {
				...state,
				data: {
					...state.data,
					redemption_amount_cents: action.payload,
				},
			};
		case SET_DETECTED:
			return {
				...state,
				detected: action.payload,
			};
		case TASK_GENERATED:
			return {
				...state,
				task_id: action.payload,
			};
		case RESET_TASK:
			return {
				...state,
				task_id: null,
			};
		case RESUME_TASK:
			return {
				...state,
				ready_to_stop: false,
				additionalTime: true,
			};
		case SET_BELT_ID:
			return {
				...state,
				machine: {
					...state.machine,
					belt_id: action.payload,
				},
			};
		case SET_SECRET:
			return {
				...state,
				machine: {
					...state.machine,
					secret: action.payload,
				},
			};
		case SET_PREV_TASK_ID:
			return {
				...state,
				detected: null,
				prevTaskId: action.payload,
			};
		case TASK_END:
			return {
				...state,
				task_id: null,
				prevTaskId: action.payload,
			};
		case SET_PROCESS_COUNTS:
			return {
				...state,
				data: {
					...state.data,
					count_aluminum: action.payload.aluminum,
					count_plastic: action.payload.plastic,
					count_glass: action.payload.glass,
				},
			};
		case SET_READY_TO_STOP:
			return {
				...state,
				ready_to_stop: false, // TODO: figure out why two 'ready_to_stop' keys are needed
				data: {
					...state.data,
					ready_to_stop: action.payload,
				},
			};
		case SET_DAILY_WEIGHT_LIMITS:
			return {
				...state,
				dailyWeightLimits: {
					overweight: action.payload.overweight,
					withinTenPercent: action.payload.withinTenPercent,
					data: action.payload.data,
				},
			};
		case TRANSACTION_FINALIZED:
			return {
				...state,
				finalized: true,
			};
		case RESET_PROCESS_STATE:
			// keeps belt id and machine info
			return {
				...state,
				throwError: false,
				finalized: false,
				detected: null,
				task_id: null,
				prevTaskId: null,
				isProcessed: false,
				ready_to_stop: false,
				additionalTime: false,
				materialToLoad: '',
				needsCountAdjustment: false,
				data: {},
				dailyWeightLimits: {
					overweight: [],
					withinTenPercent: [],
					data: {},
				},
			};
		default:
			return state;
	}
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { SET_LANGUAGE } from 'redux/actions/types';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import CustomButton from 'components/UIKit/CustomButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	toggleSwitch: {
		padding: '11px',
	},
	menuButton: {
		position: 'fixed',
		bottom: '20px',
		right: '20px',
		zIndex: '3',
		[theme.breakpoints.down('lg')]: {
			bottom: '6px',
			right: '6px',
		},
	},
	languages: {
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		'& .MuiButton-root': {
			margin: '10px',
		},
	},
}));

export default function LanguageToggle() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const modal = useSelector((state) => state.modal);
	const [showToggle, setShowToggle] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const locales = {
		en: { title: 'English' },
		es: { title: 'Español' },
		fr: { title: 'Français' },
		ru: { title: 'Русский' },
		zh_CN: { title: '简体中文' },
		zh_TW: { title: '中國傳統的' },
	};

	useEffect(() => {
		if (modal.id) {
			setShowToggle(false);
		} else if (!modal.id) {
			setShowToggle(true);
		}
	}, [modal.id]);

	const toggleLanguage = (language) => {
		dispatch({ type: SET_LANGUAGE, payload: JSON.stringify(language) });
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{showToggle && (
				<Box className={classes.menuButton}>
					<CustomButton size='small' variant='secondary' onClick={handleClick}>
						Select Language
					</CustomButton>
					<Popover
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
					>
						<Box className={classes.languages}>
							{Object.keys(locales).map((locale) => (
								<CustomButton
									size='small'
									variant={i18n.resolvedLanguage === locale ? 'secondary' : 'alternate'}
									onClick={() => {
										i18n.changeLanguage(locale);
										toggleLanguage(locale);
										setAnchorEl(false);
									}}
								>
									{locales[locale].title}
								</CustomButton>
							))}
						</Box>
					</Popover>
				</Box>
			)}
		</>
	);
}

import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import icon from 'assets/images/material-type-number.png';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		paddingTop: '6px',
		marginLeft: '10px',
	},
	image: {
		height: '32px',
		width: 'auto',
	},
	number: {
		position: 'absolute',
		top: '55%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '14px',
		fontWeight: '600',
	},
}));

export default function MaterialTypeNumber({ type }) {
	const classes = useStyles();
	const [number, setNumber] = useState(null);

	useEffect(() => {
		type && getNumber();
	}, []);

	const getNumber = () => {
		switch (type) {
			case 'PETE':
				setNumber(1);
				break;
			case 'HDPE':
				setNumber(2);
				break;
			case 'PVC':
				setNumber(3);
				break;
			case 'LDPE':
				setNumber(4);
				break;
			case 'PP':
				setNumber(5);
				break;
			case 'PS':
				setNumber(6);
				break;
			case 'OTHER':
				setNumber(7);
				break;
			default:
				setNumber(null);
		}
	};

	return (
		<>
			{number && (
				<Box className={classes.container}>
					<img className={classes.image} src={icon} alt='recycling plastic number' />
					<Box className={classes.number}>{number}</Box>
				</Box>
			)}
		</>
	);
}
